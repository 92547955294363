import { useQuery } from '@apollo/client'
import { VaultEntities, VAULTS_QUERY } from '../../queries/vaultQuery'
import { useWeb3React } from '@web3-react/core'
import { BigNumber, ethers } from 'ethers'
import { useMemo } from 'react'

export type ExtraOpenPosition = {
  id: number
  assetId: number
  perpUpdatedAt: number
  squartUpdatedAt: number
  createdAt: number
  updatedAt: number
}

export type ExtraVaultStatus = {
  vaultId: number
  owner: string
  margin: BigNumber
  isMainVault: boolean
  openPositions: ExtraOpenPosition[]
}

type ExtraVaultResult =
  | { isAvailable: false; mainVault: null; isolatedVaults: [] }
  | {
      isAvailable: true
      mainVault: ExtraVaultStatus
      isolatedVaults: ExtraVaultStatus[]
    }

export function useExtraVaultStatus(): ExtraVaultResult {
  const { account } = useWeb3React<ethers.providers.Web3Provider>()

  const { data } = useQuery<VaultEntities>(VAULTS_QUERY, {
    fetchPolicy: 'cache-and-network',
    variables: {
      owner: (account || '').toLowerCase()
    },
    pollInterval: 10000
  })

  const extraVaultStatus = useMemo(() => {
    if (data) {
      const ownVaults: ExtraVaultStatus[] = data.vaultEntities.map(entity => {
        const margin = BigNumber.from(entity.margin)

        return {
          vaultId: Number(entity.vaultId),
          owner: entity.owner,
          margin,
          isMainVault: entity.isMainVault,
          openPositions: entity.openPositions.map(openPosition => {
            return {
              id: Number(openPosition.id),
              assetId: Number(openPosition.assetId),
              perpUpdatedAt: Number(openPosition.perpUpdatedAt),
              squartUpdatedAt: Number(openPosition.squartUpdatedAt),
              createdAt: Number(openPosition.createdAt),
              updatedAt: Number(openPosition.updatedAt)
            }
          })
        }
      })

      const mainVault = ownVaults.find(vault => vault.isMainVault)
      const isolatedVaults = ownVaults.filter(vault => !vault.isMainVault)

      return {
        mainVault,
        isolatedVaults
      }
    }

    return null
  }, [data])

  if (
    data === undefined ||
    extraVaultStatus === null ||
    extraVaultStatus.mainVault === undefined
  ) {
    return {
      isAvailable: false,
      mainVault: null,
      isolatedVaults: []
    }
  }

  return {
    isAvailable: true,
    mainVault: extraVaultStatus.mainVault,
    isolatedVaults: extraVaultStatus.isolatedVaults
  }
}
