import { useQuery } from 'react-query'
import {
  LPT_RESERVE_FACTOR,
  ONE,
  TOKEN_RESERVE_FACTOR,
  ZERO
} from '../../../constants'
import { calculateInterestRate } from '../../../utils/irm'
import { Q96 } from '../../../constants'
import { Asset, ScaledAssetStatus, useAsset } from '../useAsset'
import { BigNumber } from 'ethers'

function calculateSupply(assetStatus: ScaledAssetStatus) {
  const totalDeposited = assetStatus.totalCompoundDeposited
    .mul(assetStatus.assetScaler)
    .div(ONE)
    .add(assetStatus.totalNormalDeposited)

  return totalDeposited
}

function calculateBorrow(assetStatus: ScaledAssetStatus) {
  const totalBorrowed = assetStatus.totalCompoundBorrowed
    .mul(assetStatus.debtScaler)
    .div(ONE)
    .add(assetStatus.totalNormalBorrowed)

  return totalBorrowed
}

export function calculateAssetInterest(
  asset: Asset,
  positionAmount: BigNumber,
  tradeAmount?: BigNumber
) {
  let supplyAddiction = ZERO
  let borrowAddiction = ZERO

  if (tradeAmount) {
    const openAndCloseAmounts = calculateOpenAndCloseAmounts(
      positionAmount,
      tradeAmount
    )

    if (openAndCloseAmounts.openAmount.gt(0)) {
      supplyAddiction = openAndCloseAmounts.openAmount
    } else {
      borrowAddiction = openAndCloseAmounts.openAmount.mul(-1)
    }

    if (openAndCloseAmounts.closeAmount.gt(0)) {
      borrowAddiction = openAndCloseAmounts.closeAmount.mul(-1)
    } else {
      supplyAddiction = openAndCloseAmounts.closeAmount
    }
  }

  const supply = calculateSupply(asset.tokenStatus).add(supplyAddiction)
  const borrow = calculateBorrow(asset.tokenStatus).add(borrowAddiction)

  const ur = supply.eq(0) ? ZERO : borrow.mul(ONE).div(supply)
  const borrowInterest = calculateInterestRate(asset.irmParams, ur)
  const supplyInterest = supply.eq(0)
    ? ZERO
    : borrowInterest
        .mul(borrow)
        .div(supply)
        .mul(BigNumber.from(100).sub(TOKEN_RESERVE_FACTOR))
        .div(100)

  return {
    supplyInterest,
    borrowInterest
  }
}

export function useAssetInterest(assetId: number) {
  const asset = useAsset(assetId)

  return useQuery(
    ['perp_interest', assetId],

    async () => {
      if (!asset.isSuccess) throw new Error('asset not set')

      return calculateAssetInterest(asset.data, ZERO)
    },
    {
      enabled: asset.isSuccess,
      staleTime: 1000
    }
  )
}

// interest for 2 * sqrt(x)
export function calculateSquartInterest(
  asset: Asset,
  sqrtPrice: BigNumber,
  positionAmount: BigNumber,
  tradeAmount?: BigNumber
) {
  let supplyAddiction = ZERO
  let borrowAddiction = ZERO

  if (tradeAmount) {
    const openAndCloseAmounts = calculateOpenAndCloseAmounts(
      positionAmount,
      tradeAmount
    )

    if (openAndCloseAmounts.openAmount.gt(0)) {
      supplyAddiction = openAndCloseAmounts.openAmount
    } else {
      borrowAddiction = openAndCloseAmounts.openAmount.mul(-1)
    }

    if (openAndCloseAmounts.closeAmount.gt(0)) {
      borrowAddiction = openAndCloseAmounts.closeAmount.mul(-1)
    } else {
      supplyAddiction = openAndCloseAmounts.closeAmount
    }
  }

  const supply = asset.sqrtAssetStatus.totalAmount.add(supplyAddiction)
  const borrow = asset.sqrtAssetStatus.borrowedAmount.add(borrowAddiction)

  const ur = supply.eq(0) ? ZERO : borrow.mul(ONE).div(supply)

  const borrowInterest = calculateInterestRate(asset.premiumParams, ur)
    .mul(sqrtPrice)
    .div(Q96)

  const supplyInterest = supply.eq(0)
    ? ZERO
    : borrowInterest
        .mul(borrow)
        .div(supply)
        .mul(BigNumber.from(100).sub(LPT_RESERVE_FACTOR))
        .div(100)

  return {
    supply,
    borrow,
    supplyInterest,
    borrowInterest
  }
}

function calculateOpenAndCloseAmounts(
  positionAmount: BigNumber,
  tradeAmount: BigNumber
) {
  let openAmount = ZERO
  let closeAmount = ZERO

  if (positionAmount.isNegative() === tradeAmount.isNegative()) {
    openAmount = tradeAmount
  } else {
    if (positionAmount.abs().gte(tradeAmount.abs())) {
      closeAmount = tradeAmount
    } else {
      openAmount = positionAmount.add(tradeAmount)
      closeAmount = positionAmount.mul(-1)
    }
  }

  return {
    openAmount,
    closeAmount
  }
}
