import React from 'react'
import { ASSET_NAMES } from '../../constants'
import { useVault } from '../../hooks/query/useVault'
import { toUnscaled } from '../../utils/bn'

const VaultStatus = ({ vaultId }: { vaultId: number }) => {
  const vault = useVault(vaultId)

  return (
    <div className="rounded-lg bg-secondaly leading-5 p-2">
      <div className="w-32">Vault {vaultId} Status</div>
      <div className="flex flex-wrap md:flex-no-wrap justify-between items-center px-6 space-x-0">
        <div>
          {vault.isSuccess ? (
            vault.data.openPositions.map((openPosition, i) => {
              return (
                <div key={i} className="m-2 p-2 bg-white5 rounded-lg">
                  <div>{ASSET_NAMES[openPosition.assetId]}</div>
                  <div>{toUnscaled(openPosition.sqrtPerpPosition, 12)} LP</div>
                  <div>
                    {toUnscaled(openPosition.stableAssetOrDebtAmount, 6)} USDC
                  </div>
                  <div>
                    {toUnscaled(openPosition.underlyingAssetOrDebtAmount, 18)}{' '}
                    {ASSET_NAMES[openPosition.assetId]}
                  </div>
                </div>
              )
            })
          ) : (
            <div />
          )}
        </div>
      </div>
    </div>
  )
}

export default VaultStatus
