import React, { useEffect, useState } from 'react'
import { useFeeEst } from '../../hooks/query/interest/useFeeEst'
import { BigNumber } from 'ethers'
import { MARGIN, ZERO } from '../../constants'
import { toUnscaled } from '../../utils/bn'

type Prop = {
  assetId: number
  vaultId?: number
  isSuccess: boolean
  trade?: {
    tradeAmountSqrt: BigNumber
    tradeAmountPerp: BigNumber
  }
}

export const InterestEstPanel = ({ assetId, vaultId, trade }: Prop) => {
  const [feeEst, setFeeEst] = useState(ZERO)

  const feeEstQuery = useFeeEst(
    assetId,
    vaultId ? [vaultId] : [],
    trade ? trade.tradeAmountPerp : ZERO,
    trade ? trade.tradeAmountSqrt : ZERO
  )

  useEffect(() => {
    if (feeEstQuery.isSuccess) {
      setFeeEst(feeEstQuery.data.totalFeeEst)
    }
  }, [feeEstQuery.isSuccess, feeEstQuery.data])

  return (
    <div className="w-full mt-2 flex justify-between items-end text-3xl">
      <div
        className={`font-semibold whitespace-nowrap ${
          feeEst.gte(0) ? 'text-green' : 'text-red'
        }`}
      >
        {feeEst.gte(0) ? 'GET +' : 'PAY -'}$
        {toUnscaled(feeEst.abs(), MARGIN.DECIMALS, MARGIN.SIGNIFICANT)}
      </div>
      <div className="font-semibold text-white5 whitespace-nowrap text-2xl">
        Daily est.
      </div>
    </div>
  )
}
