import React, { useState } from 'react'
import { useFeeEst } from '../../../hooks/query/interest/useFeeEst'
import {
  useVaultStatus,
  VaultSummary
} from '../../../hooks/query/useVaultStatus'
import { toUnscaled } from '../../../utils/bn'
import { usdcAmountToString } from '../../../utils/number'
import OpenPositions from './OpenPositions'
import TradeHistory from './TradeHistory'
import { getDelta, getGamma } from '../../../utils/bs'
import { useCachedPrice } from '../../../hooks/usePrice'
import { ZERO } from '../../../constants'
import { Tab, TabList } from '../../common/Tab'
import { MyPositionTab } from '../../../constants/enum'
import { PrimaryButton } from '../../common/Button'
import { useTradePerp } from '../../../hooks/contracts/useTradePerp'
import { useExtraVaultStatus } from '../../../hooks/query/useExtraVaultStatus'
import { toUnscaledSqrtPrice } from '../../../utils/price'

type Prop = {
  assetId: number
  vaultId: number
}

const InterestEstPanel = ({ assetId, vaultId }: Prop) => {
  const interestEst = useFeeEst(assetId, [vaultId], ZERO, ZERO)

  return (
    <>
      {usdcAmountToString(
        interestEst.isSuccess ? interestEst.data.totalFeeEst : undefined
      )}
    </>
  )
}

export const VaultInterestEstPanel = ({
  vaultId,
  assetId
}: {
  vaultId: number
  assetId: number
}) => {
  return <InterestEstPanel assetId={assetId} vaultId={vaultId} />
}

const MyPosition = ({
  vaultId,
  assetId,
  vaultSummary
}: {
  vaultId: number
  assetId: number
  vaultSummary?: VaultSummary
}) => {
  const [tab, setTab] = useState(MyPositionTab.Positions)
  const [hasPendingTx, setHasPendingTx] = useState(false)

  const vaultStatus = useVaultStatus()
  const extraVaultStatus = useExtraVaultStatus()
  const trade = useTradePerp(assetId)

  const position =
    vaultStatus.isSuccess && vaultStatus.data.mainVault
      ? vaultStatus.data.mainVault.openPositionStatusResults.find(
          openPosition => openPosition.assetId === assetId
        )
      : undefined

  const extraPositionStatus = extraVaultStatus.isAvailable
    ? extraVaultStatus.mainVault.openPositions.find(
        openPosition => openPosition.assetId === assetId
      )
    : undefined

  const price = useCachedPrice(assetId)

  const onClose = async () => {
    const tx = await trade.mutateAsync({
      vaultId,
      assetId,
      tradeAmount: position ? position.perpStatus.amount.mul(-1) : ZERO,
      tradeAmountSqrt: position ? position.sqrtStatus.amount.mul(-1) : ZERO
    })

    setHasPendingTx(true)
    await tx.wait()
    setHasPendingTx(false)
  }

  return (
    <div className="p-4 rounded-2xl bg-secondaly border-[1px] border-white">
      <div className="md:w-[680px] xl:w-[756px] mb-4 flex justify-between border-b-[1px] border-white">
        <div>
          <TabList
            onSelect={tabIndex => {
              setTab(tabIndex as MyPositionTab)
            }}
          >
            <Tab
              selected={tab === MyPositionTab.Positions}
              tabId={MyPositionTab.Positions}
            >
              Positions
            </Tab>
            <Tab
              selected={tab === MyPositionTab.TradeHistory}
              tabId={MyPositionTab.TradeHistory}
            >
              Trade History
            </Tab>
          </TabList>
        </div>
        <div className="hidden sm:flex flex-col space-y-2 text-xs">
          <div className="text-subtext">Margin Utilizing</div>
          <div>${usdcAmountToString(vaultSummary?.minValue)}</div>
        </div>
        <div className="flex flex-col space-y-2 text-xs">
          <div className="text-subtext">Daily Fee est.</div>
          <div>
            $<VaultInterestEstPanel vaultId={vaultId} assetId={assetId} />
          </div>
        </div>
        <div className="hidden xl:flex flex-col space-y-2 text-xs">
          <div className="text-subtext">Delta</div>
          <div>
            {position
              ? getDelta(
                  toUnscaled(position.perpStatus.amount, 18),
                  toUnscaled(position.sqrtStatus.amount, 12),
                  toUnscaledSqrtPrice(price.sqrtPrice)
                ).toFixed(4)
              : 0}
          </div>
        </div>
        <div className="hidden xl:flex flex-col space-y-2 text-xs">
          <div className="text-subtext">Gamma</div>
          <div>
            {position
              ? getGamma(
                  toUnscaled(position.sqrtStatus.amount, 12),
                  toUnscaledSqrtPrice(price.sqrtPrice)
                ).toFixed(4)
              : 0}
          </div>
        </div>

        <div className="hidden xl:block w-[168px] h-8">
          <PrimaryButton onClick={onClose} disabled={hasPendingTx}>
            <span className="text-lg">Close Positions</span>
          </PrimaryButton>
        </div>
      </div>
      {tab === MyPositionTab.Positions ? (
        <OpenPositions
          vaultId={vaultId}
          assetId={assetId}
          openPosition={position}
          extraPositionStatus={extraPositionStatus}
        />
      ) : (
        <TradeHistory vaultId={vaultId} />
      )}
    </div>
  )
}

export default MyPosition
