import { BigNumber } from 'ethers'

export type IRMParams = {
  baseRate: BigNumber
  kinkRate: BigNumber
  slope1: BigNumber
  slope2: BigNumber
}

const ONE = BigNumber.from('1000000000000000000')

export function calculateInterestRate(params: IRMParams, ur: BigNumber) {
  if (ur.lt(0)) {
    throw new Error('utilization ratio must be positive value.')
  }

  let ir = params.baseRate

  if (ur.lte(params.kinkRate)) {
    ir = ir.add(ur.mul(params.slope1).div(ONE))
  } else {
    ir = ir.add(params.kinkRate.mul(params.slope1).div(ONE))
    ir = ir.add(params.slope2.mul(ur.sub(params.kinkRate)).div(ONE))
  }

  return ir
}
