import React, { useState } from 'react'
import { toScaled, toUnscaled } from '../../utils/bn'
import { PrimaryButton } from '../common/Button'
import AmountRangeInput from '../common/AmountRangeInput'
import SideSelector from '../common/SideSelector'
import { ASSET_NAMES, MARGIN } from '../../constants'
import { useOpenIsolatedVault } from '../../hooks/contracts/useOpenIsolatedVault'
import AmountInput from '../common/AmountInput'
import { InterestEstPanel } from '../perps/InterestEstPanel'
import { useSlippageTolerance } from '../../hooks/query/common/slippage'
import { GammaTradeResult } from '../../hooks/views/gamma'
import useGammaTrade from '../../store/trade'
import { BigNumber } from 'ethers'
import {
  calculateMaxSquartAmountForLong,
  calculateMaxSquartAmountForShort
} from '../../utils/helpers/maxSquartAmount'
import { useCachedPrice } from '../../hooks/usePrice'
import { LoadingIndicatorPt } from '../common/LoadingIndicator'

const GammaTradeForm = ({
  assetId,
  gammaTradeResult,
  available
}: {
  assetId: number
  gammaTradeResult: GammaTradeResult
  available: BigNumber
}) => {
  const [isPendingTx, setIsPendingTx] = useState(false)
  const {
    squartSide,
    squartAmount,
    marginAmount,
    setSquartSide,
    setSquartAmount,
    setMarginAmount
  } = useGammaTrade()
  const price = useCachedPrice(assetId)

  const openVault = useOpenIsolatedVault(assetId)
  const slippageTolerance = useSlippageTolerance()

  const onTrade = async () => {
    const tx = await openVault.mutateAsync({
      assetId,
      marginAmount: toScaled(marginAmount, MARGIN.DECIMALS),
      tradeAmount: gammaTradeResult.perpAmount,
      tradeAmountSqrt: gammaTradeResult.squartAmount
    })

    setIsPendingTx(true)
    await tx.wait()
    setTimeout(() => {
      setIsPendingTx(false)
    }, 1000)
    setSquartAmount(0)
  }

  const maxSquartAmount = toUnscaled(
    squartSide
      ? calculateMaxSquartAmountForShort(
          toScaled(marginAmount, MARGIN.DECIMALS),
          price.sqrtIndexPrice
        )
      : calculateMaxSquartAmountForLong(
          toScaled(marginAmount, MARGIN.DECIMALS),
          price.sqrtIndexPrice
        ),
    12,
    0
  )

  return (
    <div className="rounded-3xl bg-secondaly border-[1px] border-white leading-5">
      <div className="mx-4 mt-5 mb-3 p-2 flex justify-between rounded-full bg-black3 border-[1px] border-white">
        <div className="py-1 px-2 rounded-full bg-white1 text-base font-semibold">
          Margin Isolating
        </div>
        <div className="py-1 pr-2 flex justify-between items-center gap-2">
          <div className="w-20">
            <AmountInput
              amount={marginAmount}
              min={0}
              onChange={setMarginAmount}
            />
          </div>
          <div>USDC</div>
        </div>
      </div>

      {available.lt(toScaled(marginAmount, MARGIN.DECIMALS)) ? (
        <div className="mx-5 my-5 text-xs text-red text-center">
          <span>Insufficient available margin in your main vault.</span>
        </div>
      ) : (
        <></>
      )}

      <div className="m-[-1px] rounded-3xl bg-white0 border-[1px] border-white leading-5">
        <div className="mx-5 my-5">
          <SideSelector side={squartSide} onChange={setSquartSide} />
        </div>
        <div className="mx-5 my-5 p-4 rounded-xl bg-black4 shadow-sm">
          <AmountRangeInput
            amount={squartAmount}
            max={maxSquartAmount}
            step={0.1}
            title={'√' + ASSET_NAMES[assetId]}
            onChange={setSquartAmount}
          />
        </div>
      </div>

      <div className="mx-5 my-5">
        <div className="text-base">Summary</div>
        <InterestEstPanel
          assetId={assetId}
          isSuccess={true}
          trade={gammaTradeResult.trade}
        />

        <div className="mt-2 space-y-2">
          <div className="text-sm flex justify-between border-b-[1px] border-white3">
            <div>Structure</div>
            <div>
              {squartSide ? (
                <span className="text-red">Sell</span>
              ) : (
                <span className="text-green">Buy</span>
              )}{' '}
              √{ASSET_NAMES[assetId]} {squartAmount},{' '}
              {!squartSide ? (
                <span className="text-red">Sell</span>
              ) : (
                <span className="text-green">Buy</span>
              )}{' '}
              {ASSET_NAMES[assetId]}{' '}
              {toUnscaled(gammaTradeResult.perpAmount.abs(), 18, 2)}
            </div>
          </div>
          <div className="text-sm flex justify-between border-b-[1px] border-white3">
            <div>Price</div>
            <div>
              {gammaTradeResult.squartPrice}, {gammaTradeResult.perpPrice}
            </div>
          </div>
          <div className="text-sm flex justify-between border-b-[1px] border-white3">
            <div>Gamma, Delta</div>
            <div>
              <span>{gammaTradeResult.gamma}</span>
              <span>, </span>
              <span>{gammaTradeResult.delta}</span>
            </div>
          </div>
          <div className="text-sm flex justify-between border-b-[1px] border-white3">
            <div>Loss Threshold</div>
            <div className="text-red">
              -$
              {gammaTradeResult.estLoss
                ? toUnscaled(
                    gammaTradeResult.estLoss.lossThreshold,
                    MARGIN.DECIMALS,
                    MARGIN.SIGNIFICANT
                  )
                : '-'}
            </div>
          </div>

          <div className="text-sm flex justify-between border-b-[1px] border-white3">
            <div>Min.</div>
            <div>${gammaTradeResult.minDeposit}</div>
          </div>

          <div className="text-sm flex justify-between border-b-[1px] border-white3">
            <div>Max Slippage</div>
            <div>{slippageTolerance}%</div>
          </div>
        </div>
      </div>

      <div className="mx-5 my-5 h-12">
        <PrimaryButton
          onClick={onTrade}
          disabled={
            isPendingTx || !!gammaTradeResult.tradeError || squartAmount === 0
          }
        >
          {isPendingTx ? <LoadingIndicatorPt /> : 'Trade'}
        </PrimaryButton>
      </div>

      {gammaTradeResult.tradeError ? (
        <div className="mx-5 my-5 text-xs text-red text-center">
          <span>{gammaTradeResult.tradeError}</span>
        </div>
      ) : (
        <></>
      )}
    </div>
  )
}

export default GammaTradeForm
