import { useMutation } from 'react-query'
import { useWeb3React } from '@web3-react/core'
import { BigNumberish, ethers } from 'ethers'
import { useAddresses } from '../useAddress'
import { GammaShortStrategy__factory } from '../../typechain'
import { useDeadline } from '../useBlockTimestamp'
import { useCachedPrice } from '../usePrice'
import { computeLowerSqrtPrice, computeUpperSqrtPrice } from '../../utils'
import { useChainId } from '../network'

type WithdrawFromStrategyParams = {
  strategyTokenAmount: BigNumberish
  minWithdrawAmount: BigNumberish
}

export function useWithdrawFromStrategy(assetId: number) {
  const { account, provider } = useWeb3React<ethers.providers.Web3Provider>()
  const addresses = useAddresses()
  const deadline = useDeadline()
  const price = useCachedPrice(assetId)
  const chainId = useChainId()

  return useMutation(async (params: WithdrawFromStrategyParams) => {
    if (!account) throw new Error('Account not set')
    if (!provider) throw new Error('provider not set')
    if (!addresses) throw new Error('addresses not set')
    if (!chainId) throw new Error('chainId not set')
    if (!deadline.isSuccess) throw new Error('deadline not set')

    const contract = GammaShortStrategy__factory.connect(
      addresses.assets[assetId].GammaShortStrategy,
      provider.getSigner()
    )

    return await contract.withdraw(
      params.strategyTokenAmount,
      account,
      params.minWithdrawAmount,
      {
        lowerSqrtPrice: computeLowerSqrtPrice(price.sqrtPrice, chainId),
        upperSqrtPrice: computeUpperSqrtPrice(price.sqrtPrice, chainId),
        deadline: deadline.data
      }
    )
  })
}
