import { BigNumber, ethers } from 'ethers'
import { useWeb3React } from '@web3-react/core'
import { useQuery } from 'react-query'
import { useChainId, useIsSupportedChain } from '../../network'
import { useAddresses } from '../../useAddress'
import { useDeadline } from '../../useBlockTimestamp'
import { useEffect, useState } from 'react'
import { REFETCH_INTERVAL } from '../../../constants'
import { usePrice } from '../../usePrice'
import { GammaShortStrategy__factory } from '../../../typechain'
import { computeLowerSqrtPrice, computeUpperSqrtPrice } from '../../../utils'
import { StrategyQuoter__factory } from '../../../typechain'

type QuoterResult =
  | {
      error: string
      data: null
    }
  | {
      error: null
      data: BigNumber
    }

type DepositParams = {
  strategyTokenAmount: BigNumber
  maxDepositAmount: BigNumber
}

type WithdrawParams = {
  strategyTokenAmount: BigNumber
}

export function useDepositToStrategyQuoter(
  assetId: number,
  params: DepositParams
) {
  const { provider, account } = useWeb3React<ethers.providers.Web3Provider>()
  const supportedChain = useIsSupportedChain()
  const chainId = useChainId()
  const addresses = useAddresses()
  const deadline = useDeadline()
  const price = usePrice(assetId)

  const [amounts, setAmounts] = useState<QuoterResult>({
    error: 'not loaded',
    data: null
  })

  const quoterQuery = useQuery<QuoterResult>(
    ['quoter_deposit_to_st', account, chainId, assetId, params],

    async () => {
      if (!account) throw new Error('Account not set')
      if (!provider) throw new Error('provider not set')
      if (!addresses) throw new Error('addresses not set')
      if (!chainId) throw new Error('chainId not set')
      if (!deadline.isSuccess) throw new Error('deadline not set')
      if (!price.isSuccess) throw new Error('price not set')

      const contract = StrategyQuoter__factory.connect(
        addresses.assets[assetId].StrategyQuoter,
        provider
      )

      try {
        const requiredDepositAmount = await contract.callStatic.quoteDeposit(
          params.strategyTokenAmount,
          account,
          BigNumber.from('10000000000000000'),
          {
            lowerSqrtPrice: computeLowerSqrtPrice(
              price.data.sqrtPrice,
              chainId
            ),
            upperSqrtPrice: computeUpperSqrtPrice(
              price.data.sqrtPrice,
              chainId
            ),
            deadline: deadline.data
          },
          { from: account }
        )

        return {
          error: null,
          data: requiredDepositAmount
        }
      } catch (e: any) {
        if (e.error && e.error.message === "Non-200 status code: '429'") {
          throw new Error(e.error.message)
        }
        return {
          error: String(e.reason || e.data.message),
          data: null
        }
      }
    },
    {
      enabled:
        !!account &&
        supportedChain &&
        !!provider &&
        !!addresses &&
        deadline.isSuccess &&
        price.isSuccess,
      refetchInterval: REFETCH_INTERVAL
    }
  )

  useEffect(() => {
    if (quoterQuery.isSuccess) {
      setAmounts(quoterQuery.data)
    }
  }, [quoterQuery.isSuccess, quoterQuery.data])

  return amounts
}

export function useWithdrawFromStrategyQuoter(
  assetId: number,
  params: WithdrawParams
) {
  const { provider, account } = useWeb3React<ethers.providers.Web3Provider>()
  const supportedChain = useIsSupportedChain()
  const chainId = useChainId()
  const addresses = useAddresses()
  const deadline = useDeadline()
  const price = usePrice(assetId)

  const [amounts, setAmounts] = useState<QuoterResult>({
    error: 'not loaded',
    data: null
  })

  const quoterQuery = useQuery<QuoterResult>(
    ['quoter_withdraw_from_st', account, chainId, params],

    async () => {
      if (!account) throw new Error('Account not set')
      if (!provider) throw new Error('provider not set')
      if (!addresses) throw new Error('addresses not set')
      if (!chainId) throw new Error('chainId not set')
      if (!deadline.isSuccess) throw new Error('deadline not set')
      if (!price.isSuccess) throw new Error('price not set')

      const contract = GammaShortStrategy__factory.connect(
        addresses.assets[assetId].GammaShortStrategy,
        provider
      )

      try {
        const finalWithdrawnAmount = await contract.callStatic.withdraw(
          params.strategyTokenAmount,
          account,
          0,
          {
            lowerSqrtPrice: computeLowerSqrtPrice(
              price.data.sqrtPrice,
              chainId
            ),
            upperSqrtPrice: computeUpperSqrtPrice(
              price.data.sqrtPrice,
              chainId
            ),
            deadline: deadline.data
          },
          { from: account }
        )

        return {
          error: null,
          data: finalWithdrawnAmount
        }
      } catch (e: any) {
        if (e.error && e.error.message === "Non-200 status code: '429'") {
          throw new Error(e.error.message)
        }
        return {
          error: String(e.reason || e.data.message),
          data: null
        }
      }
    },
    {
      enabled:
        !!account &&
        supportedChain &&
        !!provider &&
        !!addresses &&
        deadline.isSuccess &&
        price.isSuccess,
      refetchInterval: REFETCH_INTERVAL
    }
  )

  useEffect(() => {
    if (quoterQuery.isSuccess) {
      setAmounts(quoterQuery.data)
    }
  }, [quoterQuery.isSuccess, quoterQuery.data])

  return amounts
}
