import { BigNumber } from 'ethers'
import { ZERO } from '../../../constants'
import { useAssetGrowthHistory } from './useAssetGrowthHistory'

type HistoryItem = {
  assetId: number
  assetGrowth: BigNumber
  updatedAt: number
}

export function useLendingApr(assetId: number) {
  const scalerHistory = useAssetGrowthHistory(assetId)

  function getApr(latest: HistoryItem, start: HistoryItem, days: number) {
    const apr = latest.assetGrowth.sub(start.assetGrowth)
    const span = latest.updatedAt - start.updatedAt

    if (span === 0) {
      return ZERO
    }

    return apr.mul(60 * 60 * 24 * days).div(span)
  }

  if (scalerHistory) {
    if (scalerHistory.length > 0) {
      const latest = scalerHistory.length - 1
      const dayBefore = latest - 1
      const weekBefore = latest >= 7 ? latest - 7 : 0
      const monthBefore = latest >= 30 ? latest - 30 : 0
      const yearBefore = latest >= 365 ? latest - 365 : 0

      const dayApr = getApr(scalerHistory[latest], scalerHistory[dayBefore], 1)
      const weekApr = getApr(
        scalerHistory[latest],
        scalerHistory[weekBefore],
        7
      )
      const monthApr = getApr(
        scalerHistory[latest],
        scalerHistory[monthBefore],
        30
      )
      const yearApr = getApr(
        scalerHistory[latest],
        scalerHistory[yearBefore],
        365
      )

      return {
        dayApr,
        weekApr,
        monthApr,
        yearApr
      }
    }
  }

  return {
    dayApr: ZERO,
    weekApr: ZERO,
    monthApr: ZERO,
    yearApr: ZERO
  }
}
