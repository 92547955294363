import React, { useState } from 'react'
import { Product } from '../../constants/enum'
import { useStrategySummary } from '../../hooks/query/strategy/useStrategySummary'
import { usePriceHistory } from '../../hooks/query/usePriceHistory'
import { useAddresses } from '../../hooks/useAddress'
import { AggregationInterval } from '../../queries/aggregatedPriceQuery'
import { toUnscaled } from '../../utils/bn'
import { getNow } from '../../utils/time'
import PriceChart from '../common/PriceChart'
import { PerpPriceInfos } from '../perps/PerpPriceInfo'

const StrategyCenterArea = ({ assetId }: { assetId: number }) => {
  const [selectedPerp, setSelectedPerp] = useState(Product.SQRT)
  const addresses = useAddresses()

  const strategySummary = useStrategySummary(assetId)

  // TODO: set daily
  const priceData = usePriceHistory(
    addresses ? addresses.assets[assetId].GammaShortStrategy : '',
    AggregationInterval.Hourly
  )

  const data = priceData
    ? priceData
        .map(d => [d.openTimestamp * 1000, toUnscaled(d.openPrice, 18)])
        .concat(
          strategySummary.isSuccess
            ? [[getNow() * 1000, strategySummary.data?.price || 0]]
            : []
        )
    : []

  return (
    <div>
      <PerpPriceInfos
        assetId={assetId}
        selectedPerp={selectedPerp}
        onSelect={setSelectedPerp}
      />
      <div className="mt-4 flex justify-center">
        <div className="w-[696px]">
          <h1 className="text-3xl font-bold">Strategy Trading</h1>
          <p className="text-base leading-6">
            Strategy trade offers the opportunity to put USDC in
            Auto-Delta-hedge&apos;s Gamma short vault with Buy the unit
            &quot;Strategy&quot;. Strategy = Squart with Delta-hedge taking
            place in the display time interval. Note that this is a swap between
            Strategy(ERC20) and USDC in Wallet.
          </p>
        </div>
      </div>
      <div className="mt-4 flex justify-center items-start space-x-2">
        <div className="w-[596px] h-full">
          <PriceChart height={288} data1={data} />
        </div>
        <div className="w-[138px] h-full flex flex-col space-y-2">
          <div className="flex flex-col items-end space-y-2">
            <div className="text-sm text-white5">Next hedge in</div>
            <div className="text-xl text-right">
              {strategySummary.data?.nextHedgeIn.hours || '-'}H{' '}
              {strategySummary.data?.nextHedgeIn.minutes || '-'}M{' '}
              {strategySummary.data?.nextHedgeIn.seconds || '-'}S<br />
              or 7.5% move
            </div>
          </div>
          <div className="flex flex-col items-end space-y-2">
            <div className="text-sm text-white5">Total issuing</div>
            <div className="text-xl">{strategySummary.data?.totalIssuing}</div>
          </div>
          <div className="flex flex-col items-end space-y-2">
            <div className="text-sm text-white5">Marketcap</div>
            <div className="text-xl">-</div>
          </div>
          <div className="flex flex-col items-end space-y-2">
            <div className="text-sm text-white5">Squart/Strategy</div>
            <div className="text-xl">0.7</div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default StrategyCenterArea
