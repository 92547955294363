import React, { useState } from 'react'
import { toScaled, toUnscaled } from '../../utils/bn'
import { PrimaryButton } from '../common/Button'
import AmountRangeInput from '../common/AmountRangeInput'
import SideSelector from '../common/SideSelector'
import { useApproveMutation } from '../../hooks/contracts/useApprove'
import { calculateMargin } from '../../utils'
import { useERC20BalanceQuery } from '../../hooks/query/balance'
import { useAllowanceQuery } from '../../hooks/query/allowance'
import pendingStore from '../../store/pending'
import { BigNumber } from 'ethers'
import { useSupply, useWithdraw } from '../../hooks/contracts/useSupply'
import { ASSET_DECIMALS, ASSET_NAMES } from '../../constants'
import { useLendingApr } from '../../hooks/query/lending/useLendingApr'
import { Asset } from '../../hooks/query/useAsset'
import { Addresses } from '../../constants/addresses'

const AprComponent = ({ apr }: { apr: BigNumber }) => (
  <div className={apr.gt(0) ? 'text-green' : 'text-red'}>
    {toUnscaled(apr, 16, 2)}%
  </div>
)

const LendingTradeForm = ({
  assetId,
  addresses,
  asset
}: {
  assetId: number
  addresses: Addresses
  asset: Asset
}) => {
  const [tradeSide, setTradeSide] = useState(false)
  const [assetAmount, setAssetAmount] = useState(0)

  const { isPendingApproval, isPendingTx, setPendingApproval, setPendingTx } =
    pendingStore()

  const tokenAddress = addresses.assets[assetId].UnderlyingToken

  const balanceQuery = useERC20BalanceQuery(tokenAddress)
  const strategyBalanceQuery = useERC20BalanceQuery(asset.supplyTokenAddress)

  const allowanceQuery = useAllowanceQuery(addresses.Controller, tokenAddress)

  const lendingApy = useLendingApr(assetId)

  const supply = useSupply()
  const withdraw = useWithdraw()
  const approve = useApproveMutation()

  const onApprove = async () => {
    if (addresses === undefined) {
      return
    }

    const tx = await approve.mutateAsync({
      spender: addresses.Controller,
      address: tokenAddress,
      amount: calculateMargin(toScaled(assetAmount, ASSET_DECIMALS[assetId]))
    })

    setPendingApproval(tx)
  }

  const onTrade = async () => {
    if (tradeSide) {
      const tx = await withdraw.mutateAsync({
        assetId,
        supplyAmount: toScaled(assetAmount, ASSET_DECIMALS[assetId])
      })

      setPendingTx(tx)
    } else {
      const tx = await supply.mutateAsync({
        assetId,
        supplyAmount: toScaled(assetAmount, ASSET_DECIMALS[assetId])
      })

      setPendingTx(tx)
    }
  }

  const requiredUSDCAmount = calculateMargin(toScaled(assetAmount, 6))
  const requiredStrategyAmount = toScaled(assetAmount, 6)

  const hasEnoughUSDC = balanceQuery.gte(requiredUSDCAmount)
  const enoughUSDCApproved = allowanceQuery.gte(requiredUSDCAmount)
  const hasEnoughStrategyToken = strategyBalanceQuery.gte(
    requiredStrategyAmount
  )

  return (
    <div className="rounded-3xl bg-secondaly border-[1px] border-white leading-5">
      <div className="m-[-1px] rounded-3xl bg-white0 border-[1px] border-white leading-5">
        <div className="mx-5 my-5">
          <SideSelector side={tradeSide} onChange={setTradeSide} />
        </div>
        <div className="mx-5 my-5 p-4 rounded-xl bg-black4 shadow-sm">
          <AmountRangeInput
            amount={0}
            max={assetId === 1 ? 5000 : 50}
            title={ASSET_NAMES[assetId]}
            step={1}
            onChange={setAssetAmount}
          />
        </div>
      </div>

      <div className="mx-5 my-5">
        <div className="text-base">Historical Performance based on UTC</div>
        <div className="mt-4 px-1 space-y-2">
          <div className="text-sm flex justify-between border-b-[1px] border-white3">
            <div>1 Day</div>
            <AprComponent apr={lendingApy.dayApr} />
          </div>
          <div className="text-sm flex justify-between border-b-[1px] border-white3">
            <div>7 Days</div>
            <AprComponent apr={lendingApy.weekApr} />
          </div>
          <div className="text-sm flex justify-between border-b-[1px] border-white3">
            <div>30 Days</div>
            <AprComponent apr={lendingApy.monthApr} />
          </div>
          <div className="text-sm flex justify-between border-b-[1px] border-white3">
            <div>365 Days (or as available converted)</div>
            <AprComponent apr={lendingApy.yearApr} />
          </div>
        </div>
      </div>
      <div className="mx-5 my-5 h-12 flex justify-between items-center space-x-2">
        <PrimaryButton
          onClick={onApprove}
          disabled={
            tradeSide ||
            isPendingApproval ||
            enoughUSDCApproved ||
            !hasEnoughUSDC
          }
        >
          Approve
        </PrimaryButton>
        <PrimaryButton
          onClick={onTrade}
          disabled={
            (tradeSide && !hasEnoughStrategyToken) ||
            (!tradeSide &&
              (!enoughUSDCApproved || isPendingTx || !hasEnoughUSDC)) ||
            assetAmount === 0
          }
        >
          {tradeSide ? 'Withdraw' : 'Deposit'}
        </PrimaryButton>
      </div>

      {(!tradeSide && hasEnoughUSDC) ||
      (tradeSide && hasEnoughStrategyToken) ? (
        <></>
      ) : (
        <div className="mx-5 my-5 text-xs text-red">
          <span>
            Insufficient{' '}
            {tradeSide ? `p${ASSET_NAMES[assetId]}` : ASSET_NAMES[assetId]}{' '}
            amount in your wallet.
          </span>
        </div>
      )}
    </div>
  )
}

export default LendingTradeForm
