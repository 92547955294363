import React, { ReactNode } from 'react'
import { IRMParams } from '../../utils/irm'
import { toUnscaled } from '../../utils/bn'

const FontFamily = 'Inter'

const LabelText = ({
  x,
  y,
  children
}: {
  x: number
  y: number
  children: ReactNode
}) => {
  return (
    <text
      x={x}
      y={y}
      fontFamily={FontFamily}
      fontSize="6"
      fontWeight={400}
      fill="#ffffff"
    >
      {children}
    </text>
  )
}

const InterestRateModelChart = ({
  utilization,
  irmParams
}: {
  utilization: number
  irmParams: IRMParams
}) => {
  const utilizationPosX = utilization * 2.8
  const kinkRate = toUnscaled(irmParams.kinkRate, 16) * 2.8

  return (
    <div>
      <svg aria-hidden="true" viewBox="0 0 320 140" preserveAspectRatio="none">
        <g transform="translate(20 0)">
          <path
            d="M 0 120 L 0 0"
            stroke="#ffffff80"
            strokeDasharray={2}
            strokeWidth={0.5}
          />
          <path
            d="M 0 120 L 320 120"
            stroke="#ffffff80"
            strokeDasharray={2}
            strokeWidth={0.5}
          />
          <g>
            <LabelText x={5} y={130}>
              0%
            </LabelText>
            <LabelText x={140} y={130}>
              50%
            </LabelText>
            <LabelText x={280} y={130}>
              100%
            </LabelText>
            <LabelText x={270} y={136}>
              Utilization
            </LabelText>
          </g>
          <g>
            <LabelText x={-20} y={120}>
              0%
            </LabelText>
            <LabelText x={-20} y={65}>
              50%
            </LabelText>
            <LabelText x={-20} y={10}>
              100%
            </LabelText>
          </g>
        </g>
        <g transform="translate(20 0)">
          <path
            d={`M 0 118 L ${kinkRate.toFixed(0)} 112 L 280 90`}
            stroke="#ffffff80"
            strokeWidth={0.5}
            fill="none"
          />
          <g>
            <path
              d={`M ${utilizationPosX.toFixed(
                0
              )} 120 L ${utilizationPosX.toFixed(0)} 40`}
              stroke="#7070ff"
              strokeDasharray={2}
              strokeWidth={0.5}
            />
            <LabelText x={Number((utilizationPosX - 20).toFixed(0))} y={30}>
              Current {utilization.toFixed(2)}%
            </LabelText>
          </g>
        </g>
      </svg>
    </div>
  )
}

export default InterestRateModelChart
