import React, { useState } from 'react'
import { Product } from '../../constants/enum'
import { PerpPriceInfos } from '../perps/PerpPriceInfo'
import PositionChart from './PositionChart'
import useGammaTrade from '../../store/trade'
import { useCachedPrice } from '../../hooks/usePrice'
import { toUnscaled } from '../../utils/bn'
import { GammaTradeResult } from '../../hooks/views/gamma'
import { MARGIN } from '../../constants'

const GammaCenterArea = ({
  assetId,
  gammaTradeResult
}: {
  assetId: number
  gammaTradeResult: GammaTradeResult
}) => {
  const [selectedPerp, setSelectedPerp] = useState(Product.SQRT)
  const { squartSide, squartAmount } = useGammaTrade()
  const price = useCachedPrice(assetId)

  return (
    <div>
      <PerpPriceInfos
        assetId={assetId}
        selectedPerp={selectedPerp}
        onSelect={setSelectedPerp}
      />
      <div className="mt-4 flex justify-center">
        <div className="w-[668px]">
          <h1 className="text-3xl font-bold">Gamma Trading Isolated</h1>
          <p className="text-lg leading-6">
            In general, The Buy Gamma pays a fee for the period in which it can
            profit from a price move, while the Sell Gamma provides the
            opportunity to do the opposite by receiving a fee. The Vault is
            Isolated, meaning that you can open several Vaults, but once opened
            you can only Close or Liquidaiton.
          </p>
        </div>
      </div>
      <div className="mt-4 flex justify-center items-center">
        <div className="mt-8 w-[668px]">
          <PositionChart
            currentPrice={toUnscaled(
              price.price,
              MARGIN.DECIMALS,
              MARGIN.SIGNIFICANT
            )}
            squartSide={squartSide}
            squartAmount={squartAmount}
            estLoss={gammaTradeResult.estLoss}
            estProfits={gammaTradeResult.estProfits}
          />
        </div>
      </div>
    </div>
  )
}

export default GammaCenterArea
