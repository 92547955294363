import React from 'react'
import { useChainId } from '../../../hooks/network'
import {
  NUM_ONE_PAGE_ITEMS,
  useMainVaultHistory
} from '../../../hooks/query/useVaultHistory'
import LoadingIndicator from '../../common/LoadingIndicator'
import { HistoryTable } from '../../positions/PositionTable'

const TradeHistory = ({ vaultId }: { vaultId: number }) => {
  const { vaultHistory, setSkip, loading } = useMainVaultHistory(vaultId)
  const chainId = useChainId()

  return (
    <div>
      <div>
        {vaultHistory.length === 0 && loading ? (
          <div className="flex justify-center items-center">
            <div className="w-12 h-12">
              <LoadingIndicator />
            </div>
          </div>
        ) : (
          <HistoryTable
            fontSize="text-sm"
            enableScroll={true}
            blockExplorerLink={
              chainId === 42161 ? 'arbiscan.io' : 'goerli.arbiscan.io'
            }
            items={vaultHistory.map(vaultHistoryItem => {
              return {
                timestamp: vaultHistoryItem.timestamp,
                symbol: vaultHistoryItem.symbolString,
                side: vaultHistoryItem.side.toString(),
                price: vaultHistoryItem.price,
                size: Math.abs(vaultHistoryItem.size).toString(),
                payoff: vaultHistoryItem.payoff,
                txHash: vaultHistoryItem.txHash
              }
            })}
          />
        )}
        <div className="flex items-center justify-center">
          {vaultHistory.length >= NUM_ONE_PAGE_ITEMS ? (
            <button
              className="w-8 h-8"
              onClick={() => {
                setSkip(vaultHistory.length)
              }}
              disabled={loading}
            >
              {loading ? <LoadingIndicator /> : 'More'}
            </button>
          ) : (
            <div />
          )}
        </div>
      </div>
    </div>
  )
}

export default TradeHistory
