import React, { useState } from 'react'
import { useWeb3React } from '@web3-react/core'
import { ethers } from 'ethers'
import { MyPositionTab } from '../../../constants/enum'
import { useFeeEst } from '../../../hooks/query/interest/useFeeEst'
import {
  useVaultStatus,
  useVaultSummary
} from '../../../hooks/query/useVaultStatus'
import { toUnscaled } from '../../../utils/bn'
import { usdcAmountToString } from '../../../utils/number'
import { Tab, TabList } from '../../common/Tab'
import OpenPositions from './OpenPositions'
import TradeHistory from './TradeHistory'
import { useExtraVaultStatus } from '../../../hooks/query/useExtraVaultStatus'
import { MARGIN, ZERO } from '../../../constants'

const MyPosition = ({ assetId }: { assetId: number }) => {
  const { account } = useWeb3React<ethers.providers.Web3Provider>()
  const [tab, setTab] = useState(MyPositionTab.Positions)
  const vaultSummary = useVaultSummary()
  const vaultStatusList = useVaultStatus()
  const extraVaultStatus = useExtraVaultStatus()
  const feeEst = useFeeEst(
    assetId,
    vaultStatusList.isSuccess
      ? vaultStatusList.data?.isolatedVaults.map(vault => vault.id)
      : [],
    ZERO,
    ZERO
  )

  return (
    <div className="mt-1 p-4 rounded-2xl bg-secondaly border-[1px] border-white">
      <div className="md:w-[680px] mb-4 flex justify-between border-b-[1px] border-white">
        <div>
          <TabList
            onSelect={tabIndex => {
              setTab(tabIndex as MyPositionTab)
            }}
          >
            <Tab
              selected={tab === MyPositionTab.Positions}
              tabId={MyPositionTab.Positions}
            >
              Positions
            </Tab>
            <Tab
              selected={tab === MyPositionTab.TradeHistory}
              tabId={MyPositionTab.TradeHistory}
            >
              Trade History
            </Tab>
          </TabList>
        </div>
        <div className="hidden sm:flex justify-center items-center text-subtext">
          <div className="mb-2">Total</div>
        </div>
        <div className="hidden sm:block flex flex-col space-y-2 text-xs">
          <div className="text-subtext">Isolated Vault Value</div>
          <div>
            $
            {vaultSummary.isSuccess
              ? toUnscaled(
                  vaultSummary.data.isolatedVaultValue,
                  MARGIN.DECIMALS,
                  MARGIN.SIGNIFICANT
                )
              : '-'}
          </div>
        </div>
        <div className="hidden sm:block flex flex-col space-y-2 text-xs">
          <div className="text-subtext">Margin Isolated</div>
          <div>${usdcAmountToString(vaultSummary.data?.totalMargin)}</div>
        </div>
        <div className="hidden sm:block flex flex-col space-y-2 text-xs">
          <div className="text-subtext">Fee est.</div>
          <div>
            $
            {feeEst.isSuccess ? usdcAmountToString(feeEst.data.totalFeeEst) : 0}
          </div>
        </div>
      </div>
      {tab === MyPositionTab.Positions ? (
        <OpenPositions
          assetId={assetId}
          feeEst={feeEst.data}
          vaultStatusList={
            vaultStatusList.isSuccess ? vaultStatusList.data.isolatedVaults : []
          }
          extraVaultStatusList={extraVaultStatus.isolatedVaults}
        />
      ) : account ? (
        <TradeHistory account={account} />
      ) : (
        <></>
      )}
    </div>
  )
}

export default MyPosition
