import { BigNumber } from 'ethers'
import { useQuery } from 'react-query'
import { ONE, Q128, UNDERLYING_ONE, ZERO } from '../../../constants'
import { goerliArbitrum } from '../../../constants/addresses'
import { useAddresses } from '../../useAddress'
import { usePrice } from '../../usePrice'
import { createEmptyPosition, useVaults } from '../useVault'
import {
  calculateAssetInterest,
  calculateSquartInterest
} from './useAssetInterest'
import { useUniswapTradeFee24H } from './useUniswapTradeFee'
import { Asset, useAsset } from '../useAsset'
import { useTradeQuoter } from '../useQuoter'
import { priceMul, sqrtPriceMul } from '../../../utils/price'
import {
  calculateAmount0Offset,
  calculateAmount1Offset
} from '../../../utils/uniswap'

export function estimateFee(
  assetUnderlying: Asset,
  assetStable: Asset,
  price: BigNumber,
  sqrtPrice: BigNumber,
  position: {
    stableAmount: BigNumber
    underlyingAmount: BigNumber
    sqrt2Amount: BigNumber
  },
  diff: {
    stableAmount: BigNumber
    underlyingAmount: BigNumber
    sqrt2Amount: BigNumber
  },
  uniswapTradeFee: {
    fee0: BigNumber
    fee1: BigNumber
  }
) {
  const squartInterest = calculateSquartInterest(
    assetUnderlying,
    sqrtPrice,
    position.sqrt2Amount.sub(diff.sqrt2Amount),
    diff.sqrt2Amount
  )

  const borrowPremium = squartInterest.borrowInterest
    .mul(position.sqrt2Amount)
    .div(UNDERLYING_ONE)
    .div(365)

  const supplyPremium = squartInterest.supplyInterest
    .mul(position.sqrt2Amount)
    .div(UNDERLYING_ONE)
    .div(365)

  const tradeFeePerLiquidity = squartInterest.supply.eq(0)
    ? ZERO
    : uniswapTradeFee.fee0
        .mul(price)
        .div(UNDERLYING_ONE)
        .add(uniswapTradeFee.fee1)
        .mul(squartInterest.supply.sub(squartInterest.borrow))
        .div(squartInterest.supply)

  let totalInterestStable = ZERO
  let totalInterestUnderlying = ZERO

  if (position.sqrt2Amount.gt(0)) {
    totalInterestStable = supplyPremium.add(
      tradeFeePerLiquidity.mul(position.sqrt2Amount).div(Q128)
    )
  } else {
    totalInterestStable = borrowPremium
  }

  // Calculates Asset interest
  totalInterestStable = totalInterestStable.add(
    calculateDailyAssetInterest(
      assetStable,
      position.stableAmount,
      diff.stableAmount
    )
  )
  totalInterestUnderlying = totalInterestUnderlying.add(
    calculateDailyAssetInterest(
      assetUnderlying,
      position.underlyingAmount,
      diff.underlyingAmount
    )
  )

  return totalInterestStable.add(
    totalInterestUnderlying.mul(price).div(UNDERLYING_ONE)
  )
}

function calculateDailyAssetInterest(
  asset: Asset,
  position: BigNumber,
  trade: BigNumber
) {
  const stableInterest = calculateAssetInterest(
    asset,
    position.sub(trade),
    trade
  )

  if (position.gt(0)) {
    return position.mul(stableInterest.supplyInterest).div(ONE).div(365)
  } else {
    return position.mul(stableInterest.borrowInterest).div(ONE).div(365)
  }
}

export function useFeeEst(
  assetId: number,
  vaultIds: number[],
  tradeAmountPerp: BigNumber,
  tradeAmount2Sqrt: BigNumber
) {
  const assetStable = useAsset(1)
  const asset = useAsset(assetId)
  const vaults = useVaults(vaultIds)
  const price = usePrice(assetId)
  const quote = useTradeQuoter({
    vaultId: vaultIds[0],
    assetId,
    tradeAmount: tradeAmountPerp,
    tradeAmountSqrt: tradeAmount2Sqrt
  })
  const addresses = useAddresses()
  const tradeFeeIn24h = useUniswapTradeFee24H(
    addresses?.assets[assetId].UniswapV3Pool ||
      goerliArbitrum.assets[2].UniswapV3Pool
  )

  return useQuery(
    ['fee_est', assetId, vaultIds, tradeAmountPerp, tradeAmount2Sqrt],

    async () => {
      if (!assetStable.isSuccess) throw new Error('assetStable not set')
      if (!asset.isSuccess) throw new Error('asset not set')
      if (!vaults.isSuccess) throw new Error('vaults not set')
      if (!price.isSuccess) throw new Error('price not set')
      if (!tradeFeeIn24h.isSuccess) throw new Error('tradeFeeIn24h not set')

      const positions = vaults.isSuccess
        ? vaults.data.length === 0
          ? [createEmptyPosition(assetId)]
          : vaults.data.map(vault => {
              return vault.openPositions.length > 0
                ? vault.openPositions[0]
                : createEmptyPosition(assetId)
            })
        : []

      const tradeAmountStable =
        quote.isSuccess && quote.data.data
          ? quote.data.data.perpEntryUpdate
              .add(quote.data.data.sqrtEntryUpdate)
              .add(quote.data.data.sqrtRebalanceEntryUpdateStable)
          : priceMul(price.data.price, tradeAmountPerp.mul(-1))
              .add(sqrtPriceMul(price.data.sqrtPrice, tradeAmount2Sqrt.mul(-2)))
              .add(
                calculateAmount1Offset(
                  asset.data.sqrtAssetStatus.tickUpper,
                  tradeAmount2Sqrt
                )
              )

      const tradeAmountUnderlying = tradeAmountPerp.add(
        quote.isSuccess && quote.data.data
          ? quote.data.data.sqrtRebalanceEntryUpdateUnderlying
          : calculateAmount0Offset(
              asset.data.sqrtAssetStatus.tickLower,
              tradeAmount2Sqrt
            )
      )

      const feeEsts = positions.map(position => {
        const positionDiffSquart = tradeAmount2Sqrt.add(
          position.sqrtPerpPosition
        )
        const positionDiffStable = tradeAmountStable.add(
          position.stableAssetOrDebtAmount
        )
        const positionDiffUnderlying = tradeAmountUnderlying.add(
          position.underlyingAssetOrDebtAmount
        )

        return estimateFee(
          asset.data,
          assetStable.data,
          price.data.price,
          price.data.sqrtPrice,
          {
            stableAmount: positionDiffStable,
            underlyingAmount: positionDiffUnderlying,
            sqrt2Amount: positionDiffSquart
          },
          {
            stableAmount: tradeAmountStable,
            underlyingAmount: tradeAmountUnderlying,
            sqrt2Amount: tradeAmount2Sqrt
          },
          {
            fee0: tradeFeeIn24h.data.fee0,
            fee1: tradeFeeIn24h.data.fee1
          }
        )
      })

      const totalFeeEst = feeEsts.reduce((acc, item) => acc.add(item), ZERO)

      const vaultsFeeEst = vaultIds.map((vaultId, i) => {
        return {
          vaultId: vaultId,
          feeEst: feeEsts[i]
        }
      })

      return {
        totalFeeEst,
        vaultsFeeEst
      }
    },
    {
      enabled:
        assetStable.isSuccess &&
        asset.isSuccess &&
        vaults.isSuccess &&
        price.isSuccess &&
        tradeFeeIn24h.isSuccess
    }
  )
}
