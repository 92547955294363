import React from 'react'
import SideBar from '../components/SideBar'
import LendingTradeForm from '../components/lending/LendingTradeForm'
import LendingCenterArea from '../components/lending/LendingCenterArea'
import LendingPosition from '../components/lending/LendingPosition'
import useSupplyAssetTypeStore from '../store/supplyAssetType'
import { useAddresses } from '../hooks/useAddress'
import { useAsset } from '../hooks/query/useAsset'
import LoadingIndicator from '../components/common/LoadingIndicator'

const LendingView = () => {
  const { selectedAsset } = useSupplyAssetTypeStore()
  const addresses = useAddresses()
  const asset = useAsset(selectedAsset)

  return (
    <div className="grid grid-cols-trade-sm sm:grid-cols-lending grid-rows-lending-sm sm:grid-rows-trade gap-2">
      <div className="col-span-1 row-span-2">
        <SideBar />
      </div>
      <div className="col-span-1 row-span-2">
        {addresses && asset.isSuccess ? (
          <LendingTradeForm
            assetId={selectedAsset}
            addresses={addresses}
            asset={asset.data}
          />
        ) : (
          <div className="w-12 h-12">
            <LoadingIndicator />
          </div>
        )}
      </div>
      <div className="hidden sm:block col-span-1 row-span-1">
        <LendingCenterArea assetId={selectedAsset} />
      </div>
      <div className="col-span-1 row-span-1">
        <div className="md:mx-4">
          <LendingPosition assetId={selectedAsset} addresses={addresses} />
        </div>
      </div>
    </div>
  )
}

export default LendingView
