import { useQuery } from '@apollo/client'
import { BigNumber } from 'ethers'
import { useEffect, useState } from 'react'
import {
  StrategyHistoryItems,
  STRATEGY_HISTORY_ITEM_QUERY
} from '../../../queries/strategyHistoryItemQuery'

export type StrategyHistoryItem = {
  id: string
  address: string
  account: string
  action: string
  strategyAmount: BigNumber
  value: BigNumber
  price: BigNumber
  payoff: BigNumber
  timestamp: number
}

export const NUM_ONE_PAGE_ITEMS = 500

export function useStrategyHistory(account: string, contractAddress: string) {
  const [skip, setSkip] = useState(0)
  const [strategyHistory, setStrategyHistory] = useState<StrategyHistoryItem[]>(
    []
  )

  const { data } = useQuery<StrategyHistoryItems>(STRATEGY_HISTORY_ITEM_QUERY, {
    fetchPolicy: 'cache-and-network',
    variables: {
      owner: account.toLowerCase(),
      address: contractAddress.toLocaleLowerCase(),
      skip: skip,
      first: NUM_ONE_PAGE_ITEMS
    },
    pollInterval: 30000
  })

  useEffect(() => {
    if (data) {
      const newItems = data.strategyUserHistoryItems.map(entity => {
        const strategyAmount = BigNumber.from(entity.strategyAmount)
        const marginAmount = BigNumber.from(entity.marginAmount)
        const payoff = BigNumber.from(entity.payoff)

        return {
          id: entity.id,
          account: entity.account,
          address: entity.address.toLowerCase(),
          action: entity.action === 'DEPOSIT' ? 'Buy' : 'Sell',
          strategyAmount,
          value: marginAmount,
          price: marginAmount.mul('1000000').div(strategyAmount),
          payoff,
          timestamp: Number(entity.createdAt)
        }
      })

      setStrategyHistory(history => {
        return history
          .concat(newItems)
          .map(item => Object.assign({}, item))
          .sort((a, b) => b.timestamp - a.timestamp)
          .filter(
            val =>
              val.address === contractAddress.toLowerCase() &&
              val.account === account.toLowerCase()
          )
          .filter(
            (val, ind, self) =>
              ind === self.findIndex(item => item.id === val.id)
          )
      })
    }
  }, [account, data, contractAddress])

  return {
    strategyHistory,
    skip,
    setSkip
  }
}
