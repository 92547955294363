import React, { useEffect, useState } from 'react'
import { useWeb3React } from '@web3-react/core'
import { metaMask } from '../../connectors'
import ArbitrumIcon from '../../assets/arbitrum.svg'
import { usePrevious } from 'react-use'
import { switchNetwork } from '../../utils/switchNetwork'
import { useIsSupportedChain } from '../../hooks/network'

export const HeaderConnectButton = ({
  children
}: {
  children?: React.ReactNode
}) => {
  return (
    <div className="w-40 h-8 rounded-xl border-2 border-white">
      <BaseConnectButton>{children}</BaseConnectButton>
    </div>
  )
}

export const ConnectButton = ({ children }: { children?: React.ReactNode }) => {
  return (
    <button className="w-full h-10 final-gradient rounded-2xl text-xl font-normal">
      <BaseConnectButton>{children}</BaseConnectButton>
    </button>
  )
}

const BaseConnectButton = ({ children }: { children?: React.ReactNode }) => {
  const { account, isActive } = useWeb3React()
  const [activateClicked, setActivateClicked] = useState(false)
  const prevClicked = usePrevious(activateClicked)
  const supported = useIsSupportedChain()

  useEffect(() => {
    if (!prevClicked && activateClicked && isActive && account) {
      // To tract user connection, please put code here
      localStorage.setItem('predy.connected', 'true')
    }
  }, [activateClicked, isActive]) // eslint-disable-line

  const onConnectClicked = async () => {
    await switchNetwork()
    await metaMask.activate()
    // await activate(injected)
    setActivateClicked(true)
  }

  if (isActive && account && supported) {
    return (
      <>
        <div className="w-full h-full px-2 background-black text-white rounded-md flex flex-row items-center">
          <img className="mr-1 basis-1/6 my-auto" src={ArbitrumIcon} />
          <div className="basis-5/6 my-auto">
            {account.slice(0, 6)}…{account.slice(38)}
          </div>
        </div>
      </>
    )
  }

  return (
    <div
      className="w-full h-full background-black text-white rounded-md flex justify-center items-center cursor-pointer"
      onClick={onConnectClicked}
    >
      {supported ? (children ? children : 'Connect Wallet') : 'Switch Network'}
    </div>
  )
}
