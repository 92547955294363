import { BigNumber, BigNumberish, ethers } from 'ethers'
import { useWeb3React } from '@web3-react/core'
import { useQuery } from 'react-query'
import { useChainId, useIsSupportedChain } from '../network'
import { useAddresses } from '../useAddress'
import { Controller, Controller__factory } from '../../typechain'
import { useDeadline } from '../useBlockTimestamp'
import {
  REFETCH_INTERVAL,
  SQUART_ONE,
  UNDERLYING_ONE,
  ZERO
} from '../../constants'
import { usePrice } from '../usePrice'
import { computeLowerSqrtPrice, computeUpperSqrtPrice } from '../../utils'

type QuoteResult = {
  tradeAmount: BigNumber
  squartAmount: BigNumber
  perpEntryPrice: BigNumber
  sqrtEntryPrice: BigNumber
  perpEntryUpdate: BigNumber
  sqrtEntryUpdate: BigNumber
  sqrtRebalanceEntryUpdateUnderlying: BigNumber
  sqrtRebalanceEntryUpdateStable: BigNumber
  perpPayoff: BigNumber
  sqrtPayoff: BigNumber
  fee: BigNumber
  minDeposit: BigNumber
}

type QuoterResult =
  | {
      error: string
      data: null
    }
  | {
      error: null
      data: QuoteResult
    }

export type CloseQuoterResult =
  | {
      error: string
      data: null
      subVaultId: number
    }
  | {
      error: null
      data: QuoteResult
      subVaultId: number
    }

function convertToQuoteResult(
  data: any,
  tradeAmount: BigNumber,
  squartAmount: BigNumber
) {
  return {
    tradeAmount,
    squartAmount,
    perpEntryPrice: tradeAmount.eq(0)
      ? ZERO
      : data[0][0].add(data[0][4]).mul(UNDERLYING_ONE).div(tradeAmount).abs(),
    sqrtEntryPrice: squartAmount.eq(0)
      ? ZERO
      : data[0][1].add(data[0][5]).mul(SQUART_ONE).div(squartAmount).abs(),
    perpEntryUpdate: data[0][0],
    sqrtEntryUpdate: data[0][1],
    sqrtRebalanceEntryUpdateUnderlying: data[0][2],
    sqrtRebalanceEntryUpdateStable: data[0][3],
    perpPayoff: data[0][4],
    sqrtPayoff: data[0][5],
    fee: data[1],
    minDeposit: data[2]
  }
}

type TradePerpParams = {
  vaultId: number
  assetId: number
  tradeAmount: BigNumberish
  tradeAmountSqrt: BigNumberish
}

type OpenVaultParams = {
  assetId: number
  tradeAmount: BigNumberish
  tradeAmountSqrt: BigNumberish
  marginAmount: BigNumber
}

type CloseVaultParams = {
  isolatedVaultId: number
  assetId: number
  tradeAmount: BigNumberish
  tradeAmountSqrt: BigNumberish
}

type BaseTradeParams = {
  assetId: number
  tradeAmount: BigNumberish
  tradeAmountSqrt: BigNumberish
}

function useBaseTradeQuoter<T extends BaseTradeParams>(
  key: string,
  params: T,
  cb: (
    contract: Controller,
    params: T,
    sqrtPrice: BigNumber,
    deadline: number,
    account: string,
    chainId: number
  ) => Promise<any>
) {
  const { provider, account } = useWeb3React<ethers.providers.Web3Provider>()
  const supportedChain = useIsSupportedChain()
  const chainId = useChainId()
  const addresses = useAddresses()
  const deadline = useDeadline()
  const price = usePrice(params.assetId)

  return useQuery<QuoterResult>(
    [key, account, chainId, params],

    async () => {
      if (!account) throw new Error('Account not set')
      if (!provider) throw new Error('provider not set')
      if (!addresses) throw new Error('addresses not set')
      if (!chainId) throw new Error('chainId not set')
      if (!deadline.isSuccess) throw new Error('deadline not set')
      if (!price.isSuccess) throw new Error('price not set')

      const contract = Controller__factory.connect(
        addresses.Controller,
        provider
      )
      try {
        const data = await cb(
          contract,
          params,
          price.data.sqrtPrice,
          deadline.data,
          account,
          chainId
        )

        return {
          error: null,
          data: convertToQuoteResult(
            data,
            BigNumber.from(params.tradeAmount),
            BigNumber.from(params.tradeAmountSqrt)
          )
        }
      } catch (e: any) {
        if (e.error && e.error.message === "Non-200 status code: '429'") {
          throw new Error(e.error.message)
        }

        return {
          error: String(e.reason || e.data.message),
          data: null
        }
      }
    },
    {
      enabled:
        !!account &&
        supportedChain &&
        !!provider &&
        !!addresses &&
        deadline.isSuccess &&
        price.isSuccess,
      refetchInterval: REFETCH_INTERVAL
    }
  )
}

export function useTradeQuoter(params: TradePerpParams) {
  return useBaseTradeQuoter(
    'quoter_trade_perp',
    params,
    async (
      contract: Controller,
      params: TradePerpParams,
      sqrtPrice: BigNumber,
      deadline: number,
      account: string,
      chainId: number
    ) => {
      const data = await contract.callStatic.tradePerp(
        params.vaultId,
        params.assetId,
        {
          tradeAmount: params.tradeAmount,
          tradeAmountSqrt: params.tradeAmountSqrt,
          lowerSqrtPrice: computeLowerSqrtPrice(sqrtPrice, chainId),
          upperSqrtPrice: computeUpperSqrtPrice(sqrtPrice, chainId),
          deadline: deadline,
          enableCallback: false,
          data: '0x'
        },
        { from: account }
      )

      return data
    }
  )
}

export function useOpenVaultQuoter(params: OpenVaultParams) {
  return useBaseTradeQuoter(
    'quoter_open_vault',
    params,
    async (
      contract: Controller,
      params: OpenVaultParams,
      sqrtPrice: BigNumber,
      deadline: number,
      account: string,
      chainId: number
    ) => {
      const data = await contract.callStatic.openIsolatedVault(
        params.marginAmount,
        params.assetId,
        {
          tradeAmount: params.tradeAmount,
          tradeAmountSqrt: params.tradeAmountSqrt,
          lowerSqrtPrice: computeLowerSqrtPrice(sqrtPrice, chainId),
          upperSqrtPrice: computeUpperSqrtPrice(sqrtPrice, chainId),
          deadline: deadline,
          enableCallback: false,
          data: '0x'
        },
        { from: account }
      )

      return data[1]
    }
  )
}

export function useCloseVaultQuoter(params: CloseVaultParams) {
  return useBaseTradeQuoter(
    'quoter_close_vault',
    params,
    async (
      contract: Controller,
      params: CloseVaultParams,
      sqrtPrice: BigNumber,
      deadline: number,
      account: string,
      chainId: number
    ) => {
      const data = await contract.callStatic.closeIsolatedVault(
        params.isolatedVaultId,
        params.assetId,
        {
          lowerSqrtPrice: computeLowerSqrtPrice(sqrtPrice, chainId),
          upperSqrtPrice: computeUpperSqrtPrice(sqrtPrice, chainId),
          deadline: deadline
        },
        { from: account }
      )

      return data
    }
  )
}
