import React, { useState } from 'react'
import PnLChart, { ChartMode } from './PnLChart'
import { usePnLChartData } from '../../../hooks/query/chart/usePnLChartData'
import { LoadingIndicatorPt } from '../../common/LoadingIndicator'
import positionStore from '../../../store/position'
import gammaStore from '../../../store/gamma'
import { useCachedPrice } from '../../../hooks/usePrice'
import { SimpleTab, SimpleTabList } from '../../common/SimpleTab'
import { getStylePrice } from '../trade/StyleSelector'

const PnLChartWrapper = ({
  vaultId,
  assetId
}: {
  vaultId: number
  assetId: number
}) => {
  const price = useCachedPrice(assetId)
  const { afterPerp, after2Squart, afterEntryUpdate } = positionStore()
  const { targetStrategy } = gammaStore()

  const [chartMode, setChartMode] = useState(ChartMode.Value)
  const data = usePnLChartData(
    assetId,
    vaultId,
    afterPerp,
    after2Squart,
    afterEntryUpdate
  )

  return (
    <div className="my-8 mx-auto w-[680px]">
      <SimpleTabList
        onSelect={tabIndex => {
          setChartMode(tabIndex as ChartMode)
        }}
      >
        <SimpleTab
          selected={chartMode === ChartMode.Value}
          tabId={ChartMode.Value}
        >
          P&L
        </SimpleTab>
        <SimpleTab
          selected={chartMode === ChartMode.Delta}
          tabId={ChartMode.Delta}
        >
          Delta
        </SimpleTab>
        <SimpleTab
          selected={chartMode === ChartMode.Gamma}
          tabId={ChartMode.Gamma}
        >
          Gamma
        </SimpleTab>
      </SimpleTabList>
      <div>
        {data.data.length > 0 ? (
          <PnLChart
            price={price.price.div('1000000').toNumber()}
            style={getStylePrice(
              after2Squart.lt(0),
              targetStrategy,
              price.price
            )
              .div('1000000')
              .toNumber()}
            mode={chartMode}
            data={data.data}
            hasCurrentPosition={data.hasCurrentPosition}
          />
        ) : (
          <LoadingIndicatorPt />
        )}
      </div>
    </div>
  )
}

export default PnLChartWrapper
