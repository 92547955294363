import React, { useCallback, useState } from 'react'
import { toScaled, toUnscaled } from '../../utils/bn'
import { useTradePerp } from '../../hooks/contracts/useTradePerp'
import { PrimaryButton } from '../common/Button'
import AmountRangeInput from '../common/AmountRangeInput'
import SideSelector from '../common/SideSelector'
import { useTradeQuoter } from '../../hooks/query/useQuoter'
import { usdcAmountToString } from '../../utils/number'
import { ASSET_NAMES, ZERO } from '../../constants'
import { getDelta, getGamma } from '../../utils/bs'
import { useCachedPrice } from '../../hooks/usePrice'
import { InterestEstPanel } from './InterestEstPanel'
import { reasonToErrorMessage } from '../../utils/error'
import { useVaultStatus } from '../../hooks/query/useVaultStatus'
import { BigNumber } from 'ethers'
import { toUnscaledSqrtPrice } from '../../utils/price'
import { useSlippageTolerance } from '../../hooks/query/common/slippage'
import pendingStore from '../../store/pending'
import { LoadingIndicatorPt } from '../common/LoadingIndicator'
import {
  calculateMaxPerpAmount,
  calculateMaxSquartAmountForLong
} from '../../utils/helpers/maxSquartAmount'

const getMaximum = (
  productNo: number,
  available: BigNumber,
  sqrtPrice: BigNumber
) => {
  const maxSquartAmount = calculateMaxSquartAmountForLong(available, sqrtPrice)
  const maxPerpAmount = calculateMaxPerpAmount(
    maxSquartAmount.div(2),
    sqrtPrice
  )
  const maxSquartAmountNum = maxSquartAmount.eq(0)
    ? 5000
    : toUnscaled(maxSquartAmount, 12, 0)
  const maxPerpAmountNum = maxPerpAmount.eq(0)
    ? 100
    : toUnscaled(maxPerpAmount, 18, 0)

  return [maxSquartAmountNum, maxPerpAmountNum][productNo]
}

const TradeForm = ({
  vaultId,
  assetId
}: {
  vaultId: number
  assetId: number
}) => {
  const { isPendingTx, setPendingTx } = pendingStore()
  const [squartSide, setSquartSide] = useState([false, false, false, false])
  const [perpSide, setPerpSide] = useState([false, false, false, false])
  const [squartAmount, setSquartAmount] = useState([0, 0, 0, 0])
  const [perpAmount, setPerpAmount] = useState([0, 0, 0, 0])

  const trade = useTradePerp(assetId)
  const price = useCachedPrice(assetId)
  const vaultStatus = useVaultStatus()
  const slippageTolerance = useSlippageTolerance()

  const tradeAmountSqrt = toScaled(squartAmount[assetId], 12)
    .div(2)
    .mul(squartSide[assetId] ? -1 : 1)
  const tradeAmountPerp = toScaled(perpAmount[assetId], 18).mul(
    perpSide[assetId] ? -1 : 1
  )

  const quote = useTradeQuoter({
    vaultId,
    assetId,
    tradeAmount: tradeAmountPerp,
    tradeAmountSqrt
  })

  const onTrade = useCallback(async () => {
    const tx = await trade.mutateAsync({
      vaultId,
      assetId,
      tradeAmount: toScaled(perpAmount[assetId], 18).mul(
        perpSide[assetId] ? -1 : 1
      ),
      tradeAmountSqrt: toScaled(squartAmount[assetId], 12)
        .div(2)
        .mul(squartSide[assetId] ? -1 : 1)
    })

    setPendingTx(tx)

    setPerpAmount([0, 0, 0, 0])
    setSquartAmount([0, 0, 0, 0])
  }, [
    vaultId,
    assetId,
    perpSide,
    squartSide,
    perpAmount,
    squartAmount,
    trade,
    setPendingTx
  ])

  return (
    <div className="rounded-3xl bg-secondaly border-[1px] border-white leading-5">
      <div className="m-[-1px] rounded-3xl bg-white0 border-[1px] border-white5 leading-5">
        <div className="mx-5 my-5">
          <SideSelector
            side={squartSide[assetId]}
            onChange={side => setSquartSide({ ...squartSide, [assetId]: side })}
          />
        </div>
        <div className="mx-5 my-5 p-4 rounded-xl bg-black4 shadow-sm">
          <AmountRangeInput
            amount={squartAmount[assetId]}
            max={getMaximum(
              0,
              vaultStatus.data?.mainVault?.available || ZERO,
              price.sqrtIndexPrice
            )}
            step={0.1}
            title={'√' + ASSET_NAMES[assetId]}
            onChange={amount =>
              setSquartAmount({ ...squartAmount, [assetId]: amount })
            }
          />
        </div>
      </div>
      <div className="m-[-1px] rounded-3xl bg-white0 border-[1px] border-white5 leading-5">
        <div className="mx-5 my-5">
          <SideSelector
            side={perpSide[assetId]}
            onChange={side => setPerpSide({ ...perpSide, [assetId]: side })}
          />
        </div>
        <div className="mx-5 my-5 p-4 rounded-xl bg-black4 shadow-sm">
          <AmountRangeInput
            amount={perpAmount[assetId]}
            max={getMaximum(
              1,
              vaultStatus.data?.mainVault?.available || ZERO,
              price.sqrtIndexPrice
            )}
            title={ASSET_NAMES[assetId]}
            onChange={amount =>
              setPerpAmount({ ...perpAmount, [assetId]: amount })
            }
          />
        </div>
      </div>
      <div className="mx-4 my-5 p-2 flex justify-between rounded-full bg-black3">
        <div className="py-1 px-2 rounded-full bg-white1 text-base font-semibold">
          Margin Utilizing
        </div>
        <div className="py-1 pr-2">
          <span className="text-white5 pr-1">
            {quote.isSuccess && quote.data.data
              ? usdcAmountToString(quote.data.data.minDeposit)
              : '-'}
          </span>
          USDC
        </div>
      </div>
      <div className="mx-5 my-5">
        <div className="text-base">Summary</div>
        <InterestEstPanel
          assetId={assetId}
          vaultId={vaultId}
          isSuccess={
            quote.isSuccess &&
            (!!quote.data.data || quote.data.error.indexOf('V1') >= 0)
          }
          trade={{
            tradeAmountPerp,
            tradeAmountSqrt
          }}
        />
        <div className="mt-2 space-y-2">
          <div className="text-sm flex justify-between border-b-[1px] border-white3">
            <div>Structure</div>
            <div>
              {squartSide[assetId] ? (
                <span className="text-red">Sell</span>
              ) : (
                <span className="text-green">Buy</span>
              )}{' '}
              √{ASSET_NAMES[assetId]} {squartAmount[assetId]},{' '}
              {perpSide[assetId] ? (
                <span className="text-red">Sell</span>
              ) : (
                <span className="text-green">Buy</span>
              )}{' '}
              {ASSET_NAMES[assetId]} {perpAmount[assetId]}
            </div>
          </div>
          <div className="text-sm flex justify-between border-b-[1px] border-white3">
            <div>Price</div>
            <div>
              {usdcAmountToString(quote.data?.data?.sqrtEntryPrice.div(2))},{' '}
              {usdcAmountToString(quote.data?.data?.perpEntryPrice)}
            </div>
          </div>
          <div className="text-sm flex justify-between border-b-[1px] border-white3">
            <div>Gamma, Delta</div>
            <div>
              <span>
                {getGamma(
                  (squartAmount[assetId] * (squartSide[assetId] ? -1 : 1)) / 2,
                  toUnscaledSqrtPrice(price.sqrtPrice)
                ).toFixed(6)}
              </span>
              <span>, </span>
              <span>
                {getDelta(
                  perpAmount[assetId] * (perpSide[assetId] ? -1 : 1),
                  (squartAmount[assetId] * (squartSide[assetId] ? -1 : 1)) / 2,
                  toUnscaledSqrtPrice(price.sqrtPrice)
                ).toFixed(2)}
              </span>
            </div>
          </div>
          <div className="text-sm flex justify-between border-b-[1px] border-white3">
            <div>Loss Threshold</div>
            <div>-</div>
          </div>
          <div className="text-sm flex justify-between border-b-[1px] border-white3">
            <div>Max Slippage</div>
            <div>{slippageTolerance}%</div>
          </div>
        </div>
      </div>

      <div className="mx-5 my-5 h-12">
        <PrimaryButton
          onClick={onTrade}
          disabled={
            isPendingTx ||
            !!(quote.isSuccess && quote.data.error) ||
            (perpAmount[assetId] === 0 && squartAmount[assetId] === 0)
          }
        >
          {isPendingTx ? <LoadingIndicatorPt /> : 'Trade'}
        </PrimaryButton>
      </div>

      {!quote.isSuccess || quote.data.error === null ? (
        <></>
      ) : (
        <div className="mx-5 my-5 text-xs text-red">
          <span>{reasonToErrorMessage(quote.data.error)}</span>
        </div>
      )}
    </div>
  )
}

export default TradeForm
