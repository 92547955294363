import create from 'zustand'
import { AssetType } from '../constants/enum'

interface State {
  selectedAsset: AssetType
  selectAsset: (selectedAsset: AssetType) => void
}

const useStore = create<State>(set => ({
  selectedAsset: AssetType.USDC,
  selectAsset: (selectedAsset: AssetType) => set({ selectedAsset })
}))

export default useStore
