import { useQuery } from '@apollo/client'
import { BigNumber } from 'ethers'
import { useMemo } from 'react'
import {
  AggregatedUniswapPriceEntities,
  AGGREGATED_UNISWAP_PRICE_QUERY,
  AggregationInterval
} from '../../queries/aggregatedPriceQuery'
import { UniswapClient } from '../../utils/apollo-client'
import { useChainId } from '../network'
import { DEFAULT_CHAIN } from '../../constants'

export function usePriceHistory(
  contractAddress: string,
  interval: AggregationInterval
) {
  const chainId = useChainId()

  const { data, loading } = useQuery<AggregatedUniswapPriceEntities>(
    AGGREGATED_UNISWAP_PRICE_QUERY,
    {
      fetchPolicy: 'cache-first',
      variables: {
        address: contractAddress.toLowerCase(),
        interval: interval.toString()
      },
      pollInterval: 20000,
      client: UniswapClient[chainId || DEFAULT_CHAIN]
    }
  )

  const priceHistory = useMemo(() => {
    if (data) {
      return data.aggregatedUniswapPriceEntities
        .map(item => ({
          address: item.address,
          openPrice: BigNumber.from(item.openPrice),
          closePrice: BigNumber.from(item.closePrice),
          openTimestamp: Number(item.openTimestamp),
          timestamp: Number(item.closeTimestamp)
        }))
        .filter(data => data.address === contractAddress.toLowerCase())
        .filter(data => !data.openPrice.eq(0) && !data.closePrice.eq(0))
    }
    return null
  }, [data, contractAddress])

  if (loading || priceHistory === null) {
    return null
  }

  return [...priceHistory].reverse()
}
