import React from 'react'
import { useChainId } from '../../../hooks/network'
import {
  SubVaultItem,
  useIsolatedVaultHistory
} from '../../../hooks/query/useVaultHistory'
import LoadingIndicator from '../../common/LoadingIndicator'
import { HistoryTable } from '../../positions/PositionTable'

const TradeHistory = ({ account }: { account: string }) => {
  const { vaultHistory, loading } = useIsolatedVaultHistory(account)
  const vaultIds = Object.keys(vaultHistory)

  return (
    <div className="block overflow-x-hidden overflow-y-scroll h-48 font-normal text-xs space-y-2">
      {vaultIds.length === 0 && loading ? (
        <div className="flex justify-center items-center">
          <div className="w-12 h-12">
            <LoadingIndicator />
          </div>
        </div>
      ) : (
        vaultIds.map((vaultId, i) => {
          return (
            <VaultTradeHistory
              key={i}
              vaultId={Number(vaultId)}
              history={vaultHistory[Number(vaultId)]}
            />
          )
        })
      )}
    </div>
  )
}

const VaultTradeHistory = ({
  vaultId,
  history
}: {
  vaultId: number
  history: SubVaultItem[]
}) => {
  const chainId = useChainId()

  return (
    <div className="p-2 rounded-2xl border-[1px] border-white">
      <div className="w-40 text-base">Gamma Vault {vaultId}</div>
      <HistoryTable
        fontSize="text-sm"
        enableScroll={false}
        blockExplorerLink={
          chainId === 42161 ? 'arbiscan.io' : 'goerli.arbiscan.io'
        }
        items={history.map(vaultHistoryItem => {
          return {
            timestamp: vaultHistoryItem.timestamp,
            symbol: vaultHistoryItem.symbolString,
            side: vaultHistoryItem.side.toString(),
            price: vaultHistoryItem.price,
            size: Math.abs(vaultHistoryItem.size).toString(),
            payoff: vaultHistoryItem.payoff,
            txHash: vaultHistoryItem.txHash
          }
        })}
      />
    </div>
  )
}

export default TradeHistory
