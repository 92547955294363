import { useQuery } from 'react-query'
import { SQUART_ONE, ZERO } from '../../../constants'
import { Q96 } from '../../../constants'
import { getAmountsForLiquidityWithTicks } from '../../../utils/uniswap'
import { usePrice } from '../../usePrice'
import { useAsset } from '../useAsset'
import { useAssetInterest } from './useAssetInterest'
import { estimateFee } from './useFeeEst'
import { useUniswapTradeFee24H } from './useUniswapTradeFee'
import { useAddresses } from '../../useAddress'
import { goerliArbitrum } from '../../../constants/addresses'

export function useFundingRate(assetId: number, isBuy: boolean) {
  const stableInterest = useAssetInterest(1)
  const underlyingInterest = useAssetInterest(assetId)

  return useQuery(
    ['fr', assetId, isBuy],

    async () => {
      if (!stableInterest.isSuccess) throw new Error('stableInterest not set')
      if (!underlyingInterest.isSuccess)
        throw new Error('underlyingInterest not set')

      if (isBuy) {
        return underlyingInterest.data.supplyInterest
          .sub(stableInterest.data.borrowInterest)
          .div(365)
      } else {
        return stableInterest.data.supplyInterest
          .sub(underlyingInterest.data.borrowInterest)
          .div(365)
      }
    },
    {
      enabled: stableInterest.isSuccess && underlyingInterest.isSuccess,
      staleTime: 1000
    }
  )
}

export function useSquartFundingRate(assetId: number, isBuy: boolean) {
  const price = usePrice(assetId)
  const assetStable = useAsset(1)
  const asset = useAsset(assetId)
  const addresses = useAddresses()
  const tradeFeeIn24h = useUniswapTradeFee24H(
    addresses?.assets[assetId].UniswapV3Pool ||
      goerliArbitrum.assets[2].UniswapV3Pool
  )

  return useQuery(
    ['squart_fr', assetId, isBuy],

    async () => {
      if (!assetStable.isSuccess) throw new Error('assetStable not set')
      if (!asset.isSuccess) throw new Error('asset not set')
      if (!price.isSuccess) throw new Error('price not set')
      if (!tradeFeeIn24h.isSuccess) throw new Error('tradeFeeIn24h not set')

      const amounts = getAmountsForLiquidityWithTicks(
        price.data.sqrtIndexPrice || ZERO,
        asset.data.sqrtAssetStatus.tickLower || 0,
        asset.data.sqrtAssetStatus.tickUpper || 0,
        SQUART_ONE.div(2)
      )

      const interestEst = estimateFee(
        asset.data,
        assetStable.data,
        price.data.price,
        price.data.sqrtPrice,
        isBuy
          ? {
              stableAmount: amounts[1].mul(-1),
              underlyingAmount: amounts[0].mul(-1),
              sqrt2Amount: SQUART_ONE.div(2)
            }
          : {
              stableAmount: amounts[1],
              underlyingAmount: amounts[0],
              sqrt2Amount: SQUART_ONE.div(2).mul(-1)
            },
        {
          stableAmount: ZERO,
          underlyingAmount: ZERO,
          sqrt2Amount: ZERO
        },
        {
          fee0: tradeFeeIn24h.data.fee0,
          fee1: tradeFeeIn24h.data.fee1
        }
      )

      return interestEst.mul(Q96).mul('1000000').div(price.data.sqrtPrice)
    },
    {
      enabled:
        assetStable.isSuccess &&
        asset.isSuccess &&
        price.isSuccess &&
        tradeFeeIn24h.isSuccess
    }
  )
}
