import { useMutation } from 'react-query'
import { useWeb3React } from '@web3-react/core'
import { ethers } from 'ethers'
import { useAddresses } from '../useAddress'
import { Controller__factory } from '../../typechain'
import { useDeadline } from '../useBlockTimestamp'
import { useCachedPrice } from '../usePrice'
import { computeLowerSqrtPrice, computeUpperSqrtPrice } from '../../utils'
import { useChainId } from '../network'

type CloseIsolatedVaultParams = {
  isolatedVaultId: number
  assetId: number
}

export function useCloseIsolatedVault(assetId: number) {
  const { account, provider } = useWeb3React<ethers.providers.Web3Provider>()
  const addresses = useAddresses()
  const deadline = useDeadline()
  const price = useCachedPrice(assetId)
  const chainId = useChainId()

  return useMutation(async (params: CloseIsolatedVaultParams) => {
    if (!account) throw new Error('Account not set')
    if (!provider) throw new Error('provider not set')
    if (!addresses) throw new Error('addresses not set')
    if (!deadline.isSuccess) throw new Error('deadline not set')
    if (!chainId) throw new Error('chainId not set')

    const contract = Controller__factory.connect(
      addresses.Controller,
      provider.getSigner()
    )

    return await contract.closeIsolatedVault(
      params.isolatedVaultId,
      params.assetId,
      {
        lowerSqrtPrice: computeLowerSqrtPrice(price.sqrtPrice, chainId),
        upperSqrtPrice: computeUpperSqrtPrice(price.sqrtPrice, chainId),
        deadline: deadline.data
      }
    )
  })
}
