import { useQuery } from '@apollo/client'
import { BigNumber } from 'ethers'
import { useMemo } from 'react'
import {
  STRATEGY_POSITION_QUERY,
  StrategyPositionEntity
} from '../../../queries/strategyPositionQuery'

export type StrategyPosition = {
  entryValue: BigNumber
  strategyAmount: BigNumber
}

export function useStrategyPosition(account: string, contractAddress: string) {
  const { data } = useQuery<StrategyPositionEntity>(STRATEGY_POSITION_QUERY, {
    fetchPolicy: 'cache-and-network',
    variables: {
      id: `${contractAddress.toLocaleLowerCase()}-${account.toLowerCase()}`
    },
    pollInterval: 30000
  })

  const strategyPosition = useMemo(() => {
    if (data && data.strategyUserPosition) {
      const position = data.strategyUserPosition

      return {
        entryValue: BigNumber.from(position.entryValue),
        strategyAmount: BigNumber.from(position.strategyAmount)
      }
    }
  }, [data])

  return strategyPosition
}
