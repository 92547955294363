import React from 'react'
import { ethers } from 'ethers'
import { useERC20BalanceQuery } from '../../hooks/query/balance'
import { useAddresses } from '../../hooks/useAddress'
import { toUnscaled } from '../../utils/bn'
import { useStrategyVaultStatus } from '../../hooks/query/strategy/useStrategyVaultStatus'
import { useStrategyHistory } from '../../hooks/query/strategy/useStrategyHistory'
import { useWeb3React } from '@web3-react/core'
import { toTimeString } from '../../utils/string'
import { usdcAmountToString } from '../../utils/number'
import { useStrategyPosition } from '../../hooks/query/strategy/useStrategyPosition'
import { ZERO } from '../../constants'

const StrategyHistory = ({
  account,
  assetId
}: {
  account: string
  assetId: number
}) => {
  const address = useAddresses()
  const strategyHistory = useStrategyHistory(
    account,
    address?.assets[assetId].GammaShortStrategy || ''
  )

  return (
    <div className="text-xs font-normal">
      <table>
        <thead className="block font-normal text-left">
          <tr>
            <th className="w-40 pb-2">Time</th>
            <th className="w-36 pb-2">Symbol</th>
            <th className="w-36 pb-2">Side</th>
            <th className="w-36 pb-2">Price</th>
            <th className="w-36 pb-2">Quantity</th>
            <th className="w-36 pb-2">Profit Realized</th>
          </tr>
        </thead>
        <tbody className="block overflow-x-hidden overflow-y-scroll h-40 text-xs">
          {strategyHistory.strategyHistory.map((item, i) => {
            return (
              <tr className="h-8" key={i}>
                <td className="w-40 py-2">{toTimeString(item.timestamp)}</td>
                <td className="w-36">Strategy</td>
                <td className="w-36">{item.action}</td>
                <td className="w-36">{toUnscaled(item.price, 6, 4)}</td>
                <td className="w-36">
                  {usdcAmountToString(item.strategyAmount)}
                </td>
                <td className="w-36">{usdcAmountToString(item.payoff)}</td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}

const StrategyPositionSummary = ({
  assetId,
  account,
  contractAddress
}: {
  assetId: number
  account: string
  contractAddress: string
}) => {
  const balance = useERC20BalanceQuery(contractAddress)
  const strategyStatus = useStrategyVaultStatus(assetId)
  const strategyPosition = useStrategyPosition(account, contractAddress)

  const currentValue =
    strategyStatus.isSuccess && !strategyStatus.data.totalSupply.eq(0)
      ? balance
          .mul(strategyStatus.data.vaultValue)
          .div(strategyStatus.data.totalSupply)
      : ZERO

  return (
    <div className="flex justify-center items-center space-x-10">
      <div className="flex flex-col space-y-2">
        <div className="text-subtext">Strategy in Wallet</div>
        <div>{toUnscaled(balance, 6)}</div>
      </div>
      <div className="flex flex-col space-y-2">
        <div className="text-subtext">Current Value</div>
        <div>${toUnscaled(currentValue, 6, 2)}</div>
      </div>
      <div className="flex flex-col space-y-2">
        <div className="text-subtext">Entry Price</div>
        <div>
          $
          {strategyPosition &&
          currentValue.gt(0) &&
          strategyPosition.strategyAmount.gt(0)
            ? toUnscaled(
                strategyPosition.entryValue
                  .mul(1000000)
                  .div(strategyPosition.strategyAmount),
                6,
                4
              )
            : 0}
        </div>
      </div>
      <div className="flex flex-col space-y-2">
        <div className="text-subtext">Profit Unrealized</div>
        <div>
          $
          {strategyPosition && currentValue.gt(0)
            ? toUnscaled(currentValue.sub(strategyPosition.entryValue), 6, 2)
            : 0}
        </div>
      </div>
    </div>
  )
}

const StrategyPosition = ({ assetId }: { assetId: number }) => {
  const { account } = useWeb3React<ethers.providers.Web3Provider>()
  const addresses = useAddresses()

  return (
    <div className="p-4 rounded-2xl bg-secondaly text-xs font-semibold">
      <div className="mb-2 px-8 flex justify-start items-center space-x-10">
        <div className="text-sm">Trade History</div>
        {account && addresses ? (
          <StrategyPositionSummary
            assetId={assetId}
            account={account}
            contractAddress={addresses.assets[assetId].GammaShortStrategy}
          />
        ) : (
          <div />
        )}
        <div></div>
      </div>
      <div className="mt-6">
        {account ? (
          <StrategyHistory account={account} assetId={assetId} />
        ) : (
          <div />
        )}
      </div>
    </div>
  )
}

export default StrategyPosition
