import React, { useState } from 'react'
import { Product } from '../../constants/enum'
import { PerpPriceInfos } from '../perps/PerpPriceInfo'
import { useLendingSummary } from '../../hooks/query/lending/useLendingSummary'
import { toSupplyAmountString } from '../../utils/number'
import { ASSET_NAMES } from '../../constants'
import InterestRateModelChart from './InterestRateModelChart'
import { useAsset } from '../../hooks/query/useAsset'
import LoadingIndicator from '../common/LoadingIndicator'

const EthAssetId = 2

const LendingCenterArea = ({ assetId }: { assetId: number }) => {
  const [selectedPerp, setSelectedPerp] = useState(Product.SQRT)
  const asset = useAsset(assetId)
  const lendingSummary = useLendingSummary(assetId)

  return (
    <div>
      <PerpPriceInfos
        assetId={EthAssetId}
        selectedPerp={selectedPerp}
        onSelect={setSelectedPerp}
      />
      <div className="mt-4 flex justify-center">
        <div className="w-[696px]">
          <h1 className="text-3xl font-bold">Lending Pool</h1>
          <p className="text-base leading-6">
            This Lending pool is designed similarly to Aave. Combining the
            borrowing side with Futures trading keeps Utilization high and makes
            the APY more attractive.
          </p>
        </div>
      </div>
      <div className="mt-4 flex justify-center items-start space-x-2">
        <div className="w-[520px] h-full mt-8">
          {asset.isSuccess ? (
            <InterestRateModelChart
              utilization={lendingSummary.utilization}
              irmParams={asset.data?.irmParams}
            />
          ) : (
            <div className="flex justify-center items-center">
              <div className="w-12 h-12">
                <LoadingIndicator />
              </div>
            </div>
          )}
        </div>
        <div className="w-[160px] h-full flex flex-col space-y-2">
          <div className="flex flex-col items-end space-y-2">
            <div className="text-sm text-white5">Current APY. est.</div>
            <div className="text-xl text-right">
              {lendingSummary.apy.toFixed(2)}%
            </div>
          </div>
          <div className="flex flex-col items-end space-y-2">
            <div className="text-sm text-white5">Current Supply</div>
            <div className="text-xl">
              {toSupplyAmountString(lendingSummary.supply)}{' '}
              {ASSET_NAMES[assetId]}
            </div>
          </div>
          <div className="flex flex-col items-end space-y-2">
            <div className="text-sm text-white5">Utilization Rate</div>
            <div className="text-xl">
              {lendingSummary.utilization.toFixed(2)}%
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default LendingCenterArea
