import { toUnscaled } from '../../../utils/bn'
import { ASSET_DECIMALS, ONE } from '../../../constants'
import { useAsset, useUtilizationRatio } from '../useAsset'
import { useAssetInterest } from '../interest/useAssetInterest'
import { useQuery } from 'react-query'
import { ERC20__factory } from '../../../typechain'
import { useWeb3React } from '@web3-react/core'
import { ethers } from 'ethers'

export function useLendingSummary(assetId: number) {
  const utilization = useUtilizationRatio(assetId)
  const assetInterest = useAssetInterest(assetId)

  if (utilization.isSuccess && assetInterest.isSuccess) {
    return {
      apy: toUnscaled(assetInterest.data.supplyInterest, 16),
      supply: toUnscaled(utilization.data.supply, ASSET_DECIMALS[assetId]),
      utilization: toUnscaled(utilization.data.utilization, 16)
    }
  }

  return {
    apy: 0,
    supply: 0,
    utilization: 0
  }
}

export function useLendingPosition(assetId: number) {
  const { provider, account } = useWeb3React<ethers.providers.Web3Provider>()
  const asset = useAsset(assetId)

  return useQuery(
    ['lending_position', account, assetId],

    async () => {
      if (!account) throw new Error('Account not set')
      if (!provider) throw new Error('provider not set')
      if (!asset.isSuccess) throw new Error('asset not loaded')

      const contract = ERC20__factory.connect(
        asset.data.supplyTokenAddress,
        provider
      )
      const balance = await contract.balanceOf(account)

      return balance.mul(asset.data.tokenStatus.assetScaler).div(ONE)
    },

    {
      enabled: !!account && !!provider && asset.isSuccess
    }
  )
}
