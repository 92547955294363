import { useQuery } from '@apollo/client'
import { BigNumber } from 'ethers'
import { useMemo } from 'react'
import {
  INTEREST_GROWTH_TXES,
  InterestGrowthTxes
} from '../../../queries/interestQuery'

export function useInterestGrowthTxs(assetId: number) {
  const { data, loading } = useQuery<InterestGrowthTxes>(INTEREST_GROWTH_TXES, {
    fetchPolicy: 'cache-first',
    variables: {
      assetId
    },
    pollInterval: 30000
  })

  const interestGrowthTxs = useMemo(() => {
    if (data) {
      return data.interestGrowthTxes
        .map(item => ({
          assetId: Number(item.assetId),
          accumulatedPremiumSupply: BigNumber.from(
            item.accumulatedPremiumSupply
          ),
          accumulatedFee0: BigNumber.from(item.accumulatedFee0),
          accumulatedFee1: BigNumber.from(item.accumulatedFee1),
          supplyPremiumGrowth: BigNumber.from(item.supplyPremiumGrowth),
          fee0Growth: BigNumber.from(item.fee0Growth),
          fee1Growth: BigNumber.from(item.fee1Growth),
          createdAt: Number(item.createdAt)
        }))
        .filter(data => data.assetId === assetId)
    }
    return null
  }, [data, assetId])

  if (loading || interestGrowthTxs === null) {
    return null
  }

  return [...interestGrowthTxs]
}
