import React from 'react'
import ethIcon from '../../../assets/assets/eth.svg'
import usdcIcon from '../../../assets/assets/usdc.svg'
import arbIcon from '../../../assets/arbitrum.svg'
import uniIcon from '../../../assets/uniswap.svg'
import { useUniswapPool } from '../../../hooks/query/uniswap/pool'
import { convertNotionalToString } from '../../../utils'
import { usePrice } from '../../../hooks/usePrice'
import { toPriceString } from '../../../utils/number'
import { useFee24h, useIV } from '../../../hooks/query/interest/useFee24h'
import { ASSET_NAMES } from '../../../constants'
import InfoTooltip from '../../common/InfoTooltip'

const RateOfChange = ({ roc = 0 }: { roc?: number }) => {
  return (
    <>
      {roc > 0 ? (
        <span className="text-green">+{roc.toFixed(2)}%</span>
      ) : (
        <span className="text-red">{roc.toFixed(2)}%</span>
      )}
    </>
  )
}

const ChartTop = ({ assetId }: { assetId: number }) => {
  const pool = useUniswapPool(assetId)
  const price = usePrice(assetId)
  const feesUSD = useFee24h(assetId)
  const iv = useIV(assetId)

  return (
    <div className="flex justify-between w-[768px]">
      <div>
        <div className="flex justify-center items-center space-x-1">
          <div className="flex justify-center items-center space-x-0">
            <img src={ethIcon} width={24} />
            <img src={usdcIcon} width={24} />
          </div>
          <span className="text-base">ETH/USDC</span>
          <div className="rounded-lg bg-white3 text-sm p-1">0.05%</div>
          <img src={arbIcon} width={24} />
        </div>
        <div className="flex justify-end items-center space-x-1 text-sm">
          <img src={uniIcon} width={24} />
          <span className="text-white5 flex justify-start">
            Uniswap Pool Info
            <InfoTooltip placement="bottom">
              <p className="text-center">
                Predy protocol utilizes Uniswap&apos;s LP position to compose
                √ETH. Gamma Trading&apos;s Premium is based on Uniswap&apos;s
                trading volume.
              </p>
            </InfoTooltip>
          </span>
        </div>
      </div>
      <div className="flex flex-col space-y-1">
        <div className="text-base text-white7">
          {ASSET_NAMES[assetId]} Current Price
        </div>
        <div>
          $
          {price.isSuccess
            ? toPriceString(price.data.price.toNumber() / 1000000)
            : 0}
        </div>
      </div>
      <div className="flex flex-col space-y-1">
        <div className="text-base text-white7">TVL</div>
        <div>
          ${pool.isSuccess ? convertNotionalToString(pool.data.tvlUSD) : '-'}
        </div>
        <RateOfChange roc={pool.data?.diffTvlUSD} />
      </div>
      <div className="flex flex-col space-y-1">
        <div className="text-base text-white7">Volume 24h</div>
        <div>
          ${pool.isSuccess ? convertNotionalToString(pool.data.volumeUSD) : '-'}
        </div>
        <RateOfChange roc={pool.data?.diffVolumeUSD} />
      </div>
      <div className="flex flex-col space-y-1">
        <div className="text-base text-white7 flex justify-start">
          Fee 24h
          <InfoTooltip placement="bottom">
            <p className="text-center">
              This is the commission income earned by Predy&apos;s LP position
              in Uniswap v3 in 24 hours. Liquidity range and other information
              can be found in Stats and Dune.
            </p>
          </InfoTooltip>
        </div>
        <div>${pool.isSuccess ? convertNotionalToString(feesUSD) : '...'}</div>
      </div>
      <div className="flex flex-col space-y-1">
        <div className="text-base text-white7 flex justify-start">
          Implied Volatility
          <InfoTooltip placement="bottom">
            <p className="text-center">
              Implied volatility = 2*Sqrt( Fee24H/TickLiquidity )<br />
              Fee24H is shown on the left. TickLiquidity is the value of total
              Squart positions.
            </p>
          </InfoTooltip>
        </div>
        <div>{pool.isSuccess ? (iv * 100).toFixed(2) : '...'}%</div>
      </div>
    </div>
  )
}

export default ChartTop
