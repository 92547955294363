import React, { useEffect, useMemo, useState } from 'react'
import { Router, Route, Redirect } from 'wouter'
import { useEagerConnect } from './hooks/wallet'
import AssetView from './pages/AssetView'
import VaultView from './pages/VaultView'
import LendingView from './pages/LendingView'
import TradeFutureView from './pages/TradeFutureView'
import TradeGammaView from './pages/TradeGammaView'
import VisualizedTradeView from './pages/VisualizedTradeView'
import TradeStrategyView from './pages/TradeStrategyView'
import FaucetView from './pages/FaucetView'
import LoadingIndicator from './components/common/LoadingIndicator'
import NetworkErrorLabel from './components/common/NetworkErrorLabel'
import Header from './components/header/Header'
import { ApolloProvider } from '@apollo/client'
import { PredyClient } from './utils/apollo-client'
import { DEFAULT_CHAIN } from './constants'
import { useChainId, useIsSupportedChain } from './hooks/network'
import UnsupportedNetwork from './components/UnsupportedNetwork'

const App = () => {
  const [loaded, setLoaded] = useState(false)

  const chainId = useChainId()
  const supported = useIsSupportedChain()

  const client = useMemo(() => PredyClient[chainId || DEFAULT_CHAIN], [chainId])

  useEffect(() => {
    ; (async () => {
      // check residence once. if value is set to true, proceed
      setLoaded(true)
    })()
    // eslint-disable-next-line
  }, [])

  const tried = useEagerConnect()
  if (!tried || !loaded) return <LoadingIndicator />

  return (
    <ApolloProvider client={client || PredyClient[DEFAULT_CHAIN]}>
      <div>
        <NetworkErrorLabel />
        <Router>
          <Header />
          {supported ? (
            <div className="mx-auto mt-24">
              <Route path="/">
                <Redirect to="/trade" />
              </Route>
              <Route path="/assets" component={AssetView} />
              <Route path="/vaults/:vaultId" component={VaultView} />
              <Route path="/lending" component={LendingView} />
              <Route path="/futures" component={TradeFutureView} />
              <Route path="/isolated" component={TradeGammaView} />
              <Route path="/trade" component={VisualizedTradeView} />
              <Route path="/strategy" component={TradeStrategyView} />
              <Route path="/faucet" component={FaucetView} />
            </div>
          ) : (<UnsupportedNetwork />)}
        </Router>
      </div>
    </ApolloProvider >
  )
}

export default App
