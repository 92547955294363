import { BigNumber } from 'ethers'
import React, { useState } from 'react'
import { ASSET_NAMES, MARGIN, ZERO } from '../../constants'
import { Product } from '../../constants/enum'
import {
  useFundingRate,
  useSquartFundingRate
} from '../../hooks/query/interest/useFundingRate'
import { usePrice, useCachedPrice } from '../../hooks/usePrice'
import { toUnscaled } from '../../utils/bn'
import { toPriceString } from '../../utils/number'
import { Q96 } from '../../constants'
import PriceChart from '../common/PriceChart'
import { usePriceHistory } from '../../hooks/query/usePriceHistory'
import { useAddresses } from '../../hooks/useAddress'
import { computePrice } from '../../utils/helpers/minDeposit'
import { toUnscaledSqrtPrice } from '../../utils/price'
import { AggregationInterval } from '../../queries/aggregatedPriceQuery'
import { getNow } from '../../utils/time'

type Prop = {
  title: string
  price: BigNumber
  frBuy: BigNumber
  frSell: BigNumber
  selectedProduct: Product
  product: Product
  onSelected: (product: Product) => void
}

const PerpPriceInfo = ({
  title,
  price,
  frBuy,
  frSell,
  selectedProduct,
  product,
  onSelected
}: Prop) => {
  return (
    <div
      onClick={() => onSelected(product)}
      className={`w-full rounded-full bg-white1 flex justify-start p-2 divide-x divide-white5 ${
        selectedProduct === product
          ? 'border-[1px] border-white'
          : 'cursor-pointer'
      }`}
    >
      <div className="px-2">
        <div className="text-sm text-white5">{title}</div>
        <div className="mt-1">
          ${toPriceString(toUnscaled(price, MARGIN.DECIMALS))}
        </div>
      </div>
      <div className="pl-4 pr-2">
        <div className="text-sm text-white5">Daily Fee est.</div>
        <div className="text-sm">
          <span className="text-green">Buy</span> {frBuy.gt(0) ? 'Get' : 'Pay'}{' '}
          {toUnscaled(frBuy.abs(), 16, 4)}%/{' '}
          <span className="text-red">Sell</span> {frSell.gt(0) ? 'Get' : 'Pay'}{' '}
          {toUnscaled(frSell.abs(), 16, 4)}%
        </div>
      </div>
    </div>
  )
}

export const PerpPriceInfos = ({
  assetId,
  selectedPerp,
  onSelect
}: {
  assetId: number
  selectedPerp: Product
  onSelect: (product: Product) => void
}) => {
  const frBuy = useFundingRate(assetId, true)
  const frSell = useFundingRate(assetId, false)
  const squartFrBuy = useSquartFundingRate(assetId, true)
  const squartFrSell = useSquartFundingRate(assetId, false)

  const price = useCachedPrice(assetId)

  return (
    <div className="w-full px-2 flex justify-between items-center gap-2">
      <PerpPriceInfo
        title={`√${ASSET_NAMES[assetId]} Index Price`}
        price={price.sqrtPrice.mul(1000000000000).div(Q96)}
        frBuy={squartFrBuy.isSuccess ? squartFrBuy.data : ZERO}
        frSell={squartFrSell.isSuccess ? squartFrSell.data : ZERO}
        selectedProduct={selectedPerp}
        product={Product.SQRT}
        onSelected={onSelect}
      />
      <PerpPriceInfo
        title={`${ASSET_NAMES[assetId]} Index Price`}
        price={price.price}
        frBuy={frBuy.isSuccess ? frBuy.data : ZERO}
        frSell={frSell.isSuccess ? frSell.data : ZERO}
        selectedProduct={selectedPerp}
        product={Product.PERP}
        onSelected={onSelect}
      />
    </div>
  )
}

const PerpInfo = ({ assetId }: { assetId: number }) => {
  const [selectedPerp, setSelectedPerp] = useState(Product.SQRT)
  const price = usePrice(assetId)

  const addresses = useAddresses()
  const priceData = usePriceHistory(
    addresses ? addresses.assets[assetId].UniswapV3Pool : '',
    AggregationInterval.Hourly
  )

  const squartPrice = priceData
    ? priceData
        .map(d => [d.openTimestamp * 1000, toUnscaledSqrtPrice(d.openPrice)])
        .concat(
          price.isSuccess
            ? [[getNow() * 1000, toUnscaledSqrtPrice(price.data.sqrtPrice)]]
            : []
        )
    : []

  const perpPrice = priceData
    ? priceData
        .map(d => [
          d.openTimestamp * 1000,
          toUnscaled(computePrice(d.openPrice).mul('1000000').div(Q96), 6)
        ])
        .concat(
          price.isSuccess
            ? [[getNow() * 1000, toUnscaled(price.data.price, 6)]]
            : []
        )
    : []

  return (
    <div>
      <PerpPriceInfos
        assetId={assetId}
        selectedPerp={selectedPerp}
        onSelect={setSelectedPerp}
      />
      <div className="mt-4">
        <div className="flex justify-center">
          <div className="w-[668px]">
            {selectedPerp === Product.SQRT ? (
              <>
                <h1 className="text-3xl font-bold">Squart Perpetual Futures</h1>
                <p className="text-lg leading-6">
                  The Squart is a Perpetual Future contract indexed to √
                  {ASSET_NAMES[assetId]}.
                </p>
              </>
            ) : (
              <>
                <h1 className="text-3xl font-bold">
                  {ASSET_NAMES[assetId]} Perpetual Futures
                </h1>
                <p className="text-lg leading-6">
                  A Perpetual Future contract indexed to {ASSET_NAMES[assetId]}.
                </p>
              </>
            )}
          </div>
        </div>
        <div className="mt-8 flex justify-center items-end">
          <div className="w-[680px] xl:w-[696px] h-full">
            <PriceChart
              height={'296px'}
              data1={perpPrice}
              data2={squartPrice}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default PerpInfo
