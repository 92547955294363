import { useQuery } from '@apollo/client'
import { BigNumber } from 'ethers'
import { useMemo } from 'react'
import {
  RebalanceHistoryItems,
  REBALANCE_HISTORY_ITEM_QUERY
} from '../../queries/rebalanceHistoryItemQuery'

export function useRebalanceHistory(address: string, assetId: number) {
  const { data, loading } = useQuery<RebalanceHistoryItems>(
    REBALANCE_HISTORY_ITEM_QUERY,
    {
      fetchPolicy: 'cache-first',
      variables: {
        assetId: address.toLowerCase() + '-' + assetId.toString()
      },
      pollInterval: 20000
    }
  )

  const priceHistory = useMemo(() => {
    if (data) {
      return data.rebalanceHistoryItems.map(item => ({
        assetId: Number(item.asset.assetId),
        tickLower: Number(item.tickLower),
        tickUpper: Number(item.tickUpper),
        profit: BigNumber.from(item.profit),
        createdAt: Number(item.createdAt)
      }))
    }

    return null
  }, [data])

  if (loading || priceHistory === null) {
    return null
  }

  return [...priceHistory].reverse()
}
