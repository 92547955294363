import React, { useEffect, useState } from 'react'
import { roundDigit } from '../../../utils/number'

type Props = {
  unit?: string
  amount: number
  onChange: (amount: number) => void
}

const SizeForm = ({ unit, amount, onChange }: Props) => {
  const [value, setValue] = useState(amount.toString())

  useEffect(() => {
    setValue(amount.toString())
  }, [amount])

  const handler = (value: string) => {
    setValue(value)

    const numValue = Number(value)

    if (numValue === 0 || (numValue && numValue >= 0)) {
      onChange(roundDigit(numValue, 2))
    }
  }

  return (
    <div className="w-full relative inline-block h-[40px] text-2xl font-normal">
      <div className="absolute left-4 top-[7px] flex justify-center">
        <button
          className="w-7 h-7 rounded-lg border-[1px] border-white bg-primary text-base"
          onClick={() => {
            if (Number(value) > 0) {
              handler(String(Number(value) - 1))
            }
          }}
        >
          ↓
        </button>
        <button
          className="w-7 h-7 rounded-lg border-[1px] border-white bg-primary text-base"
          onClick={() => {
            handler(String(Number(value) + 1))
          }}
        >
          ↑
        </button>
      </div>
      <input
        autoComplete={'off'}
        type={'number'}
        className={`w-full rounded bg-black0 text-right h-full ${
          unit ? 'pr-16' : 'pr-4'
        }`}
        value={value}
        step={'1'}
        onChange={e => handler(e.target.value)}
        onBlur={e => handler(e.target.value)}
      />
      {unit ? (
        <span className="absolute right-4 top-[10px] text-subtext">{unit}</span>
      ) : (
        <div />
      )}
    </div>
  )
}

export default SizeForm
