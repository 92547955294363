import { useQuery } from 'react-query'
import { useAddresses } from '../../useAddress'
import { useDeltaTimestamps } from '../../../utils/time'
import { useBlocksFromTimestamps } from './useBlockFromTimestamp'
import { BigNumber } from 'ethers'
import { Q96, ZERO } from '../../../constants'
import { usePrice } from '../../usePrice'
import { toUnscaled } from '../../../utils/bn'

const UNISWAP_SUBGRAPH_ENDPOINT =
  'https://api.thegraph.com/subgraphs/name/ianlapham/arbitrum-minimal'

async function queryPoolDayData(poolAddress: string, block?: number) {
  const body = {
    operationName: 'pools',
    query: `query pools($address: Bytes!) {
        pools(
          where: {id: $address}
          ${block ? `block: {number: ${block}} ,` : ''}
          subgraphError: allow
        ) {
          feeTier
          feesUSD
          volumeUSD
          liquidity
          sqrtPrice
          totalValueLockedUSD
          volumeToken0
          volumeToken1
          totalValueLockedToken0
          totalValueLockedToken1
          token0 {
            derivedETH
          }
          token1 {
            derivedETH
          }
          totalValueLockedUSD
      }
    }`,
    variables: { address: poolAddress.toLowerCase() }
  }

  const res = await fetch(UNISWAP_SUBGRAPH_ENDPOINT, {
    method: 'POST',
    body: JSON.stringify(body)
  })

  const resBody = await res.json()

  if (resBody.data.pools.length === 0) {
    return {
      feeTier: 0,
      tvlUSD: 0,
      volumeUSD: 0,
      feesUSD: 0,
      liquidity: ZERO,
      sqrtPrice: ZERO,
      totalValueLockedUSD: 0,
      volumeToken0: 0,
      volumeToken1: 0,
      totalValueLockedToken0: 0,
      totalValueLockedToken1: 0,
      token0: {
        derivedETH: 0
      },
      token1: {
        derivedETH: 0
      }
    }
  }

  const poolDayData = resBody.data.pools[0]

  return {
    feeTier: Number(poolDayData.feeTier),
    tvlUSD: Number(poolDayData.totalValueLockedUSD),
    volumeUSD: Number(poolDayData.volumeUSD),
    feesUSD: Number(poolDayData.feesUSD),
    liquidity: BigNumber.from(poolDayData.liquidity),
    sqrtPrice: BigNumber.from(poolDayData.sqrtPrice),
    totalValueLockedUSD: Number(poolDayData.totalValueLockedUSD),
    volumeToken0: Number(poolDayData.volumeToken0),
    volumeToken1: Number(poolDayData.volumeToken1),
    totalValueLockedToken0: Number(poolDayData.totalValueLockedToken0),
    totalValueLockedToken1: Number(poolDayData.totalValueLockedToken1),
    token0: {
      derivedETH: Number(poolDayData.token0.derivedETH)
    },
    token1: {
      derivedETH: Number(poolDayData.token1.derivedETH)
    }
  }
}

export function useUniswapPool(assetId: number) {
  const addresses = useAddresses()
  const deltaTimestamps = useDeltaTimestamps()
  const blocks = useBlocksFromTimestamps(deltaTimestamps)
  const price = usePrice(assetId)

  return useQuery(
    ['uniswap_pool', assetId],
    async () => {
      if (!addresses) throw new Error('address not loaded')
      if (!blocks.isSuccess) throw new Error('blocks not loaded')
      if (!price.isSuccess) throw new Error('price not loaded')

      const poolAddress = addresses.assets[assetId].UniswapV3Pool

      const poolDayData = await queryPoolDayData(poolAddress)
      const poolDayData24 = await queryPoolDayData(
        poolAddress,
        blocks.data[0].number
      )
      const poolDayData48 = await queryPoolDayData(
        poolAddress,
        blocks.data[1].number
      )

      const yesterdayTvlUSD = poolDayData24.tvlUSD
      const tickLiquidityUSD =
        poolDayData.liquidity
          .mul(poolDayData.sqrtPrice)
          .mul(2)
          .div(Q96)
          .toNumber() / 1000000

      const volumeUSD = poolDayData.volumeUSD - poolDayData24.volumeUSD
      const yesterdayVolumeUSD =
        poolDayData24.volumeUSD - poolDayData48.volumeUSD

      const feesUSD = poolDayData.feesUSD - poolDayData24.feesUSD

      const tvlAdjust0 =
        (poolDayData.volumeToken0 * poolDayData.feeTier) / 1000000 / 2
      const tvlAdjust1 =
        (poolDayData.volumeToken1 * poolDayData.feeTier) / 1000000 / 2

      const tvlToken0 = poolDayData.totalValueLockedToken0 - tvlAdjust0
      const tvlToken1 = poolDayData.totalValueLockedToken1 - tvlAdjust1

      const ethPrice = toUnscaled(price.data.price, 6)

      const tvlUSD =
        (tvlToken0 * poolDayData.token0.derivedETH +
          tvlToken1 * poolDayData.token1.derivedETH) *
        ethPrice

      const diffTvlUSD =
        (poolDayData.tvlUSD - yesterdayTvlUSD) / yesterdayTvlUSD
      const diffVolumeUSD =
        (volumeUSD - yesterdayVolumeUSD) / yesterdayVolumeUSD

      const iv = 2 * Math.sqrt((feesUSD * 365) / tickLiquidityUSD)

      return {
        tvlUSD,
        volumeUSD,
        feesUSD,
        iv,
        diffTvlUSD,
        diffVolumeUSD
      }
    },
    {
      enabled: !!addresses && blocks.isSuccess && price.isSuccess
    }
  )
}
