import { useQuery } from '@apollo/client'
import { BigNumber } from 'ethers'
import { useMemo } from 'react'
import {
  DAILY_ASSET_GROWTH_QUERY,
  InterestDailyEntities
} from '../../../queries/AssetScalerDailyQuery'

export function useAssetGrowthHistory(assetId: number) {
  const { data, loading } = useQuery<InterestDailyEntities>(
    DAILY_ASSET_GROWTH_QUERY,
    {
      fetchPolicy: 'cache-first',
      variables: {
        assetId
      },
      pollInterval: 20000
    }
  )

  const scalerHistory = useMemo(() => {
    if (data) {
      return data.interestDailies
        .map(item => ({
          assetId: Number(item.assetId),
          assetGrowth: BigNumber.from(item.assetGrowth),
          debtGrowth: BigNumber.from(item.debtGrowth),
          createdAt: Number(item.createdAt),
          updatedAt: Number(item.updatedAt)
        }))
        .filter(data => data.assetId === assetId)
    }
    return null
  }, [data, assetId])

  if (loading || scalerHistory === null) {
    return null
  }

  return [...scalerHistory].reverse()
}
