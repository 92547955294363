import React from 'react'
import ChartTop from './ChartTop'
import PnLChartWrapper from './PnLChartWrapper'

const CenterArea = ({
  vaultId,
  assetId
}: {
  vaultId: number
  assetId: number
}) => {
  return (
    <div>
      <ChartTop assetId={assetId} />
      <PnLChartWrapper assetId={assetId} vaultId={vaultId} />
    </div>
  )
}

export default CenterArea
