import React from 'react'
import { BigNumber } from 'ethers'
import { ASSET_NAMES } from '../../constants'
import { toUnscaled } from '../../utils/bn'
import { usdcAmountToString } from '../../utils/number'
import { toTimeString } from '../../utils/string'

export const PositionTable = ({
  children,
  fontSize,
  isHistory
}: {
  children?: React.ReactNode
  fontSize?: string
  isHistory: boolean
}) => {
  return (
    <table>
      <thead
        className={`block font-normal text-left text-sm md:${
          fontSize || 'text-base'
        }`}
      >
        <tr className="h-8">
          <th className="w-40 pb-2">Time</th>
          <th className="w-36 pb-2">Symbol</th>
          <th className="w-36 pb-2">Side</th>
          <th className="w-36 pb-2">Price</th>
          <th className="w-36 pb-2">Quantity</th>
          <th className="w-36 pb-2">
            <span className="hidden md:block">
              {isHistory ? 'Profit Realized' : 'Profit Unrealized'}
            </span>
            <span className="block md:hidden">PnL</span>
          </th>
        </tr>
      </thead>
      {children}
    </table>
  )
}

type OpenPositionTableProps = {
  assetId: number
  tradeAmount: BigNumber
  sqrtTradeAmount: BigNumber
  entryPrice: BigNumber
  squartEntryPrice: BigNumber
  perpPayoff?: BigNumber
  sqrtPayoff?: BigNumber
  unrealizedFee?: BigNumber
  perpUpdatedAt: number
  squartUpdatedAt: number
  fontSize?: string
}

export const OpenPositionTable = ({
  assetId,
  tradeAmount,
  sqrtTradeAmount,
  entryPrice,
  squartEntryPrice,
  perpPayoff,
  sqrtPayoff,
  unrealizedFee,
  perpUpdatedAt,
  squartUpdatedAt,
  fontSize
}: OpenPositionTableProps) => {
  return (
    <PositionTable fontSize={fontSize} isHistory={false}>
      <tbody className="block text-xs">
        <tr className="h-8">
          <td className="w-40">{toTimeString(perpUpdatedAt)}</td>
          <td className="w-36">{ASSET_NAMES[assetId]}</td>
          <td className="w-36">{tradeAmount.gt(0) ? 'Buy' : 'Sell'}</td>
          <td className="w-36">
            {tradeAmount.eq(0) ? '-' : usdcAmountToString(entryPrice)}
          </td>
          <td className="w-36">{toUnscaled(tradeAmount.abs(), 18)}</td>
          <td className="w-36">{usdcAmountToString(perpPayoff)}</td>
        </tr>
        <tr className="h-8">
          <td>{toTimeString(squartUpdatedAt)}</td>
          <td>√{ASSET_NAMES[assetId]}</td>
          <td>{sqrtTradeAmount.gt(0) ? 'Buy' : 'Sell'}</td>
          <td>
            {sqrtTradeAmount.eq(0) ? '-' : usdcAmountToString(squartEntryPrice)}
          </td>
          <td>{toUnscaled(sqrtTradeAmount.mul(2).abs(), 12)}</td>
          <td>{usdcAmountToString(sqrtPayoff)}</td>
        </tr>
        <tr className="h-8">
          <td>
            {perpUpdatedAt > squartUpdatedAt
              ? toTimeString(perpUpdatedAt)
              : toTimeString(squartUpdatedAt)}
          </td>
          <td>Fee</td>
          <td>-</td>
          <td>-</td>
          <td>-</td>
          <td>{usdcAmountToString(unrealizedFee)}</td>
        </tr>
      </tbody>
    </PositionTable>
  )
}

type HistoryTableProps = {
  blockExplorerLink: string
  enableScroll: boolean
  fontSize: string
  items: {
    symbol: string
    side: string
    size: string
    price: BigNumber
    payoff: BigNumber
    timestamp: number
    txHash: string
  }[]
}

export const HistoryTable = ({
  blockExplorerLink,
  enableScroll,
  fontSize,
  items
}: HistoryTableProps) => {
  return (
    <PositionTable fontSize={fontSize} isHistory={true}>
      <tbody
        className={`block ${
          enableScroll ? 'overflow-x-hidden overflow-y-scroll h-40' : ''
        } text-xs`}
      >
        {items.map((item, i) => {
          return (
            <tr key={i} className="h-8">
              <td className="w-40">
                <a
                  href={`https://${blockExplorerLink}/tx/${item.txHash}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  {toTimeString(item.timestamp)}
                </a>
              </td>
              <td className="w-36">{item.symbol}</td>
              <td className="w-36">{item.side}</td>
              <td className="w-36">{toUnscaled(item.price, 6, 2)}</td>
              <td className="w-36">{item.size}</td>
              <td className="w-36">{toUnscaled(item.payoff, 6)}</td>
            </tr>
          )
        })}
      </tbody>
    </PositionTable>
  )
}
