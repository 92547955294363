import React from 'react'
import CenterArea from '../components/futures2/center/CenterArea'
import MyPosition from '../components/perps/positions/MyPosition'
import TradeForm from '../components/futures2/trade/TradeForm'
import VaultSummary from '../components/positions/VaultSummary'
import useAssetType from '../store/assetType'
import { useVaultSummary } from '../hooks/query/useVaultStatus'

const VisualizedTradeView = () => {
  const { selectedAsset } = useAssetType()
  const vaultSummary = useVaultSummary()

  return (
    <div className="grid grid-cols-trade-sm sm:grid-cols-trade grid-rows-trade-sm sm:grid-rows-trade gap-2">
      <div className="col-span-1 row-span-2 mx-1 md:ml-2">
        <TradeForm
          vaultId={vaultSummary.data?.mainVaultId || 0}
          assetId={selectedAsset}
        />
      </div>
      <div className="hidden sm:block col-span-1 row-span-1">
        <CenterArea
          assetId={selectedAsset}
          vaultId={vaultSummary.data?.mainVaultId || 0}
        />
      </div>
      <div className="col-span-1 row-span-1">
        <div className="md:mx-4">
          <div className="md:mb-4">
            <VaultSummary vaultSummary={vaultSummary.data} />
          </div>
          <MyPosition
            vaultId={vaultSummary.data?.mainVaultId || 0}
            assetId={selectedAsset}
            vaultSummary={vaultSummary.data}
          />
        </div>
      </div>
    </div>
  )
}

export default VisualizedTradeView
