import React from 'react'
import { ethers } from 'ethers'
import { toUnscaled } from '../../utils/bn'
import { useWeb3React } from '@web3-react/core'
import { toTimeString } from '../../utils/string'
import { useLendingHistory } from '../../hooks/query/lending/useLendingHistory'
import { ASSET_DECIMALS, ASSET_NAMES } from '../../constants'
import { useLendingPosition } from '../../hooks/query/lending/useLendingSummary'
import { Addresses } from '../../constants/addresses'

const LendingHistory = ({
  account,
  assetId,
  addresses
}: {
  account: string
  assetId: number
  addresses: Addresses
}) => {
  const lendingHistory = useLendingHistory(
    account,
    addresses.Controller,
    assetId
  )

  return (
    <div className="text-xs font-normal">
      <table>
        <thead className="block font-normal text-left">
          <tr>
            <th className="w-40 pb-2">Time</th>
            <th className="w-36 pb-2">Symbol</th>
            <th className="w-36 pb-2">Side</th>
            <th className="w-36 pb-2">Quantity</th>
          </tr>
        </thead>
        <tbody className="block overflow-x-hidden overflow-y-scroll h-40 text-xs">
          {lendingHistory.lendingHistory.map((item, i) => {
            return (
              <tr className="h-8" key={i}>
                <td className="w-40 py-2">{toTimeString(item.timestamp)}</td>
                <td className="w-36">Lending</td>
                <td className="w-36">{item.action}</td>
                <td className="w-36">{item.assetAmount.toFixed(2)}</td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}

const LendingPosition = ({
  assetId,
  addresses
}: {
  assetId: number
  addresses: Addresses | undefined
}) => {
  const { account } = useWeb3React<ethers.providers.Web3Provider>()
  const lendingPosition = useLendingPosition(assetId)

  return (
    <div className="p-4 rounded-2xl bg-secondaly text-xs font-semibold">
      <div className="mb-2 px-8 flex justify-start items-center space-x-10">
        <div className="text-sm">Trade History</div>
        <div className="flex justify-center items-center space-x-10">
          <div className="flex flex-col space-y-2">
            <div className="text-subtext">Supplying {ASSET_NAMES[assetId]}</div>
            <div>
              {lendingPosition.isSuccess
                ? toUnscaled(lendingPosition.data, ASSET_DECIMALS[assetId])
                : 0}{' '}
              {ASSET_NAMES[assetId]}
            </div>
          </div>
        </div>
        <div></div>
      </div>
      <div className="mt-2">
        {account && addresses ? (
          <LendingHistory
            account={account}
            assetId={assetId}
            addresses={addresses}
          />
        ) : (
          <div />
        )}
      </div>
    </div>
  )
}

export default LendingPosition
