import React from 'react'
import { ZERO } from '../../../constants'
import { ExtraOpenPosition } from '../../../hooks/query/useExtraVaultStatus'
import { useTradeQuoter } from '../../../hooks/query/useQuoter'
import { PositionStatusResult } from '../../../hooks/query/useVaultStatus'
import { getNow } from '../../../utils/time'
import { OpenPositionTable } from '../../positions/PositionTable'

const OpenPositions = ({
  vaultId,
  assetId,
  openPosition,
  extraPositionStatus
}: {
  vaultId: number
  assetId: number
  openPosition?: PositionStatusResult
  extraPositionStatus?: ExtraOpenPosition
}) => {
  const quote = useTradeQuoter({
    vaultId,
    assetId,
    tradeAmount: openPosition ? openPosition.perpStatus.amount.mul(-1) : ZERO,
    tradeAmountSqrt: openPosition
      ? openPosition.sqrtStatus.amount.mul(-1)
      : ZERO
  })

  return (
    <div>
      <div>
        {openPosition ? (
          <OpenPositionTable
            assetId={assetId}
            tradeAmount={openPosition.perpStatus.amount}
            sqrtTradeAmount={openPosition.sqrtStatus.amount}
            entryPrice={openPosition.perpStatus.entryPrice}
            squartEntryPrice={openPosition.sqrtStatus.entryPrice}
            perpPayoff={quote.data?.data?.perpPayoff}
            sqrtPayoff={quote.data?.data?.sqrtPayoff}
            unrealizedFee={openPosition.fee}
            perpUpdatedAt={extraPositionStatus?.perpUpdatedAt || getNow()}
            squartUpdatedAt={extraPositionStatus?.squartUpdatedAt || getNow()}
          />
        ) : (
          <div />
        )}
      </div>
    </div>
  )
}

export default OpenPositions
