import React from 'react'
import MyPosition from '../components/gamma/positions/MyPosition'
import GammaTradeForm from '../components/gamma/GammaTradeForm'
import VaultSummary from '../components/positions/VaultSummary'
import useAssetType from '../store/assetType'
import GammaCenterArea from '../components/gamma/GammaCenterArea'
import { useGammaTradeResult } from '../hooks/views/gamma'
import useGammaTrade from '../store/trade'
import { toScaled } from '../utils/bn'
import { useVaultSummary } from '../hooks/query/useVaultStatus'
import { ZERO } from '../constants'

const TradeGammaView = () => {
  const { selectedAsset } = useAssetType()
  const vaultSummary = useVaultSummary()

  const { squartSide, squartAmount, marginAmount } = useGammaTrade()

  const gammaTradeResult = useGammaTradeResult(
    Number(selectedAsset),
    toScaled(marginAmount, 6),
    toScaled(squartAmount, 12)
      .div(2)
      .mul(squartSide ? -1 : 1)
  )

  return (
    <div className="grid grid-cols-trade-sm sm:grid-cols-trade grid-rows-trade-sm sm:grid-rows-trade gap-2">
      <div className="col-span-1 row-span-2 mx-1 md:ml-2">
        <GammaTradeForm
          assetId={selectedAsset}
          gammaTradeResult={gammaTradeResult}
          available={vaultSummary.data?.marginWithdrawal || ZERO}
        />
      </div>
      <div className="hidden sm:block col-span-1 row-span-1">
        <GammaCenterArea
          assetId={selectedAsset}
          gammaTradeResult={gammaTradeResult}
        />
      </div>
      <div className="col-span-1 row-span-1">
        <div className="xl:mx-5">
          <div className="xl:mb-4">
            <VaultSummary vaultSummary={vaultSummary.data} />
          </div>
          <MyPosition assetId={selectedAsset} />
        </div>
      </div>
    </div>
  )
}

export default TradeGammaView
