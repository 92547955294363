import React from 'react'
import AssetSummary from '../components/asset/AssetSummary'

const AssetView = () => {
  return (
    <>
      <div className="max-w-7xl mx-auto">
        <AssetSummary />
      </div>
    </>
  )
}

export default AssetView
