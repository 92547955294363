import { BigNumber, ethers } from 'ethers'
import { useWeb3React } from '@web3-react/core'
import { useQuery } from 'react-query'
import { useChainId, useIsSupportedChain } from '../network'
import { useAddresses } from '../useAddress'
import { Controller__factory } from '../../typechain'
import { SQUART_ONE, STALE_TIME, UNDERLYING_ONE, ZERO } from '../../constants'
import { calculateLossThreshold } from '../../utils/helpers/minDeposit'

interface PerpStatus {
  amount: BigNumber
  entryValue: BigNumber
  entryPrice: BigNumber
}

export interface PositionStatusResult {
  assetId: number
  sqrtStatus: PerpStatus
  perpStatus: PerpStatus
  stableAmount: BigNumber
  underlyingAmount: BigNumber
  delta: BigNumber
  fee: BigNumber
  lossThreshold: BigNumber
}

export interface VaultStatusResult {
  id: number
  isMainVault: boolean
  vaultValue: BigNumber
  margin: BigNumber
  available: BigNumber
  withdrawable: BigNumber
  minDeposit: BigNumber
  positionValue: BigNumber
  openPositionStatusResults: PositionStatusResult[]
}

export interface VaultStatusResults {
  mainVault: VaultStatusResult | null
  isolatedVaults: VaultStatusResult[]
}

export function useVaultStatus() {
  const { provider, account } = useWeb3React<ethers.providers.Web3Provider>()
  const supportedChain = useIsSupportedChain()
  const chainId = useChainId()
  const addresses = useAddresses()

  const vaultStatusQuery = useQuery<VaultStatusResults>(
    ['vault_status', chainId, account],

    async () => {
      if (!account) throw new Error('Account not set')
      if (!provider) throw new Error('provider not set')
      if (!addresses) throw new Error('addresses not set')

      const controller = Controller__factory.connect(
        addresses.Controller,
        provider
      )

      const result = await controller.callStatic.getVaultStatusWithAddress({
        from: account
      })

      const decodeVaultStatus: (vaultStatus: any) => VaultStatusResult = (
        vaultStatus: any
      ) => {
        const margin = vaultStatus[3]
        const available = vaultStatus[2].sub(vaultStatus[5])
        const withdrawable = available.lt(margin) ? available : margin

        return {
          id: vaultStatus[0].toNumber(),
          isMainVault: vaultStatus[1],
          vaultValue: vaultStatus[2],
          margin,
          available: vaultStatus[2].sub(vaultStatus[5]),
          withdrawable,
          minDeposit: vaultStatus[5],
          positionValue: vaultStatus[4],
          openPositionStatusResults: vaultStatus[6].map(
            (openPositionStatus: any) => {
              const perpAmount = openPositionStatus[1][0][0]
              const perpEntryValue = openPositionStatus[1][0][1]
              const perpEntryPrice = perpAmount.eq(0)
                ? ZERO
                : perpEntryValue.mul(UNDERLYING_ONE).div(perpAmount).abs()
              const sqrtAmount = openPositionStatus[1][1][0]
              const sqrtEntryValue = openPositionStatus[1][1][1]
              const sqrtEntryPrice = sqrtAmount.eq(0)
                ? ZERO
                : sqrtEntryValue.mul(SQUART_ONE).div(sqrtAmount).div(2).abs()

              const stableAmount = openPositionStatus[1][2][0]
              const underlyingAmount = openPositionStatus[1][3][0]

              return {
                assetId: openPositionStatus[0].toNumber(),
                perpStatus: {
                  amount: perpAmount,
                  entryValue: perpEntryValue,
                  entryPrice: perpEntryPrice
                },
                sqrtStatus: {
                  amount: sqrtAmount,
                  entryValue: sqrtEntryValue,
                  entryPrice: sqrtEntryPrice
                },
                stableAmount,
                underlyingAmount,
                delta: openPositionStatus[2],
                fee: openPositionStatus[3],
                lossThreshold: calculateLossThreshold(
                  {
                    stable: perpEntryValue.add(sqrtEntryValue),
                    underlying: perpAmount,
                    squart: sqrtAmount
                  },
                  margin
                )
              }
            }
          )
        }
      }

      const vaultStatusResult = result[1].map(decodeVaultStatus)

      return {
        mainVault: decodeVaultStatus(result[0]),
        isolatedVaults: vaultStatusResult.filter(vault => !vault.isMainVault)
      }
    },

    {
      enabled: !!account && supportedChain && !!provider && !!addresses,
      staleTime: STALE_TIME,
      refetchInterval: 15000
    }
  )

  return vaultStatusQuery
}

export type VaultSummary = {
  mainVaultId: number
  totalValue: BigNumber
  totalMargin: BigNumber
  portfolioVaultValue: BigNumber
  minValue: BigNumber
  marginAvailable: BigNumber
  marginWithdrawal: BigNumber
  isolatedVaultValue: BigNumber
  isolatedTotalDelta: BigNumber
}

export function useVaultSummary() {
  const { account } = useWeb3React<ethers.providers.Web3Provider>()
  const chainId = useChainId()
  const vaultsStatus = useVaultStatus()

  return useQuery<VaultSummary>(
    ['vault_summary', chainId, account],

    async () => {
      if (!vaultsStatus.isSuccess) throw new Error('vaultsStatus not set')

      const isolatedVaultValue = vaultsStatus.data.isolatedVaults.reduce(
        (total, isolatedVault) => total.add(isolatedVault.vaultValue),
        ZERO
      )

      const isolatedVaultMargin = vaultsStatus.data.isolatedVaults.reduce(
        (total, isolatedVault) => total.add(isolatedVault.margin),
        ZERO
      )

      const isolatedTotalDelta = vaultsStatus.data.isolatedVaults.reduce(
        (total, isolatedVault) =>
          total.add(
            isolatedVault.openPositionStatusResults.reduce(
              (acc, item) => acc.add(item.delta),
              ZERO
            )
          ),
        ZERO
      )

      if (vaultsStatus.data.mainVault) {
        const mainVault = vaultsStatus.data.mainVault
        return {
          mainVaultId: mainVault.id,
          totalValue: mainVault.vaultValue.add(isolatedVaultValue),
          totalMargin: isolatedVaultMargin,
          portfolioVaultValue: mainVault.vaultValue,
          minValue: mainVault.minDeposit,
          marginAvailable: mainVault.available,
          marginWithdrawal: mainVault.withdrawable,
          isolatedVaultValue,
          isolatedTotalDelta
        }
      } else {
        return {
          mainVaultId: 0,
          totalValue: ZERO,
          totalMargin: ZERO,
          portfolioVaultValue: ZERO,
          minValue: ZERO,
          marginAvailable: ZERO,
          marginWithdrawal: ZERO,
          isolatedVaultValue,
          isolatedTotalDelta
        }
      }
    },

    {
      enabled: vaultsStatus.isSuccess,
      refetchInterval: 5000
    }
  )
}

export function useUnrealizedFee(vaultId: number) {
  const { provider, account } = useWeb3React<ethers.providers.Web3Provider>()
  const supportedChain = useIsSupportedChain()
  const chainId = useChainId()
  const addresses = useAddresses()

  const unrealizedFeeQuery = useQuery(
    ['unrealized_fee', chainId, vaultId],

    async () => {
      if (!account) throw new Error('Account not set')
      if (!provider) throw new Error('provider not set')
      if (!addresses) throw new Error('addresses not set')

      const controller = Controller__factory.connect(
        addresses.Controller,
        provider
      )

      const result = await controller.callStatic.getVaultStatus(vaultId, {
        from: account
      })

      return result[6].map(openPositionStatus => ({
        assetId: openPositionStatus[0].toNumber(),
        unrealizedFee: openPositionStatus[3]
      }))
    },

    {
      enabled: !!account && supportedChain && !!provider && !!addresses,
      staleTime: STALE_TIME,
      refetchInterval: 15000
    }
  )

  return unrealizedFeeQuery
}
