import { ethers } from 'ethers'
import { useWeb3React } from '@web3-react/core'
import { useQuery } from 'react-query'
import { useChainId, useIsSupportedChain } from '../../network'
import { useAddresses } from '../../useAddress'
import {
  GammaShortStrategy__factory,
  Multicall__factory
} from '../../../typechain'
import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
import { toUnscaled } from '../../../utils/bn'
import { REFETCH_INTERVAL } from '../../../constants'

dayjs.extend(duration)

type StrategySummary = {
  nextHedgeIn: {
    hours: number
    minutes: number
    seconds: number
  }
  totalIssuing: number
  price: number
}

export function useStrategySummary(assetId: number) {
  const { provider } = useWeb3React<ethers.providers.Web3Provider>()
  const supportedChain = useIsSupportedChain()
  const chainId = useChainId()
  const addresses = useAddresses()

  return useQuery<StrategySummary>(
    ['st_summary', chainId, assetId],

    async () => {
      if (!provider) throw new Error('provider not set')
      if (!addresses) throw new Error('addresses not set')
      if (!chainId) throw new Error('chainId not set')

      const contract = GammaShortStrategy__factory.connect(
        addresses.assets[assetId].GammaShortStrategy,
        provider
      )
      const multicall = Multicall__factory.connect(
        addresses.Multicall2,
        provider
      )

      const calls = [
        {
          target: contract.address,
          callData: contract.interface.encodeFunctionData('totalSupply')
        },
        {
          target: contract.address,
          callData: contract.interface.encodeFunctionData('lastHedgeTimestamp')
        },
        {
          target: contract.address,
          callData: contract.interface.encodeFunctionData('getPrice')
        }
      ]

      const result = await multicall.callStatic.aggregate(calls)

      const totalSupply = contract.interface.decodeFunctionResult(
        'totalSupply',
        result.returnData[0]
      )[0]
      const lastHedgeTimestamp = contract.interface.decodeFunctionResult(
        'lastHedgeTimestamp',
        result.returnData[1]
      )[0]
      const price = contract.interface.decodeFunctionResult(
        'getPrice',
        result.returnData[2]
      )[0]

      const nextHedgeTime = dayjs(
        lastHedgeTimestamp.add(60 * 60 * 24 * 2).toNumber() * 1000
      )
      const now = dayjs()

      const nextHedgeIn = nextHedgeTime.diff(now)

      const nextHedgeInDuration = dayjs.duration(nextHedgeIn)

      const daysUntil = nextHedgeInDuration.days()

      return {
        nextHedgeIn: {
          hours: nextHedgeInDuration.hours() + daysUntil * 24,
          minutes: nextHedgeInDuration.minutes(),
          seconds: nextHedgeInDuration.seconds()
        },
        totalIssuing: toUnscaled(totalSupply, 6),
        price: toUnscaled(price, 18)
      }
    },
    {
      enabled: supportedChain && !!provider && !!addresses,
      refetchInterval: REFETCH_INTERVAL
    }
  )
}
