import React, { useState } from 'react'
import { toScaled, toUnscaled } from '../../utils/bn'
import { PrimaryButton } from '../common/Button'
import AmountRangeInput from '../common/AmountRangeInput'
import SideSelector from '../common/SideSelector'
import { useDepositToStrategy } from '../../hooks/contracts/useDepositToStrategy'
import { useInitializeStrategy } from '../../hooks/contracts/useInitializeStrategy'
import { useApproveMutation } from '../../hooks/contracts/useApprove'
import { useAddressesAnyway } from '../../hooks/useAddress'
import { calculateMargin } from '../../utils'
import {
  useDepositToStrategyQuoter,
  useWithdrawFromStrategyQuoter
} from '../../hooks/query/strategy/useStrategyQuoter'
import { usdcAmountToString } from '../../utils/number'
import { useWithdrawFromStrategy } from '../../hooks/contracts/useWithdrawFromStrategy'
import { useERC20BalanceQuery } from '../../hooks/query/balance'
import { useAllowanceQuery } from '../../hooks/query/allowance'
import pendingStore from '../../store/pending'
import { reasonToErrorMessage } from '../../utils/error'
import { useStrategyPriceHistory } from '../../hooks/query/strategy/useStrategyApr'
import { BigNumber } from 'ethers'

const AprComponent = ({ apr }: { apr: BigNumber }) => (
  <div className={apr.gt(0) ? 'text-green' : 'text-red'}>
    {toUnscaled(apr, 16, 2)}%
  </div>
)

const StrategyTradeForm = ({ assetId }: { assetId: number }) => {
  const addresses = useAddressesAnyway()
  const [squartSide, setSquartSide] = useState(false)
  const [strategyAmount, setStrategyAmount] = useState(0)

  const { isPendingApproval, isPendingTx, setPendingApproval, setPendingTx } =
    pendingStore()

  const balanceQuery = useERC20BalanceQuery(addresses.QuoteToken)
  const strategyBalanceQuery = useERC20BalanceQuery(
    addresses.assets[assetId].GammaShortStrategy
  )

  const allowanceQuery = useAllowanceQuery(
    addresses.assets[assetId].GammaShortStrategy,
    addresses.QuoteToken
  )

  const quote = useDepositToStrategyQuoter(assetId, {
    strategyTokenAmount: toScaled(strategyAmount, 6),
    maxDepositAmount: calculateMargin(toScaled(strategyAmount, 6))
  })
  const quoteWithdraw = useWithdrawFromStrategyQuoter(assetId, {
    strategyTokenAmount: toScaled(strategyAmount, 6)
  })
  const strategyApy = useStrategyPriceHistory(2)

  const deposit = useDepositToStrategy(assetId)
  const withdraw = useWithdrawFromStrategy(assetId)
  const initialize = useInitializeStrategy(assetId)
  const approve = useApproveMutation()

  const onApprove = async () => {
    if (addresses === undefined) {
      return
    }

    const tx = await approve.mutateAsync({
      spender: addresses.assets[assetId].GammaShortStrategy,
      address: addresses.QuoteToken,
      amount: calculateMargin(toScaled(strategyAmount, 6))
    })

    setPendingApproval(tx)
  }
  const onTrade = async () => {
    if (squartSide) {
      if (quoteWithdraw.data) {
        const tx = await withdraw.mutateAsync({
          strategyTokenAmount: toScaled(strategyAmount, 6),
          minWithdrawAmount: quoteWithdraw.data.mul(99).div(100)
        })

        setPendingTx(tx)
      }
    } else {
      const tx = await deposit.mutateAsync({
        strategyTokenAmount: toScaled(strategyAmount, 6),
        maxDepositAmount: calculateMargin(toScaled(strategyAmount, 6))
      })

      setPendingTx(tx)
    }
  }

  const onInitialize = async () => {
    await initialize.mutateAsync({
      initialMarginAmount: toScaled(1000, 6),
      initialPerpAmount: toScaled(823, 16).mul(-1),
      initialSquartAmount: toScaled(700, 12).div(2)
    })
  }

  const requiredUSDCAmount = calculateMargin(toScaled(strategyAmount, 6))
  const requiredStrategyAmount = toScaled(strategyAmount, 6)

  const hasEnoughUSDC = balanceQuery.gte(requiredUSDCAmount)
  const enoughUSDCApproved = allowanceQuery.gte(requiredUSDCAmount)
  const hasEnoughStrategyToken = strategyBalanceQuery.gte(
    requiredStrategyAmount
  )

  return (
    <div className="rounded-3xl bg-secondaly border-[1px] border-white leading-5">
      <div className="m-[-1px] rounded-3xl bg-white0 border-[1px] border-white leading-5">
        <div className="mx-5 my-5">
          <SideSelector side={squartSide} onChange={setSquartSide} />
        </div>
        <div className="mx-5 my-5 p-4 rounded-xl bg-black4 shadow-sm">
          <AmountRangeInput
            amount={0}
            max={1000}
            title={'Strategy'}
            step={1}
            onChange={setStrategyAmount}
          />
        </div>
      </div>
      <div className="mx-4 my-5 p-2 flex justify-between rounded-full bg-black3">
        <div className="py-1 px-2 rounded-full bg-white1 text-base font-semibold">
          {squartSide ? 'You Get' : 'You Pay'}
        </div>
        <div className="py-1 pr-2">
          <span className="text-white5 pr-1">
            {squartSide
              ? quoteWithdraw.data
                ? usdcAmountToString(quoteWithdraw.data)
                : '-'
              : quote.data
              ? usdcAmountToString(quote.data)
              : '-'}
          </span>
          USDC
        </div>
      </div>

      <div className="mx-5 my-5">
        <div className="text-base">Historical Performance based on UTC</div>
        <div className="mt-4 px-1 space-y-2">
          <div className="text-sm flex justify-between border-b-[1px] border-white3">
            <div>1 Day</div>
            <AprComponent apr={strategyApy.dayApr} />
          </div>
          <div className="text-sm flex justify-between border-b-[1px] border-white3">
            <div>7 Days</div>
            <AprComponent apr={strategyApy.weekApr} />
          </div>
          <div className="text-sm flex justify-between border-b-[1px] border-white3">
            <div>30 Days</div>
            <AprComponent apr={strategyApy.monthApr} />
          </div>
          <div className="text-sm flex justify-between border-b-[1px] border-white3">
            <div>365 Days (or as available converted)</div>
            <AprComponent apr={strategyApy.yearApr} />
          </div>
        </div>
      </div>
      <div className="mx-5 my-5 h-12 flex justify-between items-center space-x-2">
        <PrimaryButton
          onClick={onApprove}
          disabled={
            squartSide ||
            isPendingApproval ||
            enoughUSDCApproved ||
            !hasEnoughUSDC
          }
        >
          Approve
        </PrimaryButton>
        <PrimaryButton
          onClick={onTrade}
          disabled={
            (squartSide && !hasEnoughStrategyToken) ||
            (!squartSide &&
              (!enoughUSDCApproved || isPendingTx || !hasEnoughUSDC)) ||
            strategyAmount === 0
          }
        >
          {squartSide ? 'Withdraw' : 'Deposit'}
        </PrimaryButton>
        {/*
        <PrimaryButton
          onClick={onInitialize}
          disabled={false}
        >
          Initialize
        </PrimaryButton>
        */}
      </div>

      {(!squartSide && hasEnoughUSDC) ||
      (squartSide && hasEnoughStrategyToken) ? (
        <></>
      ) : (
        <div className="mx-5 my-5 text-xs text-red">
          <span>
            Insufficient {squartSide ? 'Strategy' : 'USDC'} amount in your
            wallet.
          </span>
        </div>
      )}

      {quote.error === null ? (
        <></>
      ) : (
        <div className="mx-5 my-5 text-xs text-red">
          <span>{reasonToErrorMessage(quote.error)}</span>
        </div>
      )}
    </div>
  )
}

export default StrategyTradeForm
