import React from 'react'
import StrategyTradeForm from '../components/strategy/StrategyTradeForm'
import StrategyPosition from '../components/strategy/StrategyPosition'
import useAssetTypeStore from '../store/assetType'
import StrategyCenterArea from '../components/strategy/StrategyCenterArea'

const TradeStrategyView = () => {
  const { selectedAsset } = useAssetTypeStore()

  return (
    <div className="grid grid-cols-trade-sm sm:grid-cols-trade grid-rows-trade-sm sm:grid-rows-trade gap-2">
      <div className="col-span-1 row-span-2 mx-1 md:ml-2">
        <StrategyTradeForm assetId={selectedAsset} />
      </div>
      <div className="hidden sm:block col-span-1 row-span-1">
        <StrategyCenterArea assetId={selectedAsset} />
      </div>
      <div className="col-span-1 row-span-1">
        <div className="mx-5">
          <StrategyPosition assetId={selectedAsset} />
        </div>
      </div>
    </div>
  )
}

export default TradeStrategyView
