import { useQuery } from '@apollo/client'
import { BigNumber } from 'ethers'
import { useEffect, useState } from 'react'
import { ASSET_NAMES, SQUART_ONE, UNDERLYING_ONE, ZERO } from '../../constants'
import { Product, TradeSide, TradeSymbol } from '../../constants/enum'
import {
  TradeHistoryItems,
  MAINVAULT_HISTORY_ITEM_QUERY,
  ISOLATEDVAULT_HISTORY_ITEM_QUERY,
  TradeHistoryItem
} from '../../queries/tradeHistoryItemQuery'
import { toUnscaled } from '../../utils/bn'

export type SubVaultItem = {
  id: string
  assetId: number
  vaultId: number
  symbol: TradeSymbol
  symbolString: string
  side: TradeSide
  size: number
  price: BigNumber
  entryValue: BigNumber
  payoff: BigNumber
  timestamp: number
  txHash: string
}

export const NUM_ONE_PAGE_ITEMS = 200

const groupBy = (array: SubVaultItem[]): Record<number, SubVaultItem[]> =>
  array.reduce(
    (groups, item) => ({
      ...groups,
      [item.vaultId]: [...(groups[item.vaultId] || []), item]
    }),
    {} as Record<number, SubVaultItem[]>
  )

function decodeTradeHistoryItem(entity: TradeHistoryItem) {
  const product = entity.product === 'PERP' ? Product.PERP : Product.SQRT
  let sizeBn = ZERO
  let entryValue = ZERO
  let payoff = ZERO
  let price = ZERO
  let symbol = TradeSymbol.UNDEFINED
  let side = TradeSide.UNDEFINED
  let size = 0

  if (entity.action === 'POSITION') {
    sizeBn = BigNumber.from(entity.size)
    payoff = BigNumber.from(entity.payoff)
    entryValue = BigNumber.from(entity.entryValue).add(payoff)
    price = entryValue
      .mul(product === Product.PERP ? UNDERLYING_ONE : SQUART_ONE)
      .div(sizeBn)
      .abs()
    symbol = product === Product.PERP ? TradeSymbol.PERP : TradeSymbol.SQRT
    if (product === Product.SQRT) {
      sizeBn = sizeBn.mul(2)
      price = price.div(2)
    }

    size = toUnscaled(sizeBn, product === Product.PERP ? 18 : 12)
    side = sizeBn.gt(0) ? TradeSide.BUY : TradeSide.SELL
  } else if (entity.action === 'FEE') {
    symbol = TradeSymbol.FEE
    payoff = BigNumber.from(entity.payoff)
  } else if (entity.action === 'MARGIN') {
    symbol = TradeSymbol.MARGIN
    payoff = BigNumber.from(entity.payoff)
  } else if (entity.action === 'LIQUIDATION') {
    symbol = TradeSymbol.LIQUIDATION
    payoff = BigNumber.from(entity.payoff).mul(-1)
  }
  const assetId = Number(entity.assetId)

  return {
    id: entity.id,
    vaultId: Number(entity.vault.vaultId),
    assetId,
    symbol,
    symbolString:
      symbol === TradeSymbol.PERP
        ? ASSET_NAMES[assetId]
        : symbol === TradeSymbol.SQRT
        ? '√' + ASSET_NAMES[assetId]
        : symbol.toString(),
    product,
    side,
    size,
    price,
    entryValue,
    payoff,
    timestamp: Number(entity.createdAt),
    txHash: entity.txHash
  }
}

export function useMainVaultHistory(vaultId: number) {
  const [skip, setSkip] = useState(0)
  const [vaultHistory, setVaultHistory] = useState<SubVaultItem[]>([])

  const { data, loading } = useQuery<TradeHistoryItems>(
    MAINVAULT_HISTORY_ITEM_QUERY,
    {
      fetchPolicy: 'cache-and-network',
      variables: {
        vaultId: vaultId.toString(),
        skip: skip,
        first: NUM_ONE_PAGE_ITEMS
      },
      pollInterval: 30000
    }
  )

  useEffect(() => {
    if (data) {
      const newItems = data.tradeHistoryItems.map(decodeTradeHistoryItem)

      setVaultHistory(vaultHistory => {
        return vaultHistory
          .concat(newItems)
          .map(item => Object.assign({}, item))
          .sort((a, b) => b.timestamp - a.timestamp)
          .filter(
            (val, ind, self) =>
              ind === self.findIndex(item => item.id === val.id)
          )
      })
    }
  }, [data])

  return {
    vaultHistory,
    loading,
    skip,
    setSkip
  }
}

export function useIsolatedVaultHistory(account: string) {
  const [skip, setSkip] = useState(0)
  const [vaultHistory, setVaultHistory] = useState<
    Record<number, SubVaultItem[]>
  >({})

  const { data, loading } = useQuery<TradeHistoryItems>(
    ISOLATEDVAULT_HISTORY_ITEM_QUERY,
    {
      fetchPolicy: 'cache-and-network',
      variables: {
        owner: account.toLowerCase(),
        skip: skip,
        first: NUM_ONE_PAGE_ITEMS
      },
      pollInterval: 30000
    }
  )

  useEffect(() => {
    if (data) {
      const newItems = data.tradeHistoryItems.map(decodeTradeHistoryItem)

      setVaultHistory(vaultHistory => {
        return Object.assign({}, vaultHistory, groupBy(newItems))
      })
    }
  }, [data])

  return {
    vaultHistory,
    loading,
    skip,
    setSkip
  }
}
