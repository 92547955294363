import { useQuery } from 'react-query'
import { STALE_TIME, ZERO } from '../../../constants'
import { createEmptyPosition, useVault } from '../useVault'
import { toScaled, toUnscaled } from '../../../utils/bn'
import { usePrice } from '../../usePrice'
import {
  calculateDelta,
  calculateGamma,
  calculateValue
} from '../../../utils/helpers/minDeposit'
import { priceToSqrtPrice } from '../../../utils/uniswap'
import { BigNumber } from 'ethers'
import { useEffect, useState } from 'react'
import { checkIsTradeFirstTime } from '../../../utils/positions'
import { useUnrealizedFee } from '../useVaultStatus'

type PnLChartData = {
  hasCurrentPosition: boolean
  data: {
    price: number
    value: number
    delta: number
    gamma: number
    valueAfter: number
    deltaAfter: number
    gammaAfter: number
  }[]
}

function generatePrices(price: number) {
  const basePrice = Math.floor(price / 25) * 25
  const step = 25

  const prices = Array.from({ length: 21 }, (v, i) =>
    toScaled((i - 10) * step + basePrice, 6)
  )

  return prices
}

export function usePnLChartData(
  assetId: number,
  vaultId: number,
  afterPerp: BigNumber,
  after2Squart: BigNumber,
  afterEntryUpdate: BigNumber
) {
  const vault = useVault(vaultId)
  const unrealizedFees = useUnrealizedFee(vaultId)
  const price = usePrice(assetId)
  const [chartData, setChartData] = useState<PnLChartData>({
    data: [],
    hasCurrentPosition: false
  })

  const chartQuery = useQuery<PnLChartData>(
    ['pnl_chart_data', vaultId, afterPerp, after2Squart, afterEntryUpdate],

    async () => {
      if (!price.isSuccess) throw new Error('price not set')

      let openPosition = createEmptyPosition(assetId)
      let margin = ZERO

      if (vault.isSuccess && unrealizedFees.isSuccess) {
        const openPositions = vault.data.openPositions.filter(
          openPosition => openPosition.assetId === assetId
        )
        const unrealizedFee = unrealizedFees.data.filter(
          unrealizedFee => unrealizedFee.assetId === assetId
        )

        openPosition =
          openPositions.length > 0
            ? openPositions[0]
            : createEmptyPosition(assetId)

        margin = vault.data.margin.add(
          unrealizedFee.length > 0 ? unrealizedFee[0].unrealizedFee : ZERO
        )
      }

      const position = {
        stable: openPosition.stableEntryValue,
        squart: openPosition.sqrtPerpPosition,
        underlying: openPosition.perpPosition
      }

      const positionAfter = {
        stable: openPosition.stableEntryValue.add(afterEntryUpdate),
        squart: after2Squart,
        underlying: afterPerp
      }

      const xData = generatePrices(
        price.data.indexPrice.div(1000000).toNumber()
      )

      return {
        hasCurrentPosition:
          vault.isSuccess && !checkIsTradeFirstTime(vault.data, assetId),
        data: xData.map(price => {
          const sqrtPrice = priceToSqrtPrice(price)

          const value = calculateValue(position, sqrtPrice)
          const delta = calculateDelta(position, sqrtPrice)
          const gamma = calculateGamma(position, sqrtPrice)
          const valueAfter = calculateValue(positionAfter, sqrtPrice)
          const deltaAfter = calculateDelta(positionAfter, sqrtPrice)
          const gammaAfter = calculateGamma(positionAfter, sqrtPrice)

          return {
            price: price.div(1000000).toNumber(),
            value: toUnscaled(value.add(margin), 6),
            delta: toUnscaled(delta, 18),
            gamma: toUnscaled(gamma, 18),
            valueAfter: toUnscaled(valueAfter.add(margin), 6),
            deltaAfter: toUnscaled(deltaAfter, 18),
            gammaAfter: toUnscaled(gammaAfter, 18)
          }
        })
      }
    },

    {
      enabled: price.isSuccess,
      staleTime: STALE_TIME
    }
  )

  useEffect(() => {
    if (chartQuery.isSuccess) {
      setChartData(chartQuery.data)
    }
  }, [chartQuery.isSuccess, chartQuery.data])

  return chartData
}
