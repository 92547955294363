import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { PrimaryButton } from '../../common/Button'
import { LoadingIndicatorPt } from '../../common/LoadingIndicator'
import { useTradePerp } from '../../../hooks/contracts/useTradePerp'
import pendingStore from '../../../store/pending'
import positionStore from '../../../store/position'
import gammaStore from '../../../store/gamma'
import { toScaled, toUnscaled } from '../../../utils/bn'
import { useTradeQuoter } from '../../../hooks/query/useQuoter'
import { InterestEstPanel } from '../../perps/InterestEstPanel'
import { Vault, useVault } from '../../../hooks/query/useVault'
import { useCachedPrice } from '../../../hooks/usePrice'
import { getDelta, getGamma } from '../../../utils/bs'
import {
  priceMul,
  sqrtPriceMul,
  toUnscaledSqrtPrice
} from '../../../utils/price'
import StrangleSideSelector from './StrangleSideSelector'
import StyleSelector from './StyleSelector'
import SizeForm from './SizeForm'
import { ASSET_NAMES, MARGIN, ZERO } from '../../../constants'
import { toPriceString, usdcAmountToString } from '../../../utils/number'
import { useSlippageTolerance } from '../../../hooks/query/common/slippage'
import {
  calculateLiquidationPrice1,
  calculateLiquidationPrice2,
  calculateMinDeposit
} from '../../../utils/helpers/minDeposit'
import { computeTradeAmounts } from '../../../utils/helpers/targetStrategy'
import InfoTooltip from '../../common/InfoTooltip'
import { checkIsTradeFirstTime } from '../../../utils/positions'
import { useUnrealizedFee } from '../../../hooks/query/useVaultStatus'

const SIZE_TO_SQUART = 100

function getBeforePerpAmount(assetId: number, vault?: Vault) {
  const openPositions = (vault?.openPositions || []).filter(
    openPosition => openPosition.assetId === assetId
  )

  const squartAmounts = openPositions.map(
    openPosiition => openPosiition.sqrtPerpPosition
  )
  const perpAmounts = openPositions.map(
    openPosiition => openPosiition.perpPosition
  )

  return {
    squartAmount: squartAmounts.length > 0 ? squartAmounts[0] : ZERO,
    perpAmount: perpAmounts.length > 0 ? perpAmounts[0] : ZERO
  }
}

const TradeForm = ({
  vaultId,
  assetId
}: {
  vaultId: number
  assetId: number
}) => {
  const { isPendingTx, setPendingTx } = pendingStore()
  const {
    afterPerp,
    after2Squart,
    afterEntryUpdate,
    setAfterPerp,
    setAfter2Squart,
    setAfterEntryUpdate
  } = positionStore()
  const { targetStrategy, setTargetStrategy } = gammaStore()

  const vault = useVault(vaultId)
  const unrealizedFees = useUnrealizedFee(vaultId)
  const slippageTolerance = useSlippageTolerance()

  const [squartSide, setSquartSide] = useState(false)
  const [size, setSize] = useState(0)

  const price = useCachedPrice(assetId)

  const perpAmount = computeTradeAmounts(
    squartSide,
    targetStrategy,
    size * SIZE_TO_SQUART,
    toUnscaledSqrtPrice(price.sqrtPrice)
  )

  const beforeAmounts = getBeforePerpAmount(assetId, vault.data)

  const tradeAmountSqrt = after2Squart.sub(beforeAmounts.squartAmount)
  const tradeAmountPerp = afterPerp.sub(beforeAmounts.perpAmount)

  useEffect(() => {
    const afterAmountSqrt = toScaled(size * SIZE_TO_SQUART, 12)
      .div(2)
      .mul(squartSide ? -1 : 1)
    const afterAmountPerp = toScaled(perpAmount, 18)

    setAfter2Squart(afterAmountSqrt)
    setAfterPerp(afterAmountPerp)
  }, [size, squartSide, perpAmount, setAfter2Squart, setAfterPerp])

  useEffect(() => {
    const defaultSize = Math.ceil(
      toUnscaled(beforeAmounts.squartAmount.mul(2).abs(), 12) / SIZE_TO_SQUART
    )

    setSize(defaultSize)
    setSquartSide(beforeAmounts.squartAmount.lt(0))
  }, [beforeAmounts.squartAmount.toString()])

  const quote = useTradeQuoter({
    vaultId,
    assetId,
    tradeAmount: tradeAmountPerp,
    tradeAmountSqrt: tradeAmountSqrt
  })

  useEffect(() => {
    if (quote.isSuccess) {
      if (quote.data.data) {
        const result = quote.data.data

        setAfterEntryUpdate(
          result.perpEntryUpdate
            .add(result.sqrtEntryUpdate)
            .add(result.perpPayoff)
            .add(result.sqrtPayoff)
        )
      } else {
        const expectedEntryUpdate = priceMul(
          price.price,
          tradeAmountPerp.mul(-1)
        ).add(sqrtPriceMul(price.sqrtPrice, tradeAmountSqrt.mul(-2)))
        setAfterEntryUpdate(expectedEntryUpdate)
      }
    }
  }, [
    quote.data,
    quote.isSuccess,
    setAfterEntryUpdate,
    price.price,
    price.sqrtPrice,
    tradeAmountPerp.toString(),
    tradeAmountSqrt.toString()
  ])

  const liqPrices = useMemo(() => {
    let margin = ZERO
    const position = {
      squart: ZERO,
      underlying: ZERO,
      stable: ZERO
    }
    if (quote.isLoading) {
      return [ZERO, ZERO]
    }

    if (vault.isSuccess && unrealizedFees.isSuccess) {
      const stableEntryValue = vault.data.openPositions
        .filter(openPosition => openPosition.assetId === assetId)
        .map(openPosition => openPosition.stableEntryValue)[0]

      const unrealizedFee = unrealizedFees.data.filter(
        unrealizedFee => unrealizedFee.assetId === assetId
      )

      margin = vault.data.margin.add(
        unrealizedFee.length > 0 ? unrealizedFee[0].unrealizedFee : ZERO
      )

      position.stable = position.stable.add(stableEntryValue || ZERO)
    }

    position.squart = after2Squart
    position.underlying = afterPerp
    position.stable = position.stable.add(afterEntryUpdate)

    const liqPrice1 = calculateLiquidationPrice1(position, margin)
    const liqPrice2 = calculateLiquidationPrice2(position, margin)

    const liqPrices = [liqPrice1, liqPrice2]

    liqPrices.sort((a, b) => {
      return a.sub(b).toNumber()
    })

    return liqPrices
  }, [
    assetId,
    vault.isSuccess,
    vault.data,
    quote.isLoading,
    after2Squart,
    afterPerp,
    afterEntryUpdate
  ])

  const trade = useTradePerp(assetId)

  const onTrade = useCallback(async () => {
    const tx = await trade.mutateAsync({
      vaultId,
      assetId,
      tradeAmount: tradeAmountPerp,
      tradeAmountSqrt: tradeAmountSqrt
    })

    setPendingTx(tx)
  }, [vaultId, assetId, tradeAmountPerp, tradeAmountSqrt, trade, setPendingTx])

  const isTradeFirstTime = !(
    vault.isSuccess && !checkIsTradeFirstTime(vault.data, assetId)
  )

  return (
    <div className="rounded-3xl bg-secondaly border-[1px] border-white leading-5">
      <div className="m-[-1px] p-5 rounded-3xl bg-white0 border-[1px] border-white5 leading-5">
        <div className="mx-0 my-1 p-3 rounded-xl bg-black4 shadow-sm">
          <div className="flex justify-start text-base text-subtext">
            <span>
              {isTradeFirstTime ? 'Create Position' : 'Update Position'}
            </span>
            <InfoTooltip placement="bottom-end">
              {isTradeFirstTime ? (
                <p>
                  Predy Finance will create Long or Short Strangle from √ETH and
                  ETH Perpetual Futures. More information is available at
                  Summery.
                </p>
              ) : (
                <p>
                  To Update Position, enter the position you want to take.
                  <br />
                  <span className="text-red">
                    Note that the number you enter here indicates the state
                    after Trade.
                  </span>
                </p>
              )}
            </InfoTooltip>
          </div>
          <StrangleSideSelector side={squartSide} onChange={setSquartSide} />
        </div>
        <div className="mx-0 my-1 p-4 rounded-xl bg-black4 shadow-sm">
          <div className="flex justify-start text-base text-subtext mb-1">
            Market View
            <InfoTooltip placement="top-end">
              <p className="text-center">
                Predy finance creates positions to maximize profit at the
                following price changes:
                <br />
                Down: -2.5
                <br />
                Stable: 0%
                <br />
                Up: +2.5
              </p>
            </InfoTooltip>
          </div>
          <StyleSelector style={targetStrategy} onChange={setTargetStrategy} />
        </div>
        <div className="mx-0 my-1 p-4 rounded-xl bg-black4 shadow-sm flex justify-between items-center space-x-2">
          <span className="flex justify-start text-base text-subtext">
            Size
            <InfoTooltip placement="top-end">
              <p className="text-center">
                Size represents the number of Squart (√ETH) where 1 represents
                100 Squart (√ETH).
                <br />
                <br />
                <span className="text-red">
                  Note that Size indicates the number of states after Trade.
                </span>
              </p>
            </InfoTooltip>
          </span>
          <SizeForm amount={size} onChange={setSize} />
        </div>
      </div>
      <div className="mx-0 my-5 px-5">
        <div className="flex justify-start items-center space-x-1">
          <div className="flex justify-center items-center rounded-full p-[6px] bg-green" />
          <div className="flex justify-center items-center">
            {isTradeFirstTime ? 'After Created' : 'After Updated'}
          </div>
        </div>
        <InterestEstPanel
          assetId={assetId}
          vaultId={vaultId}
          isSuccess={
            quote.isSuccess &&
            (!!quote.data.data || quote.data.error.indexOf('V1') >= 0)
          }
          trade={{
            tradeAmountPerp,
            tradeAmountSqrt
          }}
        />

        <div className="mt-2 space-y-2">
          <div className="text-sm flex justify-between border-b-[1px] border-white3">
            <div>Margin Utilizing</div>
            <div>
              {quote.isSuccess && quote.data.data
                ? usdcAmountToString(quote.data.data.minDeposit)
                : toUnscaled(
                    calculateMinDeposit(
                      {
                        stable: ZERO,
                        squart: after2Squart,
                        underlying: afterPerp
                      },
                      price.sqrtIndexPrice
                    ),
                    MARGIN.DECIMALS,
                    MARGIN.SIGNIFICANT
                  )}
            </div>
          </div>
          <div className="text-sm flex justify-between border-b-[1px] border-white3">
            <div>Liquidation Price est.</div>
            <div>
              {liqPrices[0].eq(0)
                ? '-'
                : toPriceString(toUnscaled(liqPrices[0], MARGIN.DECIMALS))}
              ,{' '}
              {liqPrices[1].eq(0)
                ? '-'
                : toPriceString(toUnscaled(liqPrices[1], MARGIN.DECIMALS))}
            </div>
          </div>

          <div className="text-sm flex justify-between border-b-[1px] border-white3">
            <div>Gamma, Delta</div>
            <div>
              <span>
                {getGamma(
                  toUnscaled(after2Squart, 12),
                  toUnscaledSqrtPrice(price.sqrtPrice)
                ).toFixed(4)}
              </span>
              <span>, </span>
              <span>
                {getDelta(
                  toUnscaled(afterPerp, 18),
                  toUnscaled(after2Squart, 12),
                  toUnscaledSqrtPrice(price.sqrtPrice)
                ).toFixed(2)}
              </span>
            </div>
          </div>
        </div>

        <div className="mt-2 flex justify-start">
          Trade Summary
          <InfoTooltip placement="top-end">
            <p className="text-center">
              What is displayed below is the quantity of ETH and √ETH Future
              contracts that will actually be acquired from now on to move to
              the position selected above.
            </p>
          </InfoTooltip>
        </div>

        <div className="mt-2 space-y-2">
          <div className="text-sm flex justify-between border-b-[1px] border-white3">
            <div>Structure</div>
            <div>
              {tradeAmountSqrt.lt(0) ? (
                <span className="text-red">Sell</span>
              ) : (
                <span className="text-green">Buy</span>
              )}{' '}
              √{ASSET_NAMES[assetId]}{' '}
              {toUnscaled(tradeAmountSqrt.mul(2), 12, 2)},{' '}
              {tradeAmountPerp.lt(0) ? (
                <span className="text-red">Sell</span>
              ) : (
                <span className="text-green">Buy</span>
              )}{' '}
              {ASSET_NAMES[assetId]} {toUnscaled(tradeAmountPerp, 18, 2)}
            </div>
          </div>
          <div className="text-sm flex justify-between border-b-[1px] border-white3">
            <div>Price</div>
            <div>
              {usdcAmountToString(quote.data?.data?.sqrtEntryPrice.div(2))},{' '}
              {usdcAmountToString(quote.data?.data?.perpEntryPrice)}
            </div>
          </div>
          <div className="text-sm flex justify-between border-b-[1px] border-white3">
            <div>Max Slippage</div>
            <div>{slippageTolerance}%</div>
          </div>
        </div>
      </div>

      <div className="mx-5 my-5 h-12">
        <PrimaryButton
          onClick={onTrade}
          disabled={isPendingTx || !!(quote.isSuccess && quote.data.error)}
        >
          {isPendingTx ? <LoadingIndicatorPt /> : 'Trade'}
        </PrimaryButton>
      </div>
    </div>
  )
}

export default TradeForm
