import { BigNumber } from 'ethers'
import { ONE, ZERO } from '../../../constants'
import { AggregationInterval } from '../../../queries/aggregatedPriceQuery'
import { useAddresses } from '../../useAddress'
import { usePriceHistory } from '../usePriceHistory'

type HistoryItem = {
  address: string
  openPrice: BigNumber
  closePrice: BigNumber
  timestamp: number
}

export function useStrategyPriceHistory(assetId: number) {
  const addresses = useAddresses()
  const priceHistory = usePriceHistory(
    addresses?.assets[assetId].GammaShortStrategy || '',
    AggregationInterval.Daily
  )

  function getApr(latest: HistoryItem, start: HistoryItem, days: number) {
    const apr = latest.closePrice.mul(ONE).div(start.closePrice).sub(ONE)
    const span = latest.timestamp - start.timestamp

    if (span === 0) {
      return ZERO
    }

    return apr.mul(60 * 60 * 24 * days).div(span)
  }

  if (priceHistory) {
    if (priceHistory.length > 0) {
      const latest = priceHistory.length - 1
      const dayBefore = latest - 1
      const weekBefore = latest >= 7 ? latest - 7 : 0
      const monthBefore = latest >= 30 ? latest - 30 : 0
      const yearBefore = latest >= 365 ? latest - 365 : 0

      const dayApr = getApr(priceHistory[latest], priceHistory[dayBefore], 1)
      const weekApr = getApr(priceHistory[latest], priceHistory[weekBefore], 7)
      const monthApr = getApr(
        priceHistory[latest],
        priceHistory[monthBefore],
        30
      )
      const yearApr = getApr(
        priceHistory[latest],
        priceHistory[yearBefore],
        365
      )

      return {
        dayApr,
        weekApr,
        monthApr,
        yearApr
      }
    }
  }

  return {
    dayApr: ZERO,
    weekApr: ZERO,
    monthApr: ZERO,
    yearApr: ZERO
  }
}
