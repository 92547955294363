import { useMemo, useState } from 'react'
import { toScaled, toUnscaled } from '../../utils/bn'
import { useOpenVaultQuoter } from '../../hooks/query/useQuoter'
import { usdcAmountToString } from '../../utils/number'
import { ZERO } from '../../constants'
import { getDelta, getGamma } from '../../utils/bs'
import { useCachedPrice } from '../../hooks/usePrice'
import { toUnscaledSqrtPrice } from '../../utils/price'
import { reasonToErrorMessage } from '../../utils/error'
import { calculateMinDeposit } from '../../utils/helpers/minDeposit'
import { BigNumber } from 'ethers'
import { useDebounce } from 'react-use'
import {
  calculateEstematedProfit,
  EstProfit
} from '../../utils/helpers/estProfit'
import { calculateEstLoss, EstLoss } from '../../utils/helpers/estLoss'

export type GammaTradeResult = {
  tradeError: string | null
  perpAmount: BigNumber
  squartAmount: BigNumber
  trade: {
    tradeAmountSqrt: BigNumber
    tradeAmountPerp: BigNumber
  }
  estMinDeposit: number
  minDeposit: string
  squartPrice: string
  perpPrice: string
  gamma: string
  delta: string
  estProfits: EstProfit | null
  estLoss: EstLoss | null
}

export const useGammaTradeResult = (
  assetId: number,
  isolatedMarginAmount: BigNumber,
  squartAmount: BigNumber
) => {
  const price = useCachedPrice(assetId)
  const perpAmount = toScaled(
    Number(
      getDelta(
        0,
        toUnscaled(squartAmount, 12),
        toUnscaledSqrtPrice(price.sqrtPrice)
      ).toFixed(3)
    ),
    18
  ).mul(-1)
  const quote = useOpenVaultQuoter({
    assetId,
    marginAmount: isolatedMarginAmount,
    tradeAmount: perpAmount,
    tradeAmountSqrt: squartAmount
  })
  const [tradeError, setTradeError] = useState<string | null>(null)

  const estMinDeposit = useMemo(() => {
    return Math.ceil(
      toUnscaled(
        calculateMinDeposit(
          {
            stable: ZERO,
            squart: squartAmount,
            underlying: perpAmount
          },
          price.sqrtIndexPrice
        ),
        6,
        2
      )
    )
  }, [squartAmount, perpAmount, price.sqrtIndexPrice])

  const squartPrice =
    quote.isSuccess && quote.data.data
      ? usdcAmountToString(quote.data.data.sqrtEntryPrice.div(2))
      : '-'
  const perpPrice =
    quote.isSuccess && quote.data.data
      ? usdcAmountToString(quote.data.data.perpEntryPrice)
      : '-'

  const gamma = getGamma(
    toUnscaled(squartAmount, 12),
    toUnscaledSqrtPrice(price.sqrtPrice)
  ).toFixed(6)

  const delta = getDelta(
    toUnscaled(perpAmount, 18),
    toUnscaled(squartAmount, 12),
    toUnscaledSqrtPrice(price.sqrtPrice)
  ).toFixed(2)

  const estProfits = useMemo(() => {
    return quote.isSuccess && quote.data.data
      ? calculateEstematedProfit(
          {
            stable: quote.data.data.perpEntryUpdate.add(
              quote.data.data.sqrtEntryUpdate
            ),
            squart: quote.data.data.squartAmount,
            underlying: quote.data.data.tradeAmount
          },
          price.sqrtIndexPrice
        )
      : null
  }, [quote.isSuccess, quote.data, price.sqrtIndexPrice])

  const estLoss = useMemo(() => {
    return quote.isSuccess && quote.data.data
      ? calculateEstLoss(
          {
            stable: quote.data.data.perpEntryUpdate.add(
              quote.data.data.sqrtEntryUpdate
            ),
            squart: quote.data.data.squartAmount,
            underlying: quote.data.data.tradeAmount
          },
          isolatedMarginAmount
        )
      : null
  }, [quote.isSuccess, quote.data, isolatedMarginAmount])

  const minDeposit =
    quote.isSuccess && quote.data.data
      ? usdcAmountToString(quote.data.data.minDeposit)
      : '-'

  const error =
    quote.isSuccess && quote.data.error !== null
      ? reasonToErrorMessage(quote.data.error)
      : null

  useDebounce(
    () => {
      setTradeError(error)
    },
    500,
    [error]
  )

  return {
    tradeError,
    perpAmount,
    squartAmount,
    estMinDeposit,
    minDeposit,
    squartPrice,
    perpPrice,
    trade: {
      tradeAmountSqrt: squartAmount,
      tradeAmountPerp: perpAmount
    },
    gamma,
    delta,
    estLoss,
    estProfits
  } as GammaTradeResult
}
