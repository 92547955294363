import React from 'react'
import ethLogo from '../assets/assets/eth.svg'
import usdcLogo from '../assets/assets/usdc.svg'
import discordIcon from '../assets/links/discord.svg'
import twitterIcon from '../assets/links/twitter.svg'
import mediumIcon from '../assets/links/medium.svg'
import useSupplyAssetTypeStore from '../store/supplyAssetType'
import { AssetType } from '../constants/enum'

const SideBar = () => {
  const { selectedAsset, selectAsset } = useSupplyAssetTypeStore()

  return (
    <div>
      <div className="block sm:hidden">
        <div className="flex justify-center gap-2">
          <div
            className="cursor-pointer"
            onClick={() => {
              selectAsset(AssetType.USDC)
            }}
          >
            <img
              src={usdcLogo}
              alt={'USDC'}
              className={`border-2 rounded-full ${
                selectedAsset === AssetType.USDC
                  ? 'border-green bg-green'
                  : 'border-primary bg-primary'
              }`}
            />
          </div>
          <div
            className="cursor-pointer"
            onClick={() => {
              selectAsset(AssetType.WETH)
            }}
          >
            <img
              src={ethLogo}
              alt={'WETH'}
              className={`border-2 rounded-full ${
                selectedAsset === AssetType.WETH
                  ? 'border-green bg-green'
                  : 'border-primary bg-primary'
              }`}
            />
          </div>
        </div>
      </div>
      <div className="hidden sm:block">
        <div className="mt-4 flex flex-col gap-4">
          <div
            className="flex justify-center items-center cursor-pointer"
            onClick={() => {
              selectAsset(AssetType.USDC)
            }}
          >
            <img
              src={usdcLogo}
              alt={'USDC'}
              className={`border-2 rounded-full ${
                selectedAsset === AssetType.USDC
                  ? 'border-green bg-green'
                  : 'border-primary bg-primary'
              }`}
            />
          </div>
          <div
            className="flex justify-center items-center cursor-pointer"
            onClick={() => {
              selectAsset(AssetType.WETH)
            }}
          >
            <img
              src={ethLogo}
              alt={'WETH'}
              className={`border-2 rounded-full ${
                selectedAsset === AssetType.WETH
                  ? 'border-green bg-green'
                  : 'border-primary bg-primary'
              }`}
            />
          </div>
        </div>
      </div>
      <div className="hidden sm:block mt-60">
        <div className="flex justify-center items-center">
          <div className="flex flex-col gap-6">
            <a href="https://discord.gg/predy" target="_blank" rel="noreferrer">
              <img src={discordIcon} alt={'Discord'} />
            </a>
            <a
              href="https://twitter.com/predyfinance"
              target="_blank"
              rel="noreferrer"
            >
              <img src={twitterIcon} alt={'twitter'} />
            </a>
            <a
              href="https://predyfinance.medium.com/"
              target="_blank"
              rel="noreferrer"
            >
              <img src={mediumIcon} alt={'Medium'} />
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SideBar
