import { gql } from '@apollo/client'

export const UNI_FEE_GROWTH_HOURLY_QUERY = gql`
  query ($contractAddress: String) {
    uniFeeGrowthHourlies(
      first: 120
      where: { address: $contractAddress }
      orderBy: updatedAt
      orderDirection: desc
    ) {
      id
      address
      feeGrowthGlobal0X128
      feeGrowthGlobal1X128
      updatedAt
    }
  }
`

export type UniFeeGrowthHoulies = {
  uniFeeGrowthHourlies: {
    id: string
    address: string
    feeGrowthGlobal0X128: string
    feeGrowthGlobal1X128: string
    updatedAt: string
  }[]
}

export const INTEREST_GROWTH_TXES = gql`
  query ($assetId: Int) {
    interestGrowthTxes(
      first: 120
      where: { assetId: $assetId }
      orderBy: createdAt
      orderDirection: desc
    ) {
      id
      assetId
      accumulatedInterests
      accumulatedDebts
      accumulatedPremiumSupply
      accumulatedPremiumBorrow
      accumulatedFee0
      accumulatedFee1
      supplyPremiumGrowth
      fee0Growth
      fee1Growth
      createdAt
    }
  }
`

export type InterestGrowthTxes = {
  interestGrowthTxes: {
    id: string
    assetId: string
    accumulatedInterests: string
    accumulatedDebts: string
    accumulatedPremiumSupply: string
    accumulatedPremiumBorrow: string
    accumulatedFee0: string
    accumulatedFee1: string
    supplyPremiumGrowth: string
    fee0Growth: string
    fee1Growth: string
    createdAt: string
  }[]
}
