import { BigNumber, ethers } from 'ethers'
import { useWeb3React } from '@web3-react/core'
import { useQuery } from 'react-query'
import { useChainId, useIsSupportedChain } from '../../network'
import { useAddresses } from '../../useAddress'
import {
  Controller__factory,
  GammaShortStrategy__factory
} from '../../../typechain'
import { STALE_TIME, ZERO } from '../../../constants'

export interface StrategyVaultStatusResult {
  id: number
  totalSupply: BigNumber
  vaultValue: BigNumber
  minDeposit: BigNumber
  price: BigNumber
}

export function useStrategyVaultStatus(assetId: number) {
  const { provider, account } = useWeb3React<ethers.providers.Web3Provider>()
  const supportedChain = useIsSupportedChain()
  const chainId = useChainId()
  const addresses = useAddresses()

  const vaultStatusQuery = useQuery<StrategyVaultStatusResult>(
    ['st_vault_status', chainId, assetId],

    async () => {
      if (!account) throw new Error('Account not set')
      if (!provider) throw new Error('provider not set')
      if (!addresses) throw new Error('addresses not set')

      const strategy = GammaShortStrategy__factory.connect(
        addresses.assets[assetId].GammaShortStrategy,
        provider
      )

      const controller = Controller__factory.connect(
        addresses.Controller,
        provider
      )

      const vaultId = await strategy.vaultId()

      const totalSupply = await strategy.totalSupply()

      const vaultStatus = await controller.callStatic.getVaultStatus(vaultId, {
        from: addresses.assets[assetId].GammaShortStrategy
      })

      const vaultValue = vaultStatus[2]

      return {
        id: vaultId.toNumber(),
        totalSupply,
        vaultValue,
        minDeposit: vaultStatus[5],
        price: totalSupply.eq(0)
          ? ZERO
          : vaultValue.mul('1000000').div(totalSupply)
      }
    },

    {
      enabled: !!account && supportedChain && !!provider && !!addresses,
      staleTime: STALE_TIME,
      refetchInterval: 15000
    }
  )

  return vaultStatusQuery
}
