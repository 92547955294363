import React, { useState } from 'react'
import { BigNumber } from 'ethers'
import { ZERO } from '../../../constants'
import { useCloseIsolatedVault } from '../../../hooks/contracts/useCloseIsolatedVault'
import { useCloseVaultQuoter } from '../../../hooks/query/useQuoter'
import { VaultStatusResult } from '../../../hooks/query/useVaultStatus'
import { useCachedPrice } from '../../../hooks/usePrice'
import { toUnscaled } from '../../../utils/bn'
import { getDelta, getGamma } from '../../../utils/bs'
import { usdcAmountToString } from '../../../utils/number'
import { toUnscaledSqrtPrice } from '../../../utils/price'
import { PrimaryButton } from '../../common/Button'
import { OpenPositionTable } from '../../positions/PositionTable'
import { LoadingIndicatorPt } from '../../common/LoadingIndicator'
import { ExtraVaultStatus } from '../../../hooks/query/useExtraVaultStatus'
import { getNow } from '../../../utils/time'

const OpenPositions = ({
  assetId,
  feeEst,
  vaultStatusList,
  extraVaultStatusList
}: {
  assetId: number
  feeEst?: {
    totalFeeEst: BigNumber
    vaultsFeeEst: { vaultId: number; feeEst: BigNumber }[]
  }
  vaultStatusList: VaultStatusResult[]
  extraVaultStatusList: ExtraVaultStatus[]
}) => {
  return (
    <div className="text-xs font-normal block overflow-x-hidden overflow-y-scroll h-[202px] space-y-2">
      {vaultStatusList.map((vault, i) => {
        const extraVaultStatus = extraVaultStatusList.find(
          extraVaultStatus => extraVaultStatus.vaultId === vault.id
        )

        return (
          <VaultOpenPositions
            key={i}
            assetId={assetId}
            vaultsFeeEst={feeEst?.vaultsFeeEst || []}
            vaultStatus={vault}
            extraVaultStatus={extraVaultStatus}
          />
        )
      })}
    </div>
  )
}

const VaultOpenPositions = ({
  assetId,
  vaultsFeeEst,
  vaultStatus,
  extraVaultStatus
}: {
  assetId: number
  vaultsFeeEst: { vaultId: number; feeEst: BigNumber }[]
  vaultStatus: VaultStatusResult
  extraVaultStatus?: ExtraVaultStatus
}) => {
  const [hasPendingTx, setHasPendingTx] = useState(false)

  const closeVault = useCloseIsolatedVault(assetId)
  const price = useCachedPrice(assetId)
  const quote = useCloseVaultQuoter({
    isolatedVaultId: vaultStatus.id,
    assetId,
    tradeAmount:
      vaultStatus.openPositionStatusResults.length > 0
        ? vaultStatus.openPositionStatusResults[0].perpStatus.amount.mul(-1)
        : ZERO,
    tradeAmountSqrt:
      vaultStatus.openPositionStatusResults.length > 0
        ? vaultStatus.openPositionStatusResults[0].sqrtStatus.amount.mul(-1)
        : ZERO
  })

  const extraPositionStatus = extraVaultStatus?.openPositions.find(
    openPosition => openPosition.assetId === assetId
  )

  const onClose = async () => {
    const tx = await closeVault.mutateAsync({
      isolatedVaultId: vaultStatus.id,
      assetId
    })

    setHasPendingTx(true)
    await tx.wait()
  }

  return (
    <div>
      {vaultStatus.openPositionStatusResults
        .filter(openPosition => openPosition.assetId === assetId)
        .map((openPosition, i) => {
          const delta = getDelta(
            toUnscaled(openPosition.perpStatus.amount, 18),
            toUnscaled(openPosition.sqrtStatus.amount, 12),
            toUnscaledSqrtPrice(price.sqrtPrice)
          )
          const gamma = getGamma(
            toUnscaled(openPosition.sqrtStatus.amount, 12),
            toUnscaledSqrtPrice(price.sqrtPrice)
          )

          const feeEsts = vaultsFeeEst.filter(
            vaultFeeEst => vaultFeeEst.vaultId === vaultStatus.id
          )

          return (
            <div
              key={i}
              className="md:w-[880px] p-2 rounded-2xl border-[1px] border-white"
            >
              <div className="px-2 py-1 mb-2 w-[860px] flex justify-between items-center space-x-2 border-b-[1px] border-white">
                <div className="w-40 text-base">
                  Gamma Vault {vaultStatus.id}
                </div>

                <div className="hidden sm:flex  justify-center items-center space-x-2">
                  <div className="flex flex-col space-y-2 text-xs">
                    <div className="text-subtext">Isolated Vault Value</div>
                    <div>${usdcAmountToString(vaultStatus?.vaultValue)}</div>
                  </div>
                  <div className="flex flex-col space-y-2 text-xs">
                    <div className="text-subtext">Margin Isolated</div>
                    <div>${usdcAmountToString(vaultStatus?.margin)}</div>
                  </div>
                  <div className="flex flex-col space-y-2 text-xs">
                    <div className="text-subtext">Loss Threshold</div>
                    <div>${usdcAmountToString(openPosition.lossThreshold)}</div>
                  </div>
                  <div className="flex flex-col space-y-2 text-xs">
                    <div className="text-subtext">Fee est.</div>
                    <div>
                      $
                      {feeEsts.length > 0
                        ? usdcAmountToString(feeEsts[0].feeEst)
                        : '-'}
                    </div>
                  </div>
                  <div className="flex flex-col space-y-2 text-xs">
                    <div className="text-subtext">Delta</div>
                    <div>{delta.toFixed(2)}</div>
                  </div>
                  <div className="flex flex-col space-y-2 text-xs">
                    <div className="text-subtext">Gamma</div>
                    <div>{gamma.toFixed(6)}</div>
                  </div>
                </div>

                <div className="hidden sm:block w-[168px] h-8">
                  <PrimaryButton onClick={onClose} disabled={hasPendingTx}>
                    {hasPendingTx ? (
                      <LoadingIndicatorPt />
                    ) : (
                      <span className="text-lg">Close Positions</span>
                    )}
                  </PrimaryButton>
                </div>
              </div>
              <OpenPositionTable
                key={i}
                assetId={assetId}
                tradeAmount={openPosition.perpStatus.amount}
                sqrtTradeAmount={openPosition.sqrtStatus.amount}
                entryPrice={openPosition.perpStatus.entryPrice}
                squartEntryPrice={openPosition.sqrtStatus.entryPrice}
                perpPayoff={quote.data?.data?.perpPayoff}
                sqrtPayoff={quote.data?.data?.sqrtPayoff}
                unrealizedFee={vaultStatus?.openPositionStatusResults
                  .filter(openPosition => openPosition.assetId === assetId)
                  .reduce((acc, i) => acc.add(i.fee), ZERO)}
                perpUpdatedAt={extraPositionStatus?.perpUpdatedAt || getNow()}
                squartUpdatedAt={
                  extraPositionStatus?.squartUpdatedAt || getNow()
                }
                fontSize={'text-sm'}
              />
            </div>
          )
        })}
    </div>
  )
}

export default OpenPositions
