import React from 'react'
import { toUnscaled } from '../../utils/bn'
import { EstLoss } from '../../utils/helpers/estLoss'
import { EstProfit } from '../../utils/helpers/estProfit'

const FontFamily = 'Inter'

const GammaShortPositionChart = ({
  currentPrice,
  estLoss
}: {
  currentPrice: number
  estLoss: EstLoss | null
}) => {
  return (
    <g>
      <text
        x="160"
        y="34"
        fontFamily={FontFamily}
        fontSize="6"
        fontWeight={400}
        fill="#ffffff"
      >
        <tspan textAnchor="middle">${currentPrice}</tspan>
      </text>
      <path
        d="M 40 90 Q 160 -50 280 90"
        stroke="#FA7B6770"
        fill="transparent"
        strokeWidth={0.5}
      />
      <path
        d="M 106 90 L 106 34 Q 160 6 214 34 L 214 90"
        stroke="#67FABF"
        fill="#67FABF10"
        strokeWidth={0.5}
      />

      {estLoss ? (
        <g>
          <g>
            <path d="M 214 20 L 240 20" stroke="#713DE1" strokeWidth={0.5} />
            <path d="M 214 34 L 240 34" stroke="#713DE1" strokeWidth={0.5} />
            <text
              x="240"
              y="29"
              fontFamily={FontFamily}
              fontSize="6"
              fontWeight={400}
              fill="#FA7B67"
            >
              <tspan textAnchor="end">
                -${toUnscaled(estLoss.high.estLoss, 6, 2)}
              </tspan>
            </text>

            <text
              x="243"
              y="29"
              fontFamily={FontFamily}
              fontSize="6"
              fontWeight={400}
              fill="#FFFFFF"
            >
              Est. Loss
            </text>
          </g>
          <g transform="translate(-134 0)">
            <path d="M 214 20 L 240 20" stroke="#713DE1" strokeWidth={0.5} />
            <path d="M 214 34 L 240 34" stroke="#713DE1" strokeWidth={0.5} />
            <text
              x="240"
              y="29"
              fontFamily={FontFamily}
              fontSize="6"
              fontWeight={400}
              fill="#FA7B67"
            >
              <tspan textAnchor="end">
                -${toUnscaled(estLoss.low.estLoss, 6, 2)}
              </tspan>
            </text>

            <text
              x="186"
              y="29"
              fontFamily={FontFamily}
              fontSize="6"
              fontWeight={400}
              fill="#FFFFFF"
            >
              Est. Loss
            </text>
          </g>

          <g>
            <text
              x="120"
              y="40"
              fontFamily={FontFamily}
              fontSize="6"
              fontWeight={400}
              fill="#ffffff"
            >
              <tspan textAnchor="middle">
                ${toUnscaled(estLoss.low.price, 6, 2)}
              </tspan>
            </text>
            <circle cx={106} cy={34} r={2} fill="#67FABF" />
          </g>

          <g>
            <text
              x="216"
              y="40"
              fontFamily={FontFamily}
              fontSize="6"
              fontWeight={400}
              fill="#ffffff"
            >
              <tspan textAnchor="left">
                ${toUnscaled(estLoss.high.price, 6, 2)}
              </tspan>
            </text>
            <circle cx={214} cy={34} r={2} fill="#67FABF" />
          </g>
        </g>
      ) : (
        <></>
      )}

      <text
        x="160"
        y="72"
        fontFamily={FontFamily}
        fontSize="6"
        fontWeight={400}
        fill="#FFFFFF"
      >
        <tspan textAnchor="middle">&lt;-- Loss cut without Fee --&gt;</tspan>
      </text>
      <circle cx={160} cy={20} r={2} fill="#67FABF" />
    </g>
  )
}

const GammaLongPositionChart = ({
  currentPrice,
  estProfits
}: {
  currentPrice: number
  estProfits: EstProfit | null
}) => {
  return (
    <g>
      <text
        x="160"
        y="78"
        fontFamily={FontFamily}
        fontSize="6"
        fontWeight={400}
        fill="#ffffff"
      >
        ${currentPrice}
      </text>
      {estProfits ? (
        <g>
          <g transform="translate(-155 52)">
            <path d="M 214 21 L 240 21" stroke="#713DE1" strokeWidth={0.5} />
            <path d="M 214 34 L 240 34" stroke="#713DE1" strokeWidth={0.5} />
            <text
              x="240"
              y="30"
              fontFamily={FontFamily}
              fontSize="6"
              fontWeight={400}
              fill="#0ECB81"
            >
              <tspan textAnchor="end">
                +${toUnscaled(estProfits.low.estProfit, 6, 2)}
              </tspan>
            </text>

            <text
              x="182"
              y="32"
              fontFamily={FontFamily}
              fontSize="6"
              fontWeight={400}
              fill="#FFFFFF"
            >
              Est. Profit
            </text>
          </g>

          <g transform="translate(14 52)">
            <path d="M 214 20 L 240 20" stroke="#713DE1" strokeWidth={0.5} />
            <path d="M 214 34 L 240 34" stroke="#713DE1" strokeWidth={0.5} />
            <text
              x="240"
              y="29"
              fontFamily={FontFamily}
              fontSize="6"
              fontWeight={400}
              fill="#0ECB81"
            >
              <tspan textAnchor="end">
                +${toUnscaled(estProfits.high.estProfit, 6, 2)}
              </tspan>
            </text>

            <text
              x="243"
              y="29"
              fontFamily={FontFamily}
              fontSize="6"
              fontWeight={400}
              fill="#FFFFFF"
            >
              Est. Profit
            </text>
          </g>

          <path
            d="M 86 100 L 86 76"
            stroke="#F0F0F0"
            strokeWidth={0.5}
            strokeDasharray={1}
          />
          <path
            d="M 228 100 L 228 74"
            stroke="#F0F0F0"
            strokeWidth={0.5}
            strokeDasharray={1}
          />

          <g transform="translate(0 0)">
            <text
              x="88"
              y="70"
              fontFamily={FontFamily}
              fontSize="6"
              fontWeight={400}
              fill="#ffffff"
            >
              ${toUnscaled(estProfits.low.price, 6, 2)}
            </text>
            <text
              x="116"
              y="70"
              fontFamily={FontFamily}
              fontSize="5"
              fontWeight={300}
              fill="#ffffff"
            >
              (-5%)
            </text>
          </g>
          <circle cx={86} cy={74} r={2} fill="#67FABF" />

          <g transform="translate(196 68)">
            <text
              x="0"
              y="0"
              fontFamily={FontFamily}
              fontSize="6"
              fontWeight={400}
              fill="#ffffff"
            >
              ${toUnscaled(estProfits.high.price, 6, 2)}
            </text>
            <text
              x="28"
              y="0"
              fontFamily={FontFamily}
              fontSize="5"
              fontWeight={300}
              fill="#ffffff"
            >
              (+5%)
            </text>
          </g>
          <circle cx={228} cy={72} r={2} fill="#67FABF" />
        </g>
      ) : (
        <></>
      )}
      <path
        d="M 10 16 C 80 110, 230 110, 300 16"
        stroke="#67FABF"
        fill="transparent"
        strokeWidth={0.5}
      />
      <circle cx={160} cy={86.6} r={2} fill="#67FABF" />
    </g>
  )
}

const PositionChart = ({
  currentPrice,
  squartSide,
  estLoss,
  estProfits
}: {
  currentPrice: number
  squartSide: boolean
  squartAmount: number
  estLoss: EstLoss | null
  estProfits: EstProfit | null
}) => {
  return (
    <div>
      <svg aria-hidden="true" viewBox="0 0 320 100" preserveAspectRatio="none">
        <text
          x="2"
          y="14"
          fontFamily={FontFamily}
          fontSize="6"
          fontWeight={400}
          fill="#ffffff"
        >
          Profit & Loss
        </text>
        <text
          x="290"
          y="92"
          fontFamily={FontFamily}
          fontSize="6"
          fontWeight={400}
          fill="#ffffff"
        >
          Price
        </text>
        <path
          d="M 0 100 L 0 0"
          stroke="#ffffff80"
          strokeDasharray={2}
          strokeWidth={0.5}
        />
        <path
          d="M 0 100 L 320 100"
          stroke="#ffffff80"
          strokeDasharray={2}
          strokeWidth={0.5}
        />
        {squartSide ? (
          <GammaLongPositionChart
            currentPrice={currentPrice}
            estProfits={estProfits}
          />
        ) : (
          <GammaShortPositionChart
            currentPrice={currentPrice}
            estLoss={estLoss}
          />
        )}
      </svg>
    </div>
  )
}

export default PositionChart
