import React from 'react'
import { toUnscaled } from '../../utils/bn'
import { useAsset, useUtilizationRatio } from '../../hooks/query/useAsset'
import { TickMath } from '@uniswap/v3-sdk'
import { BigNumber } from 'ethers'
import { ASSET_NAMES, Q96 } from '../../constants'
import { computePrice } from '../../utils/helpers/minDeposit'
import { getAmountsForLiquidity } from '../../utils/uniswap'
import { usePrice } from '../../hooks/usePrice'
import { useRebalanceHistory } from '../../hooks/query/useRebalanceHistory'
import { toTimeString } from '../../utils/string'
import { toPriceString } from '../../utils/number'
import { useAddressesAnyway } from '../../hooks/useAddress'

function tickToPrice(tick: number) {
  const sqrtPrice = TickMath.getSqrtRatioAtTick(tick)

  return computePrice(BigNumber.from(sqrtPrice.toString()))
    .mul('1000000')
    .div(Q96)
}

const AssetInfo = ({
  assetId,
  decimals
}: {
  assetId: number
  decimals: number
}) => {
  const asset = useAsset(assetId)
  const utilization = useUtilizationRatio(assetId)

  if (!asset.isSuccess || !utilization.isSuccess) {
    return <div>loading</div>
  }
  return (
    <div className="p-2 flex justify-center items-center">
      <div className="p-2 w-1/5 text-right">{ASSET_NAMES[assetId]}</div>
      <div className="p-2 w-1/5 text-right">
        {toUnscaled(utilization.data.supply, decimals, 3)}
      </div>
      <div className="p-2 w-1/5 text-right">
        {toUnscaled(utilization.data.borrow, decimals, 3)}
      </div>
      <div className="p-2 w-1/5 text-right">
        {toUnscaled(utilization.data.utilization, 16, 2)}%
      </div>
      <div className="p-2 w-1/5 text-right">
        {toUnscaled(
          asset.data.accumulatedProtocolRevenue,
          assetId === 1 ? 6 : 18,
          6
        )}{' '}
        {ASSET_NAMES[assetId]}
      </div>
    </div>
  )
}

const AssetSquartInfo = ({ assetId }: { assetId: number }) => {
  const asset = useAsset(assetId)
  const utilization = useUtilizationRatio(assetId)

  if (!asset.isSuccess || !utilization.isSuccess) {
    return <div>loading</div>
  }
  return (
    <div className="flex justify-center items-center">
      <div className="p-2 w-1/5 text-right">√{ASSET_NAMES[assetId]}</div>
      <div className="p-2 w-1/5 text-right">
        {toUnscaled(utilization.data.sqrt.supply.mul(2), 12, 2)}
      </div>
      <div className="p-2 w-1/5 text-right">
        {toUnscaled(utilization.data.sqrt.borrow.mul(2), 12, 2)}
      </div>
      <div className="p-2 w-1/5 text-right">
        {toUnscaled(utilization.data.sqrt.utilization, 16, 2)}%
      </div>
      <div className="p-2 w-1/5 text-right"></div>
    </div>
  )
}

const AssetSquartExtraInfo = ({ assetId }: { assetId: number }) => {
  const asset = useAsset(assetId)
  const sqrtPrice = usePrice(assetId)

  if (!asset.isSuccess || !sqrtPrice.isSuccess) {
    return <div>loading</div>
  }

  const amounts = getAmountsForLiquidity(
    sqrtPrice.data.sqrtPrice,
    BigNumber.from(
      TickMath.getSqrtRatioAtTick(
        asset.data.sqrtAssetStatus.tickLower
      ).toString()
    ),
    BigNumber.from(
      TickMath.getSqrtRatioAtTick(
        asset.data.sqrtAssetStatus.tickUpper
      ).toString()
    ),
    asset.data.sqrtAssetStatus.totalAmount.sub(
      asset.data.sqrtAssetStatus.borrowedAmount
    )
  )
  const lpAmount = asset.data.sqrtAssetStatus.totalAmount.sub(
    asset.data.sqrtAssetStatus.borrowedAmount
  )

  const amountsLower = calculateRequiredAmounts(
    asset.data.sqrtAssetStatus.tickLower,
    asset.data.sqrtAssetStatus.tickUpper,
    asset.data.sqrtAssetStatus.tickLower + 500,
    lpAmount
  )

  const amountsUpper = calculateRequiredAmounts(
    asset.data.sqrtAssetStatus.tickLower,
    asset.data.sqrtAssetStatus.tickUpper,
    asset.data.sqrtAssetStatus.tickUpper - 500,
    lpAmount
  )

  function calculateRequiredAmounts(
    currentLower: number,
    currentUpper: number,
    newCenter: number,
    lpAmount: BigNumber
  ) {
    const sqrtPrice = BigNumber.from(
      TickMath.getSqrtRatioAtTick(newCenter).toString()
    )

    const amounts = getAmountsForLiquidity(
      sqrtPrice,
      BigNumber.from(TickMath.getSqrtRatioAtTick(currentLower).toString()),
      BigNumber.from(TickMath.getSqrtRatioAtTick(currentUpper).toString()),
      lpAmount
    )
    const amounts2 = getAmountsForLiquidity(
      sqrtPrice,
      BigNumber.from(TickMath.getSqrtRatioAtTick(newCenter - 1000).toString()),
      BigNumber.from(TickMath.getSqrtRatioAtTick(newCenter + 1000).toString()),
      lpAmount
    )

    return [amounts[0].sub(amounts2[0]), amounts[1].sub(amounts2[1])]
  }

  return (
    <div className="flex justify-center items-center">
      <div className="p-2 w-1/4 text-right">√{ASSET_NAMES[assetId]}</div>
      <div className="p-2 w-1/4 text-right">
        <div>
          {toUnscaled(tickToPrice(asset.data.sqrtAssetStatus.tickLower), 6, 2)}-
          {toUnscaled(tickToPrice(asset.data.sqrtAssetStatus.tickUpper), 6, 2)}
        </div>
        <div>
          {toUnscaled(
            tickToPrice(
              (asset.data.sqrtAssetStatus.tickLower +
                asset.data.sqrtAssetStatus.tickUpper) /
                2
            ),
            6,
            2
          )}
        </div>

        <div>
          {toUnscaled(
            tickToPrice(
              asset.data.sqrtAssetStatus.tickLower +
                asset.data.riskParams.rebalanceThreshold
            ),
            6,
            2
          )}
          -
          {toUnscaled(
            tickToPrice(
              asset.data.sqrtAssetStatus.tickUpper -
                asset.data.riskParams.rebalanceThreshold
            ),
            6,
            2
          )}
        </div>
      </div>
      <div className="p-2 w-1/4 text-right">
        {toPriceString(toUnscaled(amountsLower[1].sub(amounts[1]), 6, 0))} USDC,{' '}
        {toUnscaled(amountsUpper[0].sub(amounts[0]), 18, 3)} ETH
      </div>
      <div className="p-2 w-1/4 text-right">
        {toPriceString(toUnscaled(amounts[1], 6, 0))} USDC,{' '}
        {toUnscaled(amounts[0], 18, 3)} ETH
      </div>
      <div className="p-2 w-1/4 text-right">
        {toUnscaled(
          asset.data.sqrtAssetStatus.rebalancePositionStable.positionAmount,
          6,
          2
        )}
        ,
        {toUnscaled(
          asset.data.sqrtAssetStatus.rebalancePositionUnderlying.positionAmount,
          18,
          3
        )}
      </div>
    </div>
  )
}

const AssetSquartRebalanceHistory = ({ assetId }: { assetId: number }) => {
  const addresses = useAddressesAnyway()
  const rebalanceHistory = useRebalanceHistory(addresses.Controller, assetId)

  if (rebalanceHistory === null) {
    return <div>loading</div>
  }

  return (
    <div>
      {rebalanceHistory.map((item, i) => (
        <div key={i} className="flex justify-center items-center">
          <div className="p-2 w-1/3 text-right">
            {toTimeString(item.createdAt)}
          </div>
          <div className="p-2 w-1/3 text-right">
            {toPriceString(toUnscaled(tickToPrice(item.tickLower), 6))}-
            {toPriceString(toUnscaled(tickToPrice(item.tickUpper), 6))}
          </div>
          <div className="p-2 w-1/3 text-right">
            {toUnscaled(item.profit, 6, 2)}
          </div>
        </div>
      ))}
    </div>
  )
}
const AssetSummary = () => {
  return (
    <div className="m-10 rounded-xl bg-white1">
      <div className="p-6 flex flex-wrap justify-between items-center space-x-0">
        <div className="w-32">Asset Summary</div>
      </div>

      <div className="p-10">
        <h2 className="text-lg">Utilization</h2>
        <div className="flex justify-center items-center">
          <div className="p-2 w-1/5 text-right">Name</div>
          <div className="p-2 w-1/5 text-right">Supply</div>
          <div className="p-2 w-1/5 text-right">Borrow</div>
          <div className="p-2 w-1/5 text-right">Utilization</div>
          <div className="p-2 w-1/5 text-right">Protocol Revenue</div>
        </div>
        <AssetInfo assetId={1} decimals={6} />
        <AssetInfo assetId={2} decimals={18} />
        <AssetSquartInfo assetId={2} />
      </div>

      <div className="p-10">
        <h2 className="text-lg">LP Position</h2>
        <div className="flex justify-center items-center">
          <div className="p-2 w-1/4 text-right">Name</div>
          <div className="p-2 w-1/4 text-right">Range(Threshold)</div>
          <div className="p-2 w-1/4 text-right">Required Asset</div>
          <div className="p-2 w-1/4 text-right">Locked Funds</div>
          <div className="p-2 w-1/4 text-right">Rebalance Funds</div>
        </div>

        <AssetSquartExtraInfo assetId={2} />
      </div>

      <div className="p-10">
        <h2 className="text-lg">ETH</h2>
        <div className="flex justify-center items-center">
          <div className="p-2 w-1/3 text-right">Time</div>
          <div className="p-2 w-1/3 text-right">Range</div>
          <div className="p-2 w-1/3 text-right">PnL</div>
        </div>
        <AssetSquartRebalanceHistory assetId={2} />
      </div>
    </div>
  )
}

export default AssetSummary
